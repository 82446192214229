import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { DateRange, MatDatepickerModule } from '@angular/material/datepicker';
import { NG_VALUE_ACCESSOR, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ControlValueAccessor } from '@ngneat/reactive-forms';

/**
 * Custom calendar date range picker
 */
@Component({
  selector: 'pu-date-range-picker',
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, FormsModule, ReactiveFormsModule],
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
  // eslint-disable-next-line
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements ControlValueAccessor {
  /**
   * Class of component
   * @internal
   */
  @HostBinding('class.pu-date-range-picker') hostClass = true;

  /**
   * Max date
   */
  @Input() maxDate: Date;

  /**
   * Max date
   */
  @Input() minDate: Date;

  /**
   * Control ref
   */
  @Input() formControl: FormControl<DateRange<Date>>;

  /**
   * Control ref
   */
  @Input() formControlName: string;

  /**
   * @internal
   */
  selectedDateRange: DateRange<Date>;

  /**
   * @internal
   * @param range
   */
  // eslint-disable-next-line
  onChange = (range: DateRange<Date>) => {};

  /**
   * @internal
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  /**
   * @internal
   * @param date
   */
  changed(date: Date) {
    if (this.selectedDateRange?.start && date >= this.selectedDateRange.start && !this.selectedDateRange.end) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }

    this.onChange(this.selectedDateRange);
  }

  /**
   * @internal
   * @param onChange
   */
  registerOnChange(onChange: (value: DateRange<Date>) => void): void {
    this.onChange = onChange;
  }

  /**
   * @internal
   * @param onTouched
   */
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  /**
   * @internal
   * @param value
   */
  writeValue(value: DateRange<Date>): void {
    this.selectedDateRange = value;
  }
}
