import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';

import { FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, map, tap } from 'rxjs';
import { OnChange } from '@pu/decorators';
import { DropdownTriggerForDirective } from '@pu/forms';
import { uaPhoneValidator, phoneSelectValidator } from '@pu/validators';

import { Phone } from './models/phone.model';
import { SelectDropdownListItem } from '../../models/select-dropdown-list-item.model';
import { AbstractInputComponent } from '../abstract-input.component';

const COUNTRY_CODE_UA = 'UA';

@UntilDestroy()
@Component({
  selector: 'pu-phone-select-input',
  templateUrl: './phone-select-input.component.html',
  styleUrls: ['./phone-select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneSelectInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneSelectInputComponent),
      multi: true,
    },
  ],
})
export class PhoneSelectInputComponent extends AbstractInputComponent<string> implements Validator, OnInit {
  override errorDictionary: Record<string, string> = {
    uaOperatorCode: 'validationMessages.uaOperatorCode',
    phoneFormat: 'validationMessages.wrongPhoneFormat',
  };
  /**
   * Icon of input
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() iconTemplate: TemplateRef<any>;

  /**
   * Country code phone list
   */
  @OnChange('_handlePhoneList')
  @Input()
  phoneList: Phone[];

  /**
   * Indicates if input is non-editable and readonly
   */
  @OnChange('_handleIsReadonly')
  @Input()
  override isReadonly: boolean;

  @Input() allowAutocomplete = true;
  @Input() allowPaste = true;

  /**
   * Has phone input edit button
   */
  @Input() hasEditButton = false;
  @Output() editClicked = new EventEmitter<void>();

  /**
   * List for select dropdown
   * @internal
   */
  itemList: SelectDropdownListItem<string>[] = [];
  /**
   * Selected country code
   * @internal
   */
  selectedPhoneSubject: BehaviorSubject<Phone> = new BehaviorSubject<Phone>(null);
  /**
   * Control for number without country code
   * @internal
   */
  phoneControl = new FormControl<number | string>();
  /**
   * Instance to avoid error in template
   * @internal
   */
  dropdownRef: DropdownTriggerForDirective;

  /**
   * Items map for dropdown
   */
  private _itemsRecord: Record<string, Phone>;

  /**
   * @internal
   */
  // eslint-disable-next-line
  onChange = (value: string) => {
  };

  override ngOnInit() {
    super.ngOnInit();

    this.phoneControl.valueChanges
      .pipe(
        filter(() => !!this.selectedPhoneSubject.getValue() && !this.isReadonly),
        map(value => this._cleanPhoneValue(value)),
        tap(value => this.phoneControl.setValue(value, { emitEvent: false })),
        untilDestroyed(this),
      )
      .subscribe(number => {
        this._notifyControl(number);
      });
  }

  /**
   * @internal
   * @param item
   */
  onItemSelected(item: SelectDropdownListItem<string | number>) {
    this.selectedPhoneSubject.next(this._itemsRecord[item.id]);
    this._notifyControl(this.phoneControl.value);
    this._updateValidators(this._itemsRecord[item.id]);
  }

  /**
   * @internal
   * @param value
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override writeValue(value: any) {
    this._handlePhoneCountry(value);
    this._setPhoneValue(value);
  }

  /**
   * Register a function that will be called when the control is touched
   * @internal
   */
  override registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  /**
   * @internal
   * @param control
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.selectedPhoneSubject.getValue() && !this.isReadonly) {
      return { invalidCode: true };
    }

    if (this.phoneControl.errors) {
      return this.phoneControl.errors;
    }

    return null;
  }

  private _updateValidators(phone: Phone): void {
    if (!this.isReadonly) {
      const validators = [];

      validators.push(Validators.minLength(phone.minDigit), Validators.maxLength(phone.maxDigit));

      if (this.control.hasValidator(Validators.required)) {
        validators.push(Validators.required);
      }

      if (phone.countryCode === COUNTRY_CODE_UA) {
        validators.push(uaPhoneValidator);
      }

      validators.push(phoneSelectValidator(phone.code));

      this.phoneControl.setValidators(validators);
    } else {
      this.phoneControl.clearValidators();
    }
  }

  private _handlePhoneList(): void {
    const itemsList = [];
    const itemsRecord: Record<string, Phone> = {};

    for (const phone of this.phoneList) {
      itemsRecord[phone.countryCode] = phone;
      itemsList.push({
        id: phone.countryCode,
        label: `${phone.code} ${phone.countryName}`,
        img: phone.img,
      });
    }

    this.itemList = itemsList;
    this._itemsRecord = itemsRecord;

    // Handling the case when phone list appears later than control value
    if (this.control?.value) {
      this._handlePhoneCountry();
      this._setPhoneValue(this.control.value);
    }
  }

  private _handlePhoneCountry(value: string = null): void {
    const phoneValue = this.control?.value || value;

    if (phoneValue && this.phoneList) {
      const phone = this.phoneList.find((phone: Phone) => phoneValue.startsWith(phone.code));
      this.selectedPhoneSubject.next(phone);
      this._updateValidators(phone);
    }
  }

  private _notifyControl(currentPhone: number | string) {
    this.onChange(`${this.selectedPhoneSubject.getValue().code}${currentPhone || ''}`);
  }

  private _setPhoneValue(value: string) {
    const selectedPhone = this.selectedPhoneSubject.getValue();

    if (selectedPhone || this.isReadonly) {
      const valueToSet = this.isReadonly ? value : value.replace(selectedPhone.code, '');
      this.phoneControl.setValue(valueToSet ? valueToSet : null, {
        emitEvent: false,
      });
    }
  }

  private _handleIsReadonly(): void {
    if (this.control?.value) {
      this._setPhoneValue(this.control.value);
    }
  }

  private _cleanPhoneValue(phone: string | number): string {
    const phoneString = String(phone ?? '');
    const countryCode = this.selectedPhoneSubject.getValue().code;
    const countryCodeRegExp = new RegExp(countryCode.replace('+', `^\\+?`)); // final regex example: ^\+?380
    const cleanPhone = phoneString.replace(countryCodeRegExp, '');

    return cleanPhone.slice(0, this.selectedPhoneSubject.getValue().maxDigit);
  }

  handleEditClick() {
    this.editClicked.emit();
  }
}
