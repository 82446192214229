/**
 * Returns a new object, which has converted values
 * @param obj
 * @param converter
 */
export function mapObj(obj: Record<string, any>, converter: (item: any, key: string) => any): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = converter(obj[key], key);
    }
  }

  return result;
}

/**
 * Returns a array, which has converted values of obj
 * @param obj
 * @param converter
 */
export function mapObjToArr(obj: Record<string, any>, converter: (item: any, key: string) => any): any[] {
  const result: any[] = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result.push(converter(obj[key], key));
    }
  }

  return result;
}
