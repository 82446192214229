import { EnvironmentModel } from './models/environment.model';
import { EnvName } from './models/env-name.enum';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* eslint-disable */
export const environment: EnvironmentModel = {
  production: false,
  useMocks: false,
  envName: EnvName.DEV,
  apiHost: (window as any).apiHost || 'https://api-pinup-team-dev.pin-up.dev/api/v1/',
  wssHost: (window as any).wssHost || 'wss://api-pinup-team-dev.pin-up.dev/api/v1/',
  defaultLang: 'en',
  supportedUiLanguages: ['en', 'uk', 'ru'],
  theme: 'light',
  googleClientId: '888042350879-l6ck0847d6m0dbbvldoacf0fut5r34nk.apps.googleusercontent.com',
  slackTeamId: 'T01FZLAFN9Z',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
