<textarea
  class="pu-control__input pu-control__input_textarea"
  [maxlength]="maxLength"
  [id]="controlId"
  [rows]="rows"
  [class.pu-control__input_invalid]="control.touched && control.invalid"
  [readonly]="isReadonly"
  [placeholder]="placeholder"
  (focus)="setIsFocused(true)"
  (blur)="setIsFocused(false)"
  [formControl]="control"
  [attr.data-testid]="controlTestId"
>
</textarea>
<label
  *ngIf="label"
  [for]="controlId"
  class="pu-control__label pu-control__label_textarea"
  [ngClass]="{
    'pu-control__label_invalid': control.touched && control.invalid,
    'pu-control__label_active': control.value || placeholder,
    'pu-control__label_small': labelSize === 's'
  }"
  >{{ label }}</label
>
<pu-floating-label-outline
  [hasGap]="isFocused || !!control.value || !!placeholder"
  [label]="label"
  [labelSize]="labelSize"
  [isHighlighted]="isFocused"
  [isInvalid]="control.touched && control.invalid"
  [isReadOnly]="isReadonly"
></pu-floating-label-outline>
<span class="pu-control__textarea-grab" [class.pu-control__textarea-grab_invalid]="control.touched && control.invalid"></span>
<span *ngIf="hint && !isReadonly" class="pu-control__hint" [class.pu-control__hint_invalid]="control.touched && control.invalid">
  {{ hint }}
</span>
