import { APP_INITIALIZER, InjectionToken } from '@angular/core';

import { lastValueFrom } from 'rxjs';

export const ORDERED_APP_INITIALIZER = new InjectionToken('Ordered APP_INITIALIZER');

/**
 * Function which execute functions from ORDERED_APP_INITIALIZER tokens in correct order
 * @param appInits
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function orderedAppInitializer(appInits: (() => any)[]) {
  return async (): Promise<void> => {
    for (const initializer of appInits) {
      await lastValueFrom(initializer());
    }
  };
}

export const ORDERED_APP_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: orderedAppInitializer,
  deps: [ORDERED_APP_INITIALIZER],
  multi: true,
};
