import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IconModule, ButtonModule, NavItemModule } from '@pu/ui';
import { LetModule } from '@pu/directives';
import { EffectsModule } from '@ngrx/effects';

import { ShellComponent } from './shell.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderModule } from './header/header.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { MenuModule } from './menu/menu.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { CartCounterModule } from './components/cart-counter/cart-counter.module';
import { ShellEffects } from './store/shell.effects';

@NgModule({
  declarations: [ShellComponent, NavbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    EffectsModule.forFeature([ShellEffects]),
    TranslateModule,
    IconModule,
    LetModule,
    ButtonModule,
    HeaderModule,
    SidebarModule,
    NavItemModule,
    MenuModule,
    NotificationsModule,
    CartCounterModule,
  ],
  exports: [ShellComponent],
})
export class ShellModule {}
