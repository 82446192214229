import { Entity, ImageModel, OptionColor, OptionSize, ProductOptionDetails, ProductOption } from '@pu/models';

export interface Product extends Entity {
  name: string;
  mainImage: ImageModel;
}

export interface ProductShop extends Product {
  price: number;
  sale: boolean;
  new: boolean;
}

export interface ProductDetails extends ProductShop {
  available: boolean;
  description: string;
  urlReview: string;
  extraImages: ImageModel[];
  productOptions: ProductOptionDetails[];
}

export interface ProductCart extends Product {
  price: number;
  productGlobalId: number;
  promo: boolean;
  quantity: number;
  productOption: ProductOption;
}

export interface ProductOrder extends Product {
  price: number;
  productGlobalId: number;
  productOption: ProductOption;
  quantity: number;
}

export interface ProductGlobal extends Product {
  price: number;
  sale: boolean;
  visible: boolean;
}

export interface ProductGlobalForm extends ProductGlobal {
  promo: boolean;
  description: string;
  urlReview: string;
  extraImages: ImageModel[];
  optionColors: OptionColor[];
  optionSizes: OptionSize[];
}

export interface ProductWarehouse extends Product {
  price: number;
  new: boolean;
  visible: boolean;
}

export interface ProductGlobalWarehouse extends Product {
  optionColors: OptionColor[];
  optionSizes: OptionSize[];
}

export interface ProductWishlist extends Product {
  price: number;
  locationId: number;
  productGlobalId: number;
  productOption: ProductOption;
}

export interface ProductWarehouseNew {
  productGlobalId: number;
  locationId: number;
  optionColorId: number;
  optionSizeId: number;
  quantity: number;
}

export interface CategoryProduct extends Product {
  price: number;
}

export interface CategoryProductNew {
  productWarehouseId: number;
  categoryIds: number[];
}
