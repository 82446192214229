import { Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  standalone: true,
  selector: 'pu-popover',
  template: ` <ng-container *ngTemplateOutlet="templateRef; context: context"></ng-container>`,
  styles: [],
  imports: [NgTemplateOutlet],
  animations: [
    trigger('attached', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '200ms linear',
          style({
            opacity: 1,
          }),
        ),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate(
          '200ms linear',
          style({
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class PopoverComponent {
  @Input() templateRef: TemplateRef<any>;

  @Input() context: { close: () => void };

  @HostBinding('style.marginLeft')
  @HostBinding('style.marginRight')
  @Input()
  offsetX: `${number}px`;

  @HostBinding('style.marginTop')
  @HostBinding('style.marginBottom')
  @Input()
  offsetY: `${number}px`;

  @HostBinding('@attached') attached = true;
}
