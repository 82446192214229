import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { DEVICE, UAParser } from 'ua-parser-js';

export enum UaDevices {
  Desktop = 'SPA',
  Mobile = 'SPA-MOBILE',
}

export enum OsName {
  IOS = 'iOS',
  MacOS = 'Mac OS',
}

const OLD_IOS_VERSION = '12.2';
const UC_BROWSER_NAME = 'UCBrowser';

/**
 * Service for parsing UA string
 *
 * Don't use getters directly in components
 */
@Injectable({
  providedIn: 'root',
})
export class UaParserService {
  private _uaParser: UAParser = new UAParser();
  private _serverDeviceType: string;

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  init(uaString?: string, cfHeader?: string): void {
    this._uaParser = new UAParser(uaString);
    this._serverDeviceType = cfHeader || new UAParser(uaString).getDevice().type;
  }

  get isMobile(): boolean {
    return this.deviceType === DEVICE.MOBILE;
  }

  get isTablet(): boolean {
    return this.deviceType === DEVICE.TABLET;
  }

  get isTouchDevice(): boolean {
    return this.isIpadOs || !this.isDesktop;
  }

  get isDesktop(): boolean {
    return !this.isMobile && !this.isTablet;
  }

  get deviceType(): string {
    return this._serverDeviceType || this._uaParser.getDevice().type;
  }

  get osName(): string {
    return this._uaParser.getOS().name || '';
  }

  get osVersion(): string {
    return this._uaParser.getOS().version || '';
  }

  get isIpadOs(): boolean {
    return this.osName === OsName.MacOS && 'ontouchend' in this._document;
  }

  get isIOS(): boolean {
    return this.osName === OsName.IOS;
  }

  get browserName(): string {
    return this._uaParser.getBrowser()?.name || '';
  }

  get isOldBrowser(): boolean {
    return (this.osName === OsName.IOS && !isNewerVersion(OLD_IOS_VERSION, this.osVersion)) || this.browserName === UC_BROWSER_NAME;
  }

  get isMozillaBrowser(): boolean {
    return this.browserName === 'Firefox' || this.browserName === 'Mozilla';
  }

  get isChromeBrowser(): boolean {
    return this.browserName === 'Chrome';
  }

  get isSafariBrowser(): boolean {
    return this.browserName.toLowerCase().includes('safari');
  }
}

const isNewerVersion = (oldVer: string, newVer: string): boolean => {
  const oldParts = oldVer.split('.');
  const newParts = newVer.split('.');

  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i]; // parse int
    const b = ~~oldParts[i]; // parse int

    if (a > b) {
      return true;
    }
    if (a < b) {
      return false;
    }
  }

  return false;
};
