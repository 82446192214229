import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { CartCounterActions, CartCounterSelectors } from './store';

@Component({
  selector: 'pu-cart-counter',
  templateUrl: './cart-counter.component.html',
  styleUrls: ['./cart-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartCounterComponent implements OnInit {
  @Input() top = '0';
  @Input() right = 'calc(50% - 20px)';

  private _store = inject(Store);
  quantity$ = this._store.select(CartCounterSelectors.selectQuantity);

  ngOnInit() {
    this._store.dispatch(CartCounterActions.init());
  }
}
