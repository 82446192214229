import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'ui-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent implements OnInit, ControlValueAccessor {
  /**
   * Form control binding
   */
  @Input() formControl: FormControl<string>;
  /**
   * Form control name binding
   */
  @Input() formControlName: string;
  /**
   * Control placeholder
   */
  @Input() placeholder = '';
  /**
   * Has search icon
   */
  @Input() hasSearchIcon = true;

  /**
   * Closed clicked event
   */
  @Output() closeClicked = new EventEmitter<void>();

  /**
   * Form control directive
   * @internal
   */
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

  control: FormControl<string>;

  constructor(@SkipSelf() @Optional() private _controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.control = this.formControl || (this._controlContainer.control.get(this.formControlName) as FormControl<string>);
  }

  /**
   * Register a function that will be called when the control is touched
   * @param fn - A function that is called when the control is touched.
   * @internal
   */
  registerOnTouched(fn: () => void): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  /**
   * Register a callback function that will be called when the value of the input changes
   * @param fn - A function that is called when the value changes.
   * @internal
   */
  registerOnChange(fn: (value: string) => void): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  /**
   * Write the value of the form control to the form control's value accessor
   * @param {string} value - The value to be written to the model.
   * @internal
   */
  writeValue(value: string): void {
    this.formControlDirective.valueAccessor.writeValue(value);
  }
}
