import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { TOAST_CONFIG_TOKEN, ToastConfig, ToastData } from './models';

const OVERLAY_DISPOSE_TIMEOUT = 1000;

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  /**
   * Current toasts data: Observable<ToastData[]>
   */
  currentToasts$: Observable<ToastData[]>;
  private _overlayRef: OverlayRef;
  private _currentToastsSubject: BehaviorSubject<ToastData[]> = new BehaviorSubject<ToastData[]>([]);

  constructor(private _overlay: Overlay, @Inject(TOAST_CONFIG_TOKEN) private _toastConfig: ToastConfig) {
    this.currentToasts$ = this._currentToastsSubject.asObservable();
  }

  /**
   * Show new toast in toaster component
   * @param {ToastData} toastData - ToastData
   */
  show(toastData: ToastData): void {
    if (!this._toastConfig.component) {
      console.error('Component nor provided! Component is required');

      return;
    }

    const currentArrValue = this._currentToastsSubject.getValue();
    const updateValue = [
      ...currentArrValue,
      {
        ...toastData,
        timeout: toastData?.timeout || this._toastConfig.timeout,
      },
    ];

    this._currentToastsSubject.next(updateValue);

    if (!this._overlayRef) {
      this.prepareOverlay();
    }
  }

  /**
   * Hide toast component from toaster
   * @param index: number
   */
  hide(index: number): void {
    const currentArrValue = [...this._currentToastsSubject.getValue()];

    currentArrValue.splice(index, 1);
    this._currentToastsSubject.next(currentArrValue);

    if (!currentArrValue.length) {
      // TODO: added timeout for correct toast animation
      setTimeout(() => {
        this._overlayRef.dispose();
        this._overlayRef = null;
      }, OVERLAY_DISPOSE_TIMEOUT);
    }
  }

  /**
   * Creates a new overlay and attaches _toasterPortal
   */
  prepareOverlay(): void {
    const positionStrategy = this._overlay
      .position()
      .global()
      .right(this._toastConfig.position?.right + 'px')
      .top(this._toastConfig.position?.top + 'px');

    this._overlayRef = this._overlay.create({ positionStrategy });
    this._overlayRef.attach(this._getToasterPortal());
  }

  /**
   * Creates a new toasterPortal
   */
  private _getToasterPortal(): ComponentPortal<unknown> {
    return new ComponentPortal(this._toastConfig.component, null, null);
  }
}
