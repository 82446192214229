import { Component, Input } from '@angular/core';

import { UserProfile } from '@pu/models';

@Component({
  selector: 'pu-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss'],
})
export class UserProfileCardComponent {
  @Input() user: UserProfile;
  @Input() colorfulAvatar = false;
}
