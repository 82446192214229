import { createAction } from '@ngrx/store';
import { createAsyncActionNoProps } from '@pu/store';

import { cartCounterFeatureKey } from './';
import { GetQuantityRes } from '../models';

const init = createAction(`[${cartCounterFeatureKey}]: Init`);
const getQuantityReq = createAsyncActionNoProps<GetQuantityRes>(`[${cartCounterFeatureKey}]: Get quantity`);

export const CartCounterActions = {
  init,
  getQuantityReq,
};
