import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { auditTime, fromEvent, map } from 'rxjs';
import { UiActions } from '@pu/store';
import { CommonMediaQueries, MediaScreenService } from '@pu/services';

import { GlobalSearchActions } from '../components/global-search/store';
import { NotificationsActions } from '../components/notifications/store';

@Injectable()
export class ShellEffects {
  showNavBar$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GlobalSearchActions.open, NotificationsActions.open),
      map(() => UiActions.elementsScrollVisibilityChanged({ isNavbarHidden: false, isShadowVisible: false })),
    ),
  );

  scrollPosition$ = createEffect(() => {
    let currentScrollPosition = 0;
    const scrollThreshold = this._mediaScreenService.isMatched(CommonMediaQueries.LG) ? 0 : 70;

    return fromEvent(this._document, 'scroll').pipe(
      auditTime(200),
      map((event: Event) => {
        const target = event.target as Document;
        const scroll = target.scrollingElement.scrollTop;
        const isScrollBottom = target.scrollingElement.clientHeight + scroll >= target.scrollingElement.scrollHeight;
        const visibilityChangedPayload = { isNavbarHidden: false, isShadowVisible: true };
        visibilityChangedPayload.isNavbarHidden = scroll > scrollThreshold && scroll > currentScrollPosition && !isScrollBottom;
        visibilityChangedPayload.isShadowVisible = scroll > scrollThreshold;
        currentScrollPosition = scroll;

        return UiActions.elementsScrollVisibilityChanged(visibilityChangedPayload);
      }),
    );
  });

  constructor(private _actions$: Actions, @Inject(DOCUMENT) private _document: Document, private _mediaScreenService: MediaScreenService) {}
}
