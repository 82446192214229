import { createSelector } from '@ngrx/store';

import { notificationsFeature } from './notifications.reducers';

const { selectNotificationsCount, selectNotifications, selectOpened } = notificationsFeature;

const selectCount = createSelector(selectNotificationsCount, ({ count }) => count);
const selectHasUnreadNotifications = createSelector(selectCount, count => count > 0);
const selectPrevTimestamp = createSelector(selectNotificationsCount, ({ prevTimestamp }) => prevTimestamp);

export const NotificationsSelectors = {
  selectCount,
  selectHasUnreadNotifications,
  selectPrevTimestamp,
  selectNotifications,
  selectOpened,
};
