import { isPositiveInteger } from '../is-positive-integer/is-positive-integer';
import { countryPhoneCodesPattern } from '../regexp-patterns/regexp-patterns';
import { removePhoneSpecialCharacters } from '../remove-phone-special-characters/remove-phone-special-characters';

/**
 * Count of digits to start match phone
 */
const MIN_DIGITS_COUNT_TO_MATCH_PHONE = 3;

export function isPhoneMatched(value: string): boolean {
  const replacedValue = removePhoneSpecialCharacters(value, true);

  return (
    replacedValue.length >= MIN_DIGITS_COUNT_TO_MATCH_PHONE &&
    countryPhoneCodesPattern.test(replacedValue) &&
    !replacedValue.includes('.') &&
    isPositiveInteger(replacedValue)
  );
}
