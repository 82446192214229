<img class="pu-avatar__img"
     *ngIf="image; else text"
     [src]="image"
     alt="Avatar">

<ng-template #text>
  <p class="pu-avatar__text"
     *ngIf="nameFirstLetter; else placeholder">
    {{ nameFirstLetter }}
  </p>
</ng-template>

<ng-template #placeholder>
  <ui-icon iconName="user"></ui-icon>
</ng-template>

<button
  class="pu-avatar__btn"
  type="button"
  *ngIf="isHover">
  <ui-icon iconName="edit"></ui-icon>
</button>
