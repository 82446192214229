import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { decodingUnicode, lineBreakReplacer, replaceHrefWithNative, unescape } from './decoded-safe-html.utils';

@Pipe({
  name: 'decodedSafeHtml',
})
export class DecodedSafeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string, shouldAddNative: boolean = false): SafeHtml {
    let decodedValue = value ? lineBreakReplacer(unescape(decodingUnicode(value))) : '';

    if (shouldAddNative) {
      decodedValue = replaceHrefWithNative(decodedValue);
    }

    return this._sanitizer.bypassSecurityTrustHtml(decodedValue);
  }
}
