import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateLoaderService implements TranslateLoader {
  constructor(private _http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    return this._http.get(`./assets/i18n/${lang}.json`);
  }
}
