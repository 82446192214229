import { createAction, props } from '@ngrx/store';
import { AppError } from '@pu/models';

const featureName = 'Errors';

/**
 * Action which is triggered after unexpected server error
 */
const unexpectedServerErrorHappened = createAction(`[${featureName}]: Unexpected server error`, props<{ error: AppError }>());

export const ErrorsActions = {
  unexpectedServerErrorHappened,
};
