import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DecodedSafeHtmlPipe } from './decoded-safe-html.pipe';

@NgModule({
  declarations: [DecodedSafeHtmlPipe],
  imports: [CommonModule],
  providers: [DecodedSafeHtmlPipe],
  exports: [DecodedSafeHtmlPipe],
})
export class DecodedSafeHtmlPipeModule {}
