import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { RootState } from '@pu/store';
import { Observable } from 'rxjs';

import { AccrualUserFormDialog } from '../accrual-user-form/accrual-user-form.dialog';
import { BalanceActions, BalanceSelectors, BalanceViewModel } from './store';

@Component({
  selector: 'pu-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
  @HostBinding('class.pu-balance_card')
  @Input()
  isCard = false;

  @Input() isTablet: boolean;

  vm$: Observable<BalanceViewModel> = this._store.select(BalanceSelectors.selectViewModel);

  constructor(private _accrualUserFormDialog: AccrualUserFormDialog, private _store: Store<RootState>) {}

  ngOnInit() {
    this._store.dispatch(BalanceActions.init());
  }

  edit() {
    this._store.dispatch(BalanceActions.getBalanceSkinsReq.action());
  }

  share() {
    this._accrualUserFormDialog.open('site');
  }
}
