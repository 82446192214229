import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BalanceSkin } from '@pu/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pu/ui';
import { DIALOG_DATA, DialogRef } from '@pu/services';
import { provideNgxMask } from 'ngx-mask';
import { trackById } from '@pu/utils';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, IconModule, ReactiveFormsModule],
  selector: 'pu-balance-skin-form',
  templateUrl: './balance-skin-form.component.html',
  styleUrls: ['./balance-skin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideNgxMask()],
})
export class BalanceSkinFormComponent {
  selectedSkin: BalanceSkin;
  balance: number;
  skins: BalanceSkin[];

  trackById = trackById;

  constructor(
    private _dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: { skins: BalanceSkin[]; balance: number; skin: BalanceSkin },
  ) {
    this.skins = data.skins;
    this.selectedSkin = data.skin;
    this.balance = data.balance;
  }

  selectSkin(skin: BalanceSkin) {
    this.selectedSkin = skin;
  }

  apply() {
    this._dialogRef.close({
      balanceSkinId: this.selectedSkin.id,
    });
  }

  close(): void {
    this._dialogRef.close();
  }
}
