import { combineReducers, createReducer, on } from '@ngrx/store';
import { PersonalInfo } from '@pu/models';

import { AuthActions, AuthState } from './';

const profileReducer = createReducer<PersonalInfo>(
  null,
  on(AuthActions.setProfile, (state, { profile }): PersonalInfo => profile),
);

const recoveryCodesReducer = createReducer(
  [],
  on(AuthActions.enable2fa.succeededAction, (state, { payload: { recoveryCodes } }): string[] => recoveryCodes),
);

const is2faEnabledReducer = createReducer(
  false,
  on(AuthActions.remove2fa.succeededAction, (): boolean => false),
  on(AuthActions.signIn.succeededAction, (state, { payload }): boolean => payload.is2faEnabled),
);

export const authReducer = combineReducers<AuthState>({
  profile: profileReducer,
  recoveryCodes: recoveryCodesReducer,
  is2faEnabled: is2faEnabledReducer,
});
