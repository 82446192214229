import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[puDisablePaste]',
})
export class DisablePasteDirective {
  @Input() allowPaste = false;

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (!this.allowPaste) {
      e.preventDefault();
    }
  }
}
