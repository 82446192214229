import { createSelector } from '@ngrx/store';
import { UiSelectors } from '@pu/store';

const selectShellViewModel = createSelector(
  UiSelectors.selectIsNavbarHiddenByScroll,
  UiSelectors.selectIsShadowVisibleByScroll,
  (isNavbarHiddenByScroll, isShadowVisibleByScroll) => ({
    isNavbarHiddenByScroll,
    isShadowVisibleByScroll,
  }),
);

export const ShellVmSelectors = {
  selectShellViewModel,
};
