<ng-container *puLet="vm$ | async as vm">
  <ng-container *uiSpinner="vm.isLoading">
    <ng-container [ngSwitch]="type">
      <button
        class="pu-support__btn"
        *ngSwitchCase="'popover'"
        uiButton
        size="m"
        [onlyIcon]="true"
        uiType="quaternary"
        [puPopover]="popover"
        [position]="['br', 'bl']"
        [offsetY]="3"
        scrollStrategy="close">

        <ui-icon iconName="faq2"></ui-icon>

        <ng-template
          #popover
          let-close="close">

          <div
            class="pu-support__popover"
            (click)="close()">

            <a
              class="pu-support__popover-item"
              routerLink="/faq"
              [queryParams]="{tagId: vm.tagId}"
              (click)="clickItem.emit()"
              translate="support.popover.item.faq">
            </a>

            <button
              class="pu-support__popover-item"
              *ngIf="vm.tipType"
              (click)="openTipsDialog(vm.tipType)"
              translate="support.popover.item.tips">
            </button>

            <button
              class="pu-support__popover-item"
              (click)="openContactDialog()"
              translate="support.popover.item.contact">
            </button>
          </div>
        </ng-template>
      </button>

      <div
        class="pu-support__nav"
        *ngSwitchCase="'list'">

        <button
          class="nav-item"
          (click)="toggleList()">
          <span class="pu-support__btn-toggle-content">
            {{ 'support.btn.list' | translate }}
            <ui-icon
              class="pu-support__icon-arrow"
              [class.opened]="opened"
              iconName="chevron-1">
            </ui-icon>
          </span>
        </button>

        <ng-container *ngIf="opened">
          <a
            class="nav-item"
            routerLink="/faq"
            [queryParams]="{tagId: vm.tagId}"
            routerLinkActive="nav-item_active"
            (click)="clickItem.emit()"
            translate="support.popover.item.faq">
          </a>

          <button
            class="nav-item"
            *ngIf="vm.tipType"
            (click)="openTipsDialog(vm.tipType)"
            translate="support.popover.item.tips">
          </button>

          <button
            class="nav-item"
            (click)="openContactDialog()"
            translate="support.popover.item.contact">
          </button>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
