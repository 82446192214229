import { AbstractControl, ValidationErrors } from '@angular/forms';

const DATE_LESS_THAN = 100;
const DATE_GREATER_THAN = 18;

export const birthDateValidator = (control: AbstractControl): ValidationErrors | null => {
  const today = new Date();
  const minDate = new Date(today.getFullYear() - DATE_LESS_THAN, 0, 1);
  const maxDate = new Date(today.getFullYear() - DATE_GREATER_THAN, today.getMonth(), today.getDate());

  if (control.value > maxDate || control.value < minDate) {
    return {
      dateInvalid: true,
    };
  }

  return null;
};
