import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, map, Observable, of, switchMap, takeUntil, tap, timer } from 'rxjs';
import { AuthActions, createAsyncActionEffect, KeyboardActions, RouterActions } from '@pu/store';
import { environment } from '@pu/environment';
import { Store } from '@ngrx/store';
import { DialogRef } from '@pu/services';

import { NotificationsActions, NotificationsSelectors } from './';
import { GetNotificationsCountRes, GetNotificationsRes, PatchNotificationsRes } from '../models';
import { getNotificationsCountMock$, getNotificationsListMock$ } from '../mocks';
import { BalanceActions } from '../../../../components/balance/store';
import { GlobalSearchActions } from '../../global-search/store';
import { NotificationsComponent } from '../notifications.component';
import { NotificationsDialogService } from '../dialog/notifications-dialog.service';

@Injectable()
export class NotificationsEffects {
  private _dialogRef: DialogRef = null;

  initNotificationsCount$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.initCount),
      switchMap(() => timer(0, 5000).pipe(takeUntil(this._actions$.pipe(ofType(AuthActions.logout))))),
      map(() => NotificationsActions.getNotificationsCountReq.action()),
    ),
  );

  initNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.initNotifications),
      map(() => NotificationsActions.getNotificationsReq.action()),
    ),
  );

  getNotificationsReq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.getNotificationsReq.action),
      switchMap(() => createAsyncActionEffect(this.getNotifications(), NotificationsActions.getNotificationsReq)),
    ),
  );

  getNotificationsCountReq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.getNotificationsCountReq.action),
      switchMap(() => createAsyncActionEffect(this.getNotificationsCount(), NotificationsActions.getNotificationsCountReq)),
    ),
  );

  updateNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.getNotificationsCountReq.succeededAction),
      concatLatestFrom(() => [
        this._store.select(NotificationsSelectors.selectOpened),
        this._store.select(NotificationsSelectors.selectPrevTimestamp),
      ]),
      filter(([{ payload }, opened, prevTimestamp]) => opened && prevTimestamp !== payload.lastNotificationTimestamp),
      map(() => NotificationsActions.getNotificationsReq.action()),
    ),
  );

  updateBalance$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.getNotificationsCountReq.succeededAction),
      concatLatestFrom(() => [this._store.select(NotificationsSelectors.selectPrevTimestamp)]),
      filter(([{ payload }, prevTimestamp]) => prevTimestamp !== payload.lastNotificationTimestamp),
      map(() => BalanceActions.getBalanceReq.action()),
    ),
  );

  readOne$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.readOneReq.action),
      switchMap(action => createAsyncActionEffect(this.readOne(action.payload.id), NotificationsActions.readOneReq)),
    ),
  );

  readAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.readAllReq.action),
      switchMap(() => createAsyncActionEffect(this.readAll(), NotificationsActions.readAllReq)),
    ),
  );

  refetchOnRead$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.readOneReq.succeededAction, NotificationsActions.readAllReq.succeededAction),
      switchMap(() => [NotificationsActions.getNotificationsReq.action(), NotificationsActions.getNotificationsCountReq.action()]),
    ),
  );

  openNotifications$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(NotificationsActions.open),
        tap(() => {
          if (!this._dialogRef) {
            this._dialogRef = this._dialog.open(NotificationsComponent);
          }
        }),
      ),
    { dispatch: false },
  );

  closeNotifications$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(NotificationsActions.close),
        tap(() => {
          this._dialogRef?.close();
          this._dialogRef = null;
        }),
      ),
    { dispatch: false },
  );

  toggleNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationsActions.toggle),
      concatLatestFrom(() => this._store.select(NotificationsSelectors.selectOpened)),
      switchMap(([, opened]) => [opened ? NotificationsActions.close() : NotificationsActions.open()]),
    ),
  );

  closeNotificationsOn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(RouterActions.routerNavigatedAction, GlobalSearchActions.open, KeyboardActions.escapePressed),
      map(() => NotificationsActions.close()),
    ),
  );

  constructor(private _actions$: Actions, private _http: HttpClient, private _store: Store, private _dialog: NotificationsDialogService) {}

  getNotifications(): Observable<GetNotificationsRes> {
    if (environment.useMocks) {
      return getNotificationsListMock$();
    }

    return this._http.get<GetNotificationsRes>(environment.apiHost + 'notification/');
  }

  getNotificationsCount(): Observable<GetNotificationsCountRes> {
    if (environment.useMocks) {
      return getNotificationsCountMock$();
    }

    return this._http.get<GetNotificationsCountRes>(environment.apiHost + 'notification/count/');
  }

  readAll(): Observable<PatchNotificationsRes> {
    if (environment.useMocks) {
      return of({});
    }

    return this._http.patch<PatchNotificationsRes>(environment.apiHost + 'notification/read-all/', { isRead: true });
  }

  readOne(id: number): Observable<PatchNotificationsRes> {
    if (environment.useMocks) {
      return of({});
    }

    return this._http.patch<PatchNotificationsRes>(environment.apiHost + `notification/${id}/`, { isRead: true });
  }
}
