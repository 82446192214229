import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { AppError } from '@pu/models';
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '@pu/ui';
import { environment } from '@pu/environment';

import { UiActions } from '../ui';
import { ErrorsActions } from './';

@Injectable()
export class ErrorsEffects {
  showErrorToast$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ErrorsActions.unexpectedServerErrorHappened),
      tap(action => {
        if (!environment.production) {
          console.error('Async action error: ', action.error);
        }
      }),
      map(action => UiActions.showToast({ toastType: ToastType.Error, message: this._detectMessage(action.error) })),
    ),
  );

  constructor(private _actions$: Actions, private _translate: TranslateService) {}

  private _detectMessage(error: AppError): string {
    return error.code ? this._translate.instant(`errors.${error.code}`) : error.message || this._translate.instant('errors.unknown');
  }
}
