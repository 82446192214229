import { Injector, NgModule } from '@angular/core';

import { ORDERED_APP_INITIALIZER, ORDERED_APP_PROVIDER } from '@pu/utils';
import { Store } from '@ngrx/store';

import { registerLocaleFactory } from './register-locale.factory';
import { rootEffectsFactory } from './root-effects.factory';

/**
 * This is the init module of the application.
 * Default APP_INITIALIZER not guarantee the order of factories execution.
 * ORDERED_APP_INITIALIZER token uses for ordered execution of factories.
 * It accepts factories which resolves Observable, not Promise
 * Provide init factories in correct order and the last provided token should be ORDERED_APP_PROVIDER.
 */
@NgModule({
  providers: [
    {
      provide: ORDERED_APP_INITIALIZER,
      useFactory: registerLocaleFactory,
      deps: [Injector],
      multi: true,
    },
    {
      provide: ORDERED_APP_INITIALIZER,
      useFactory: rootEffectsFactory,
      deps: [Store],
      multi: true,
    },
    ORDERED_APP_PROVIDER,
  ],
})
export class AppInitModule {}
