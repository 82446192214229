<ng-container *puLet="profile$ | async as profile">
  <ng-container *ngIf="profile">
    <pu-avatar
      class="pu-user-profile__avatar"
      size="72"
      [image]="profile.picture"
      [nameFirstLetter]="profile.email && profile.email[0]"
    ></pu-avatar>
    <h3 class="pu-user-profile__name">{{ profile.fullName }}</h3>
    <p class="pu-user-profile__position">{{ profile.position }}</p>
    <!-- <pu-achievement [type]="'horizontal'"></pu-achievement> -->
  </ng-container>
</ng-container>
