import { Entity } from './entity.model';
import { User } from './user.model';

export type TransactionGroup = 'income' | 'expense';
export type TransactionStatus = 'reward' | 'fromColleague' | 'refund' | 'purchase' | 'toColleague' | 'rollback';

export interface Transaction extends Entity {
  status: TransactionStatus;
  sum: number;
  createdAt: string;
  comment: string | null;
  user: User | null;
}
