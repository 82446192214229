<form class="pu-form-container"
      [formGroup]="form"
      (ngSubmit)="addTag()">

  <div class="pu-form-group">
    <pu-simple-input
      [formControlName]="nameField"
      [label]="nameLabelKey | translate">
    </pu-simple-input>

    <ng-container
      *ngTemplateOutlet="extraFieldTpl; context: { $implicit: form }">
    </ng-container>

    <button
      class="pu-form-btn"
      type="submit"
      uiButton
      size="l"
      uiType="secondary"
      [disabled]="!form.valid">
      {{addBtnKey | translate}}
    </button>
  </div>
</form>

<div class="ui-tags__list">
  <div class="ui-tags__item"
       *ngFor="let tag of tags; trackBy: trackById">
    {{tag[nameField]}}
    <button
      type="button"
      uiButton
      size="m"
      uiType="quaternary"
      [onlyIcon]="true"
      (click)="delTag(tag)">
      <ui-icon iconName="close" size="16"></ui-icon>
    </button>
  </div>
</div>
