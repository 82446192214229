<ng-container *uiSpinner="isLoading">
  <div class="pu-support-contact__header">
    <h4
      class="pu-support-contact__header-text"
      translate="support.contactDialog.header">
    </h4>

    <button
      class="pu-support-contact__close"
      type="button"
      uiButton
      size="m"
      uiType="quaternary"
      [onlyIcon]="true"
      (click)="cancel()">
      <ui-icon
        iconName="close"
        size="16">
      </ui-icon>
    </button>
  </div>

  <form
    class="pu-support-contact__body"
    [formGroup]="form"
    (ngSubmit)="send()">

    <img
      class="pu-support-contact__img"
      src="assets/images/support.png"
      alt="Contact support icon">

    <p
      class="pu-support-contact__text"
      [innerHTML]="'support.contactDialog.text' | translate">
    </p>

    <pu-text-area
      class="pu-form-container"
      formControlName="question"
      [label]="'support.contactDialog.control.question' | translate">
    </pu-text-area>

    <ui-uploader
      class="pu-form-container"
      formControlName="images"
      apiS3="upload/image/"
      accept=".jpg,.jpeg,.png"
      labelKey="support.contactDialog.control.images.label"
      placeholderKey="support.contactDialog.control.images.placeholder"
      [multiple]="true"
      [hiddenPlaceholder]="!!imagesControl.value?.length">
    </ui-uploader>

    <div class="pu-support-contact__actions">
      <button
        type="submit"
        uiButton
        size="l"
        uiType="primary"
        [disabled]="!form.valid"
        translate="support.contactDialog.btn.send">
      </button>

      <button
        type="button"
        uiButton
        size="l"
        uiType="secondary"
        (click)="cancel()"
        translate="support.contactDialog.btn.cancel">
      </button>
    </div>
  </form>
</ng-container>
