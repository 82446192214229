import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DonutChartComponent } from './donut-chart.component';
import { DonutChartPipe } from './donut-chart.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DonutChartComponent, DonutChartPipe],
  exports: [DonutChartComponent],
})
export class DonutChartModule {}
