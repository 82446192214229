import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'pu-floating-label-outline',
  templateUrl: './floating-label-outline.component.html',
  styleUrls: ['./floating-label-outline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingLabelOutlineComponent {
  /**
   * Is active
   */
  @HostBinding('class.pu-floating-label-outline_gap')
  @Input()
  hasGap: boolean;

  /**
   * Is active
   */
  @HostBinding('class.pu-floating-label-outline_highlighted')
  @Input()
  isHighlighted: boolean;

  /**
   * Is readonly
   */
  @HostBinding('class.pu-floating-label-outline_read-only')
  @Input()
  isReadOnly: boolean;

  /**
   * Is readonly
   */
  @HostBinding('class.pu-floating-label-outline_invalid')
  @Input()
  isInvalid: boolean;

  /**
   * Label
   * Needed for correct width
   */
  @Input() label: string;

  /**
   * Label size
   * Needed for correct width
   */
  @Input() labelSize: 's' | 'm';
}
