/**
 * GOOGLE JWT token local storage key
 */
export const GOOGLE_JWT_TOKEN_KEY = 'google-jwt-token';

/**
 * Auth token local storage key
 */
export const AUTH_TOKEN_KEY = 'auth-token';

/**
 * Refresh token local storage key
 */
export const REFRESH_TOKEN_KEY = 'refresh-token';
