import { Entity, StrNum } from '@pu/models';

export interface DictionaryItem<T extends StrNum = number> extends Entity<T> {
  name: string;
  code?: string;
  description?: string;
}

export type SortType = DictionaryItem<string>;
export type SlackChannel = DictionaryItem;
