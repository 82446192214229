import { random } from './random';

/*
 * Counter for method 'uniqueId'
 */
let counter = 0;

/*
 * Salt for method 'uniqueId'
 */
const salt: number = random(6);

/**
 * Returns unique id
 */
export function uniqueId(): number {
  return salt + ++counter;
}
