<ng-container *puLet="control.touched && control.invalid && !showNewPasswordHints as isInvalid">
  <div class="pu-control__content">
    <input
      class="pu-control__input pu-control__input_padding"
      [type]="isPasswordVisible ? controlTypes.Text : controlTypes.Password"
      [id]="controlId"
      [class.pu-control__input_invalid]="isInvalid"
      [readonly]="isReadonly"
      [placeholder]="placeholder"
      (focus)="setIsFocused(true)"
      (blur)="setIsFocused(false)"
      [autocomplete]="autocomplete"
      [formControl]="control"
      [attr.data-testid]="controlTestId"
    />
    <label
      *ngIf="label"
      [for]="controlId"
      class="pu-control__label"
      [ngClass]="{
        'pu-control__label_invalid': isInvalid,
        'pu-control__label_active': control.value || placeholder,
        'pu-control__label_small': labelSize === 's'
      }"
    >{{ label }}</label
    >
    <pu-floating-label-outline
      [hasGap]="isFocused || !!control.value || !!placeholder"
      [label]="label"
      [labelSize]="labelSize"
      [isHighlighted]="isFocused"
      [isInvalid]="isInvalid"
      [isReadOnly]="isReadonly"
    ></pu-floating-label-outline>
    <ui-icon
      *ngIf="showToggle"
      class="pu-control__icon"
      size="20"
      (click)="toggleVisibility()"
      [iconName]="isPasswordVisible ? 'eye-hiding' : 'eye-showing'"
      [attr.data-testid]="controlTestId + 'VisibleBtn'"
    ></ui-icon>
  </div>

  <pu-validation-message *ngIf="isInvalid && showValidationMessage"
                         [errors]="control.errors$ | async"></pu-validation-message>
</ng-container>

<div
  class="pu-password-input__validators"
  [class.pu-password-input__validators_touched]="control.touched"
  *ngIf="showNewPasswordHints && wasFocused"
>
  <p
    *puLet="!(
        control.value === '' ||
        control.errors?.['minlength'] ||
        control.errors?.['maxlength']) as isValid"
    class="pu-password-input__validators-item"
    [class.pu-password-input__validators-item_active]="isValid"
  >
    <ui-icon class="pu-password-input__validators-icon" size="12"
             [iconName]="isValid ? 'checkpoint-yes' : 'checkpoint-no'"></ui-icon>
    {{ 'validationMessages.passwordSymbolsLength' | translate }}
  </p>
  <p
    *ngIf="control.errors?.['whitespacePresented']"
    class="pu-password-input__validators-item pu-password-input__validators-item__active"
  >
    <ui-icon class="pu-password-input__validators-icon" size="12" iconName="checkpoint-no"></ui-icon>
    {{ 'validationMessages.whitespaceNotAllowed' | translate }}
  </p>
</div>
