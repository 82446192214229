import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ButtonModule, IconModule } from '@pu/ui';

import { EmojiComponent } from './emoji.component';

@NgModule({
  imports: [CommonModule, ButtonModule, IconModule, PickerModule],
  declarations: [EmojiComponent],
  exports: [EmojiComponent],
})
export class EmojiModule {}
