<form
  class="pu-tfa__body"
  [formGroup]="form"
  (ngSubmit)="verify()">

  <ui-icon
    iconName="p"
    size="96">
  </ui-icon>

  <h4
    class="pu-tfa__header"
    translate="tfa.header">
  </h4>

  <p
    class="pu-tfa__message"
    translate="tfa.message2">
  </p>

  <pu-simple-input
    class="pu-form-container"
    formControlName="code"
    [errorDictionary]="errorDictionary"
    [label]="'tfa.control.code' | translate">
  </pu-simple-input>

  <div class="pu-tfa__actions">
    <button
      type="submit"
      uiButton
      size="l"
      uiType="primary"
      [disabled]="!form.valid || isLoading"
      translate="tfa.btn.verify">
    </button>
  </div>

  <p class="pu-tfa__message">
    {{ 'tfa.help1' | translate }}
    <a
      uiButton
      uiType="quaternary"
      (click)="openResetDialog()">
      {{ 'tfa.help2' | translate }}
    </a>
  </p>
</form>
