<div class="pu-date-range-picker__head">
  <div class="pu-date-range-picker__state">
    {{ selectedDateRange?.start ? (selectedDateRange.start | date : 'dd-MM-yyyy') : '-' }}
  </div>
  <div class="pu-date-range-picker__state">
    {{ selectedDateRange?.end ? (selectedDateRange.end | date : 'dd-MM-yyyy') : '-' }}
  </div>  
</div>

<mat-calendar
  #calendar
  [selected]="selectedDateRange"
  [startAt]="selectedDateRange?.start"
  (selectedChange)="changed($event)"
  [maxDate]="maxDate"
  [minDate]="minDate"
></mat-calendar>
