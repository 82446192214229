import { createSelector } from '@ngrx/store';
import { BalanceSkin, RubricTag } from '@pu/models';
import { AuthSelectors } from '@pu/store';

import { balanceFeature } from './balance.reducers';

const {
  selectBalance,
  selectTags,
  selectGetBalanceAction,
  selectGetBalanceSkinsAction,
  selectApplyBalanceSkinAction,
  selectGetTagsAction,
} = balanceFeature;

export interface BalanceViewModel {
  skin: BalanceSkin;
  balance: number;
  pincoinsTag: RubricTag;
  isLoading: boolean;
}

const selectViewModel = createSelector(
  AuthSelectors.selectProfile,
  selectBalance,
  selectTags,
  selectGetBalanceSkinsAction,
  selectApplyBalanceSkinAction,
  selectGetTagsAction,
  (profile, balance, tags, GetBalanceSkinsAction, applyBalanceSkinAction, getTagsAction): BalanceViewModel => ({
    skin: profile?.balanceSkin,
    balance,
    pincoinsTag: tags.tags.find(tag => tag.name.toLowerCase() === 'pincoins'),
    isLoading: GetBalanceSkinsAction.inProgress || applyBalanceSkinAction.inProgress || getTagsAction.inProgress,
  }),
);

export const BalanceSelectors = { selectBalance, selectGetBalanceAction, selectViewModel };
