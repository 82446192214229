import { Entity } from '@pu/models';

export interface SelectDropdownListItem<T extends number | string = number> extends Entity<T> {
  icon?: string;
  img?: string;
  emitWithNoChange?: boolean;
  placeholder?: string;

  [key: string]: any;
}
