import { BalanceSkin } from '@pu/models';

export const getBalanceSkinsMock = (count = 12): BalanceSkin[] => {
  const balanceSkins: BalanceSkin[] = [];

  for (let i = 0; i <= count; i++) {
    balanceSkins.push({
      id: i,
      url: `/assets/images/balance/balance-card-mock${i % 2 === 0 ? '-dark' : ''}.jpg`,
      name: `${i}.jpg`,
      isDark: i % 2 === 0 ? true : false,
    });
  }

  return balanceSkins;
};
