<div class="pu-ok__header">
  <h4 class="pu-ok__header-text">
    {{headerKey | translate}}
  </h4>

  <button
    class="pu-ok__close"
    type="button"
    *ngIf="!notCanceled"
    uiButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="cancel()">
    <ui-icon iconName="close" size="16"></ui-icon>
  </button>
</div>

<div class="pu-ok__body">
  <img class="pu-ok__img"
       *ngIf="imageUrl"
       [src]="imageUrl">

  <ui-icon
    *ngIf="iconName"
    [iconName]="iconName"
    size="96">
  </ui-icon>

  <p class="pu-ok__message"
     *ngIf="messageKey"
     [translate]="messageKey"
     [translateParams]="messageParams">
  </p>

  <p class="pu-ok__sub-message"
    *ngIf="subMessageKey"
    [translate]="subMessageKey"
    [translateParams]="subMessageParams">
  </p>

  <div class="pu-ok__actions">
    <button
      type="button"
      uiButton
      size="l"
      uiType="primary"
      (click)="ok()">
      {{btnOkKey | translate}}
    </button>

    <button
      type="button"
      *ngIf="!notCanceled"
      uiButton
      size="l"
      uiType="secondary"
      (click)="cancel()">
      {{btnCancelKey | translate}}
    </button>
  </div>
</div>
