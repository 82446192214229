import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncActionReducer } from '@pu/store';

import { BirthdaysWidgetActions, birthdaysWidgetFeatureKey, BirthdaysWidgetState } from './index';
import { GetTodayBirthdaysRes } from '../models';

const todayBirthdaysReducer = createReducer(
  [],
  on(BirthdaysWidgetActions.init, (state, action): GetTodayBirthdaysRes['todayBirthdays'] => []),
  on(
    BirthdaysWidgetActions.getTodayBirthdays.succeededAction,
    (state, { payload }): GetTodayBirthdaysRes['todayBirthdays'] => payload.todayBirthdays,
  ),
);

export const birthdaysWidgetFeature = createFeature({
  name: birthdaysWidgetFeatureKey,
  reducer: combineReducers<BirthdaysWidgetState>({
    todayBirthdays: todayBirthdaysReducer,
    getTodayBirthdaysAction: createAsyncActionReducer(BirthdaysWidgetActions.getTodayBirthdays),
  }),
});
