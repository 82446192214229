/**
 * Downloads file
 */
export function downloadFile(url: string): boolean {
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

  // If in Chrome or Safari download via virtual link click
  if (isChrome || isSafari) {
    const link = document.createElement('a');
    link.href = url;

    // Trigger click event
    if (document.createEvent) {
      const event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      link.dispatchEvent(event);

      return true;
    }
  }

  window.open(url, '_self');

  return true;
}
