/**
 * Normalize number by range
 * @param value
 * @param min
 * @param max
 * @param roundOn - if there is need to round
 * @param emptyOff - if value cannot be null
 */
export function normalizeNumber(
  value: number | null,
  min: number | null,
  max?: number | null,
  roundOn = false,
  emptyOff = false,
): number | null {
  value = typeof value === 'number' ? (roundOn ? Math.floor(value) : value) : null;

  if (typeof value === 'number') {
    if (typeof min === 'number' && value < min) {
      value = min;
    }

    if (typeof max === 'number' && value > max) {
      value = max;
    }
  } else if (emptyOff && typeof min === 'number') {
    value = min;
  }

  return value;
}
