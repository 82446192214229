import { Component, ChangeDetectionStrategy, forwardRef, OnInit, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

import { LetModule } from '@pu/directives';
import { DateUtil } from '@pu/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxMaskDirective } from 'ngx-mask';
import { FormControl } from '@ngneat/reactive-forms';

import { AbstractInputComponent } from '../abstract-input.component';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { ValidationMessageModule } from '../../components/validation-message/validation-message.module';

const NGX_DATE_MASK = 'd0.M0.0000';

@UntilDestroy()
@Component({
  selector: 'pu-date-input-picker',
  templateUrl: './date-input-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateInputPickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgClass,
    LetModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    FloatingLabelOutlineComponentModule,
    ValidationMessageModule,
  ],
})
export class DateInputPickerComponent extends AbstractInputComponent<Date | string> implements OnInit, Validator {
  @Input() value: string;
  ngxDateMask = NGX_DATE_MASK;
  dateControl: FormControl<string> = new FormControl<string>(null);
  onChange = (date: Date): void => null;

  override ngOnInit() {
    super.ngOnInit();

    this.dateControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => this.onChange(value ? DateUtil.fromFormattedDate(value) : null));
  }

  override registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  override writeValue(value: Date) {
    this.dateControl.setValue(value ? DateUtil.formatDate(value) : null, { emitEvent: false });
  }

  validate(): ValidationErrors | null {
    if (this.dateControl.value && !DateUtil.isFormattedDateValid(this.dateControl.value)) {
      return {
        dateInvalid: true,
      };
    }

    return null;
  }
}
