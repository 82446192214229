import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[puRendered]',
  standalone: true,
})
export class RenderedDirective implements AfterViewInit {
  @Output('puRendered') rendered = new EventEmitter();

  ngAfterViewInit() {
    this.rendered.emit();
  }
}
