<ng-container *puLet="vm$ | async as vm">
  <span class="pu-birthdays__label">
    {{ 'header.birthdays.todayBirthdays' | translate }}
  </span>
  <pu-avatar
    *ngFor="let user of vm.todayBirthdays | slice:0:(vm.todayBirthdays.length === maxBirthdays + 1 ? maxBirthdays + 1 : maxBirthdays); trackBy: trackById"
    [image]="user.imageUrl"
    [nameFirstLetter]="user.email && user.email[0]"
  >
  </pu-avatar>
  <span
    class="pu-birthdays__all"
    *ngIf="vm.todayBirthdays.length > maxBirthdays + 1">
    +{{ vm.todayBirthdays.length - maxBirthdays }}
  </span>
</ng-container>
