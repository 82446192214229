import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { trackByIndex } from '@pu/utils';
import { NavItem } from '@pu/ui';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'pu-sidebar, [puSidebar]',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  sidebarItems: NavItem[] = [
    {
      label: 'nav.homePage',
      icon: 'home',
      link: '/',
      isRoot: true,
    },
    {
      label: 'nav.personalInfo',
      icon: 'info',
      link: '/personal-info',
    },
    {
      label: 'nav.news',
      icon: 'news-2',
      link: '/news',
    },
    {
      label: 'nav.shop',
      icon: 'cart',
      link: '/shop',
    },
    {
      label: 'nav.myQuests',
      icon: 'tasks',
      link: '/my-quests',
    },
    {
      label: 'nav.myWishlist',
      icon: 'heart',
      link: '/my-wishlist',
    },
    {
      label: 'nav.myOrders',
      icon: 'shopping-bag',
      link: '/my-orders',
    },
  ];

  @Input() isTablet: boolean;

  trackByIndex = trackByIndex;

  constructor(private _permissions: NgxPermissionsService, private _cd: ChangeDetectorRef) {
    this._permissions.hasPermission('admin').then(has => {
      if (has) {
        this.sidebarItems.unshift({
          label: 'nav.adminPanel',
          icon: 'admin',
          link: '/admin',
        });
        this._cd.detectChanges();
      }
    });
  }
}
