import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Observable } from 'rxjs';

import { ToastService } from '../toast.service';
import { ToastData } from '../models';
import { slideInAnimation, slideOutAnimation, triggerChildAnimation, verticalCollapseAnimation } from '../animations';

@Component({
  selector: 'ui-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInAnimation, slideOutAnimation, triggerChildAnimation, verticalCollapseAnimation],
})
export class ToasterComponent {
  currentToasts$: Observable<ToastData[]> = this._toastService.currentToasts$;

  constructor(private _toastService: ToastService) {}

  close = (index: number) => {
    this._toastService.hide(index);
  };
}
