// reference to 'ngx-mask/lib/ngx-mask.config';
export interface NgxMaskPatterns {
  [character: string]: NgxMaskPattern;
}
// https://github.com/JsDaddy/ngx-mask#custom-pattern-for-this
interface NgxMaskPattern {
  pattern: RegExp;
  optional?: boolean;
  symbol?: string;
}

const allowOnlyDigitsPattern: NgxMaskPattern = {
  pattern: new RegExp('\\d'),
};

const allowAllCharactersPattern: NgxMaskPattern = {
  // allow all characters
  pattern: new RegExp('.'),
};

export const ngxPatterns: NgxMaskPatterns = {
  // Character which used in mask, example 'BBB (BB) BBB B*'
  B: allowAllCharactersPattern,
  D: allowOnlyDigitsPattern,
};
