/**
 * Function which convert JS Date to date string in format 'dd.MM.yyyy'
 * @param date {Date}
 * @return {string}
 */
const formatDate = (date: Date): string => [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('.');

const padTo2Digits = (num: number): string => num.toString().padStart(2, '0');

/**
 * Function which convert string date 'dd.MM.yyyy' to JS Date
 * @param date {string}
 * @return {Date}
 */
const fromFormattedDate = (date: string): Date => {
  const [day, month, year] = date.split('.');

  return new Date(Number(year), Number(month) - 1, Number(day));
};

const isFormattedDateValid = (date: string): boolean => {
  const splitDate = date.split('.');
  const day = Number(splitDate[0]);
  const month = Number(splitDate[1]);
  const year = Number(splitDate[2]);
  const lastDayOfMonth = new Date(year, month, 0).getDate();

  if (date.length !== 10) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  return !(day < 1 || day > lastDayOfMonth);
};

/**
 * Resolves start of the day for given Date
 * @param date
 */
const startOfTheDay = (date: Date): Date => {
  const startOfTheDay = new Date(date.getTime());
  startOfTheDay.setHours(0, 0, 0, 0);

  return startOfTheDay;
};

/**
 * Resolves end of the day for given Date
 * @param date
 */
const endOfTheDay = (date: Date): Date => {
  const endOfTheDay = new Date(date.getTime());
  endOfTheDay.setHours(23, 59, 59, 999);

  return endOfTheDay;
};

/**
 * Subtracts days from given date
 * @param date
 * @param num
 */
const subtractDays = (date: Date, num: number): Date => {
  const result = new Date(date.getTime());
  result.setDate(result.getDate() - num);

  return result;
};

/**
 * Add days to given date
 * @param date
 * @param num
 */
const addDays = (date: Date, num: number): Date => {
  const result = new Date(date.getTime());
  result.setDate(result.getDate() + num);

  return result;
};

/**
 * Add hours to given date
 * @param date
 * @param num
 */
const addHours = (date: Date, num: number): Date => {
  const result = new Date(date.getTime());
  result.setTime(result.getTime() + num * 60 * 60 * 1000);

  return result;
};

/**
 * Resolves start of the Month
 * @param date
 */
const startOfMonth = (date: Date): Date => {
  const dateMonth = new Date(date).getMonth();
  const dateYear = new Date(date).getFullYear();

  return new Date(`${dateYear}/${dateMonth + 1}/1`);
};

/**
 * Converts date to unix
 * @param date
 */
const toUnix = (date: Date): number => Math.floor(date.getTime() / 1000);

/**
 * Converts unix to date
 * @param date
 */
const fromUnix = (unix: number): Date => new Date(unix * 1000);

const isDateExpired = (date: Date): boolean => date.getTime() < new Date().getTime();

const secondsToMilliseconds = (seconds: number): number => seconds * 1000;

export const DateUtil = {
  formatDate,
  startOfTheDay,
  toUnix,
  fromUnix,
  endOfTheDay,
  subtractDays,
  fromFormattedDate,
  startOfMonth,
  addDays,
  isDateExpired,
  secondsToMilliseconds,
  addHours,
  isFormattedDateValid,
};
