import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { ToastService } from '@pu/ui';

import { RootActions } from '../root';
import { UiActions, UI_IS_DARK_THEME_KEY } from './';

@Injectable()
export class UiEffects {
  initTheme$ = createEffect(() =>
    this._actions$.pipe(
      ofType(RootActions.appInitialized),
      map(action => {
        const isDarkTheme = window.localStorage.getItem(UI_IS_DARK_THEME_KEY);

        return UiActions.setTheme({ isDarkTheme: isDarkTheme === 'true' });
      }),
    ),
  );

  setDarkTheme$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UiActions.setTheme),
        tap(({ isDarkTheme }) => {
          window.localStorage.setItem(UI_IS_DARK_THEME_KEY, isDarkTheme ? 'true' : 'false');
        }),
      ),
    {
      dispatch: false,
    },
  );

  showToast$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(UiActions.showToast),
        tap(action => {
          this._toastService.show({
            type: action.toastType,
            message: action.message,
            title: action.title,
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(private _actions$: Actions, private _router: Router, private _toastService: ToastService) {}
}
