import { BalanceSkin } from '.';
import { Entity } from './entity.model';

export type AchievementType = 'only-icon' | 'horizontal' | 'vertical';

export interface Achievement {
  id: number;
  title: string;
  picture: string;
}

export interface UserGroup extends Entity {
  name: string;
}

export interface User extends Entity {
  fullName: string;
}

export interface UserProfile extends User {
  imageUrl: string;
  position: string;
  email: string;
}

export interface Manager extends User {
  picture: string;
  position: string;
  email: string;
}

export interface Managers {
  directManager: Manager | null;
  functionalManager: Manager | null;
  peoplePartner: Manager | null;
}

export interface MainUserInfo {
  email: string;
  location: string;
  locationId: number;
  birthday: string;
  team: string;
  segment: string;
}

export interface Colleague extends User, MainUserInfo, Managers, UserSlackId {
  achievements: Achievement[];
  hobby: string;
  picture: string;
  position: string;
  startDate: string;
}

export interface PersonalInfoMainInfo extends MainUserInfo {
  status: string;
}

export interface PersonalInfo extends User, PersonalInfoMainInfo, Managers {
  position: string;
  hobby: string;
  startDate: string;
  personalYearStart: string;
  picture: string;
  phone: string;
  myGoals: string;
  vacationAvailablePaid: number;
  personalYearEnd: string;
  updated: string;
  balance: number;
  is2faEnabled: boolean;
  balanceSkin: BalanceSkin;
}

export interface SigninWithCodeData {
  token: string;
  is2faEnabled: boolean;
}

export interface Enable2FAData {
  qrCode: string;
  manualSetupUri: string;
  recoveryCodes: string[];
}

export type UserStatusType = 'Active' | 'Probation' | 'Offboarding';

export interface UserAdmin extends User {
  email: string;
  phone: string | null;
  status: UserStatusType;
  balance: number;
}

export interface UserInfoMainInfo extends MainUserInfo {
  status: UserStatusType;
}

export interface UserGroups {
  groups: UserGroup[];
}

export interface AdditionalInfo {
  hobby: string;
  phone: string;
  startDate: string;
  personalYearStart: string;
  personalYearEnd: string;
}

export interface UserInfoAdmin extends User, AdditionalInfo, UserInfoMainInfo, Managers, UserGroups, UserSlackId {
  position: string;
  balance: number;
  picture: string;
  achievements: Achievement[];
}

export interface AccessData {
  refresh: string;
  access: string;
  permissions: string[];
}

export interface UserSlackId {
  slackId: string;
}

export interface UserOrgTree extends Partial<UserSlackId>, UserProfile {
  locationName: string;
  segment: string;
  directManagerId: number | null;
  functionalManagerId: number | null;
  isRoot?: boolean;
  isActive: boolean;
}

export type PersonalInfoAdditionalInfo = Pick<PersonalInfo, 'phone' | 'startDate' | 'personalYearStart' | 'personalYearEnd' | 'hobby'>;
export type PersonalInfoRest = Omit<PersonalInfo, keyof PersonalInfoMainInfo | keyof PersonalInfoAdditionalInfo | keyof Managers>;

export type GlobalSearchPerson = UserProfile;

export type UserInfoRestAdmin = Omit<UserInfoAdmin, keyof AdditionalInfo | keyof UserInfoMainInfo | keyof Managers | keyof UserGroups>;
