import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SumWithOptionInputComponent } from './sum-with-option-input.component';
import { InputNumberDirectiveModule } from '../../directives';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { ValidationMessageModule } from '../../components/validation-message/validation-message.module';
import { CheckboxComponentModule } from '../checkbox/checkbox.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputNumberDirectiveModule,
    FloatingLabelOutlineComponentModule,
    ValidationMessageModule,
    CheckboxComponentModule,
  ],
  declarations: [SumWithOptionInputComponent],
  exports: [SumWithOptionInputComponent],
})
export class SumWithOptionInputComponentModule {}
