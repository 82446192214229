<ui-nav-item [item]="navbarItems.home">
</ui-nav-item>

<ui-nav-item [item]="navbarItems.news">
</ui-nav-item>

<ui-nav-item
  [item]="navbarItems.menu"
  (clicked)="openMenu.emit()">
</ui-nav-item>

<ng-container *ngIf="isTabletMode; else alerts">
  <ui-nav-item [item]="navbarItems.shop">
  </ui-nav-item>
</ng-container>

<ng-template #alerts>
  <ui-nav-item
    [item]="navbarItems.alerts"
    (clicked)="toggleNotifications.emit($event)">
    <div
      class="not-read-dot"
      *ngIf="hasUnreadNotifications$ | async">
    </div>
  </ui-nav-item>
</ng-template>

<ui-nav-item [item]="navbarItems.cart">
  <pu-cart-counter top="5px"></pu-cart-counter>
</ui-nav-item>
