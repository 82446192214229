import { UserProfile } from './user.model';
import { Entity } from './entity.model';

export type GroupAdminType = 'from_jira' | 'individual';

export interface GroupQuery {
  field: string;
  value: string;
}

export interface Group extends Entity {
  name: string;
}

export interface GroupAdmin extends Group {
  visible: boolean;
  usersCount: number;
}

export interface GroupDetailsAdmin extends GroupAdmin {
  groupType: GroupAdminType;
  query: GroupQuery[];
  users?: UserProfile[];
}

export interface GroupByGroup {
  fieldName: string;
  displayName: string;
  possibleValues: string[];
}
