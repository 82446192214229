import { AsyncActionState } from '@pu/store';

import { GetTagsRes, GetTipsRes, SendMessageRes } from '../models';

export const supportFeatureKey = 'Support';

export interface SupportState {
  tags: GetTagsRes;
  tagId: number;
  tipType: string;
  getTagsAction: AsyncActionState<GetTagsRes>;
  getTipsAction: AsyncActionState<GetTipsRes>;
  sendMessageAction: AsyncActionState<SendMessageRes>;
}
