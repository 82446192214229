import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FloatingLabelOutlineComponent } from './floating-label-outline.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FloatingLabelOutlineComponent],
  exports: [FloatingLabelOutlineComponent],
})
export class FloatingLabelOutlineComponentModule {}
