import { Directive, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '@pu/environment';
import { OnChange } from '@pu/decorators';
import { UaParserService } from '@pu/services';

@Directive({
  selector: '[puSlackLink]',
  standalone: true,
})
export class SlackLinkDirective {
  @OnChange('_slackIdChanged') @Input('puSlackLink') slackId: string;

  @HostBinding('attr.href') href: SafeResourceUrl;

  constructor(private _uaParserService: UaParserService, private _sanitizer: DomSanitizer) {}

  private _slackIdChanged(slackId: string) {
    if (slackId) {
      const slackTeamId = environment.slackTeamId;

      this.href = this._sanitizer.bypassSecurityTrustResourceUrl(
        this._uaParserService.isDesktop
          ? `https://slack.com/app_redirect?channel=${slackId}&team=${slackTeamId}`
          : `slack://user?team=${slackTeamId}&id=${slackId}`,
      );
    }
  }
}
