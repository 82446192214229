import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LetModule, PopoverDirective } from '@pu/directives';
import { ButtonModule, IconModule, SpinnerModule } from '@pu/ui';

import { SupportComponent } from './support.component';
import { supportFeature, SupportEffects } from './store';
import { TipsDialog } from './components/tips/tips.dialog';
import { ContactDialog } from './components/contact/contact.dialog';

@NgModule({
  declarations: [SupportComponent],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(supportFeature),
    EffectsModule.forFeature([SupportEffects]),
    TranslateModule,
    LetModule,
    IconModule,
    ButtonModule,
    SpinnerModule,
    PopoverDirective,
  ],
  providers: [TipsDialog, ContactDialog],
  exports: [SupportComponent],
})
export class SupportModule {}
