import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { trackById } from '@pu/utils';

import { DonutSlice } from './donut-chart.model';

@Component({
  selector: 'pu-donut-chart',
  styleUrls: ['./donut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg *ngIf="slices" [attr.height]="viewBox" [attr.width]="viewBox" [attr.viewBox]="'0 0 ' + viewBox + ' ' + viewBox">
      <path
        *ngFor="let slice of slices | puDonutChartSlices : radius : viewBox : borderSize : gap; trackBy: trackById"
        [attr.fill]="slice.color"
        [attr.d]="slice.commands"
        [attr.transform]="'rotate(' + slice.offset + ')'"
        (click)="slice.onClick ? slice.onClick() : null">
        <title>{{ slice.label }}</title>
      </path>
    </svg>
  `,
})
export class DonutChartComponent implements OnInit {
  @Input() slices: DonutSlice[] = [];
  @Input() viewBox = 120;
  @Input() borderSize = 10;
  @Input() gap = 4;

  radius: number;
  trackById = trackById;

  ngOnInit() {
    const sum = this.slices?.reduce((acc, slice) => acc + slice.percent, 0);
    if (sum !== 100) {
      throw new Error(`The sum of all slices of the donut chart must equal to 100%. Found: ${sum}.`);
    }
    this.radius = Math.floor(this.viewBox / 2);
  }
}
