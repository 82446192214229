import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticleCardSmallComponent } from './article-card-small.component';

@NgModule({
  declarations: [ArticleCardSmallComponent],
  imports: [CommonModule],
  exports: [ArticleCardSmallComponent],
})
export class ArticleCardSmallModule {}
