import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { NavItemComponent } from './nav-item.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  imports: [CommonModule, RouterModule, IconModule, TranslateModule],
  declarations: [NavItemComponent],
  exports: [NavItemComponent],
})
export class NavItemModule {}
