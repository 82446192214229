import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';

import { FileUploadModule } from 'ng2-file-upload';
import { IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';

import { UploaderComponent } from './uploader.component';

@NgModule({
  imports: [CommonModule, TranslateModule, FileUploadModule, MatProgressSpinnerModule, IconModule, ReactiveFormsModule],
  declarations: [UploaderComponent],
  exports: [UploaderComponent],
})
export class UploaderModule {}
