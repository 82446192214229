import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { trackById } from '@pu/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClickOutsideDirective } from '@pu/directives';

import { GlobalSearchActions, GlobalSearchSelectors } from './store';

@UntilDestroy()
@Component({
  selector: 'pu-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: ClickOutsideDirective,
      // bug with eslint
      // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property
      outputs: ['clickOutside'],
    },
  ],
})
export class GlobalSearchComponent {
  private _store = inject(Store);

  vm$ = this._store.select(GlobalSearchSelectors.selectViewModel);

  protected readonly trackById = trackById;

  @HostListener('clickOutside') clickedOutside() {
    this.close();
  }

  close() {
    this._store.dispatch(GlobalSearchActions.close());
  }
}
