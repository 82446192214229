import { AbstractControl, ValidationErrors } from '@angular/forms';

const uaPhonePrefixes = ['23', '39', '50', '63', '66', '67', '68', '73', '91', '92', '93', '94', '95', '96', '97', '98', '99'];

export const uaPhoneValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value) {
    const valid = control.value.length > 1 && uaPhonePrefixes.includes(control.value.slice(0, 2));

    return !valid ? { uaOperatorCode: true } : null;
  }

  return null;
};
