import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { DropdownPanel } from '../../models';

/**
 * Default implementation of dropdown
 * Closes on any click in it
 * You can use it or custom component which implements DropdownPanel
 */
@Component({
  selector: 'pu-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef: TemplateRef<unknown>;
  @Input() closeOnClick = true;
  @Output() closed = new EventEmitter<void>();

  close(): void {
    if (this.closeOnClick) {
      this.closed.emit();
    }
  }
}
