import { Component, HostBinding, Input } from '@angular/core';
import { NgIf } from '@angular/common';

import { Achievement, AchievementType } from '@pu/models';

@Component({
  standalone: true,
  selector: 'pu-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
  imports: [NgIf],
})
export class AchievementComponent {
  /**
   * Type of View
   */
  @HostBinding('class')
  private _parentClassList = '';

  private _type: AchievementType;
  @Input() achievement: Achievement;

  @Input() set type(type: AchievementType) {
    this._type = type;
    this._parentClassList = `pu-achievement_${this._type}`;
  }

  get type() {
    return this._type;
  }
}
