<img class="pu-not-found__art"
     srcset="assets/404/404-art@2x.png 2x"
     src="assets/404/404-art.png"
     alt="Not Found"/>

<div class="pu-not-found__body">
  <img class="pu-not-found__symbol"
       src="assets/404/404.png"
       alt="404"/>

  <p class="pu-not-found__description">
    {{ 'notFoundPage.description' | translate }}
  </p>

  <a class="pu-not-found__btn xl:ui-button_l"
     [routerLink]="'/'">
    {{ 'notFoundPage.backToHomePage' | translate }}
  </a>
</div>
