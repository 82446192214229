import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { OnChange } from '@pu/decorators';
import { trackByIndex } from '@pu/utils';

/**
 * Temporary hack due to Jest error with externally imported type or interface when using custom decorator on property
 * Check if it can be removed (fixed) after next dep upgrades
 */
type ValidationErrorsPrivate = ValidationErrors;

@Component({
  selector: 'pu-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessageComponent {
  /**
   * Control errors
   */
  @OnChange('_generateErrorsList')
  @Input()
  errors: ValidationErrorsPrivate | null;
  /**
   * Errors dictionary. Dictionary which contain error key as a key of record, and error text as a value
   * Adds ability to not extend template of ValidationMessageComponent each time when new errors for different components added
   */
  @Input() errorDictionary: Record<string, string>;

  /**
   * Errors params. They are needed for translations when params needed.
   */
  @Input() errorParams: Record<string, Record<string, string>>;

  errorsList: string[];
  trackByIndex = trackByIndex;

  /**
   * Convert ValidationErrors object to list, that will be used with errorDictionary
   * @private
   */
  private _generateErrorsList() {
    const errorsList: string[] = [];

    if (this.errors) {
      Object.entries(this.errors).forEach(([key, value]) => {
        if (value) {
          errorsList.push(key);
        }
      });
    }

    this.errorsList = errorsList;
  }
}
