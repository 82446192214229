import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withLeadingZero',
  standalone: true,
})
export class WithLeadingZeroPipe implements PipeTransform {
  transform(value: number, withLeadingZero: boolean): string {
    if (value >= 0) {
      return withLeadingZero && value < 10 ? '0' + value : `${value}`;
    }

    return '';
  }
}
