import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@pu/directives';

import { PasswordInputComponent } from './password-input.component';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { ValidationMessageModule } from '../../components/validation-message/validation-message.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    FloatingLabelOutlineComponentModule,
    ValidationMessageModule,
    TranslateModule,
    LetModule,
  ],
  declarations: [PasswordInputComponent],
  exports: [PasswordInputComponent],
})
export class PasswordInputComponentModule {}
