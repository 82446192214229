import { delay, Observable, of } from 'rxjs';

import { GetTodayBirthdaysRes } from '../models';

export function todayBirthdaysMock(): Observable<GetTodayBirthdaysRes> {
  return of({
    todayBirthdays: Array(9)
      .fill(null)
      .map((_, index) => ({
        id: index,
        fullName: `Person ${index} Name`,
        imageUrl: '/assets/images/user-avatar-mock.jpg',
        position: `Person ${index} Position`,
        email: `person${index}@email`,
      })),
  }).pipe(delay(1000));
}
