import { Injectable, Injector } from '@angular/core';

import { DialogService, MediaScreenService, UaParserService } from '@pu/services';

import { NotificationsOverlay } from './notifications-overlay';

@Injectable()
export class NotificationsDialogService extends DialogService {
  constructor(
    _overlay: NotificationsOverlay,
    _injector: Injector,
    _mediaScreenService: MediaScreenService,
    _uaParserService: UaParserService,
  ) {
    super(_overlay, _injector, _mediaScreenService, _uaParserService);
  }
}
