export interface SumWithOptionControl {
  /**
   * Input sum value
   */
  sum: number;
  /**
   * Is option checked value
   */
  isOptionEnabled: boolean;
}
