import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogRef } from '@pu/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule, IconModule, ToastType } from '@pu/ui';
import { trackByIndex } from '@pu/utils';
import { AuthSelectors, RootState, RouterActions, UiActions } from '@pu/store';
import { Store } from '@ngrx/store';
import { LetModule } from '@pu/directives';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, IconModule, LetModule],
  selector: 'pu-tfa-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodesComponent {
  trackByIndex = trackByIndex;
  codes$ = this._store.select(AuthSelectors.selectRecoveryCodes);

  constructor(private _store: Store<RootState>, private _translate: TranslateService, private _dialogRef: DialogRef) {}

  ok(): void {
    this._store.dispatch(RouterActions.navigateByUrl({ url: '/' }));
    this._dialogRef.close();
  }

  copy(codes: string[]): void {
    navigator.clipboard.writeText('PIN-UP.TEAM Recovery codes: \n\n' + codes.join('\n')).then(() => {
      this._store.dispatch(
        UiActions.showToast({
          toastType: ToastType.Success,
          message: this._translate.instant('tfaCodes.codesCopied'),
        }),
      );
    });
  }
}
