import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, IconModule } from '@pu/ui';

import { GalleryComponent } from './gallery.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, IconModule, ButtonModule],
  declarations: [GalleryComponent],
  exports: [GalleryComponent],
})
export class GalleryModule {}
