import { RouterReducerState } from '@ngrx/router-store';

import { AuthState } from '../auth';
import { UiState } from '../';

export interface RootState {
  ui: UiState;
  router: RouterReducerState;
  auth: AuthState;
}
