<label [class]="'pu-switcher'">
  <input
    type="checkbox"
    (click)="clicked.emit()">
  <div class="pu-switcher__slider">
    <ui-icon 
      size="20"
      [iconName]="isDarkTheme ? 'light' : 'dark'"
    ></ui-icon>
  </div>
</label>