import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

import { sanitizeNumeric } from '../../../utils';

@Directive({
  selector: '[puInputNumber]',
})
export class InputNumberDirective {
  constructor(private _el: ElementRef, private _control: NgControl) {}

  @HostListener('input', ['$event'])
  onChange(e: Event): void {
    const value = (<HTMLInputElement>e.target).value;
    const sanitizedValue = this.sanitizeValue(value);

    if (sanitizedValue !== value) {
      if (this._control) {
        this._control.control.setValue(sanitizedValue);
      } else {
        this._el.nativeElement.value = sanitizedValue;
      }
    }
  }

  protected sanitizeValue(value: string): string {
    return sanitizeNumeric(value);
  }
}
