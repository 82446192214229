import { Injectable } from '@angular/core';

import { createEffect } from '@ngrx/effects';
import { filter, fromEvent, map } from 'rxjs';

import { KeyboardActions } from './keyboard.actions';

@Injectable()
export class KeyboardEffects {
  escapePressed$ = createEffect(() =>
    fromEvent<KeyboardEvent>(document, 'keyup').pipe(
      filter((event: KeyboardEvent) => event.key === 'Escape'),
      map(() => KeyboardActions.escapePressed()),
    ),
  );
}
