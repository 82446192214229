import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncActionReducer } from '@pu/store';

import { CartCounterActions, cartCounterFeatureKey, CartCounterState } from './';

const initialQuantity = 0;
const quantityReducer = createReducer<number>(
  initialQuantity,
  on(CartCounterActions.init, (state): number => initialQuantity),
  on(CartCounterActions.getQuantityReq.succeededAction, (state, { payload }): number => payload.quantity),
);

export const cartCounterFeature = createFeature({
  name: cartCounterFeatureKey,
  reducer: combineReducers<CartCounterState>({
    quantity: quantityReducer,
    getQuantityAction: createAsyncActionReducer(CartCounterActions.getQuantityReq),
  }),
});
