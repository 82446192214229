<ng-container *uiSpinner="isSaving">
  <h4 class="pu-accrual-user-form__header">
    {{ type + '.accrualUserForm.header' | translate }}

    <button
      class="pu-accrual-user-form__close"
      type="button"
      uiButton
      uiType="quaternary"
      size="m"
      [onlyIcon]="true"
      (click)="close()">
      <ui-icon iconName="close-2"></ui-icon>
    </button>
  </h4>

  <div class="pu-accrual-user-form__body">
    <form class="pu-accrual-user-form__form"
          [formGroup]="form"
          (ngSubmit)="accrue()">

      <pu-search-select
        formControlName="user"
        itemsField="users"
        [api]="type === 'admin' ? 'user/admin/users-search/' : 'user/users-search/share/'"
        [label]="type + '.accrualUserForm.control.recipient' | translate">

        <ng-template #itemTpl let-item>
          <pu-user-profile-card [user]="item"></pu-user-profile-card>
        </ng-template>
      </pu-search-select>

      <pu-simple-input
        class="pu-accrual-user-form__amount"
        formControlName="amount"
        [controlType]="controlTypes.Number"
        [iconTemplate]="pincoinIcon"
        [label]="type + '.accrualUserForm.control.amount.label' | translate"
        [hint]="type === 'site' ? 'site.accrualUserForm.control.amount.hint' : ''"
        [isHintVisible]="true">
      </pu-simple-input>

      <ng-template #pincoinIcon>
        <ui-icon size="20" iconName="pin-coin"></ui-icon>
      </ng-template>

      <ui-emoji
        class="pu-accrual-user-form__emoji"
        [control]="form.controls.comment"
        inputId="accrual_user_comment_id">
      </ui-emoji>

      <pu-text-area
        controlId="accrual_user_comment_id"
        formControlName="comment"
        [maxLength]="1000"
        [rows]="4"
        [label]="(type + '.accrualUserForm.control.comment') | translate">
      </pu-text-area>

      <div class="pu-accrual-user-form__actions">
        <button
          type="submit"
          uiButton
          size="l"
          uiType="primary"
          [disabled]="!form.valid">
          {{ type + '.accrualUserForm.button.share' | translate }}
        </button>

        <button
          *ngIf="type === 'admin'"
          type="button"
          uiButton
          size="l"
          uiType="secondary"
          (click)="close()">
          {{ type + '.accrualUserForm.button.cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-container>
