import { PersonalInfo } from '@pu/models';

export const profileMock: PersonalInfo = {
  id: 3335,
  email: 'a.timofeenko@pin-up.business',
  fullName: 'Alexander Timofeenko',
  location: 'UA IT',
  locationId: 1,
  team: 'Frontend',
  position: 'Front End developer',
  segment: 'PIN-UP.TECH',
  hobby: 'Some hobby, bla bla bla',
  birthday: '1995-02-24',
  startDate: '2023-06-29',
  personalYearStart: '2023-06-29',
  picture: '',
  directManager: {
    id: 1738,
    fullName: 'Tiukh Oleksandr',
    email: 'o.tiukh@pin-up.team',
    picture: 'https://jira.time2go.tech/secure/useravatar?ownerId=JIRAUSER24589&avatarId=24123',
    position: 'Product Owner Web',
  },
  functionalManager: {
    id: 1738,
    fullName: 'Tiukh Oleksandr',
    email: 'o.tiukh@pin-up.team',
    picture: 'https://jira.time2go.tech/secure/useravatar?ownerId=JIRAUSER24589&avatarId=24123',
    position: 'Product Owner Web',
  },
  peoplePartner: {
    id: 1738,
    fullName: 'Tiukh Oleksandr',
    email: 'o.tiukh@pin-up.team',
    picture: '',
    position: 'Product Owner Web',
  },
  phone: '+380968425369',
  status: 'Probation',
  myGoals:
    '●Contribute to project perfection - maintainability, scalability, upgradability and reuse.\n●Become an active participator in the process of code review;\n●Understand the basics of project business domain;\n●Master the benefits of latest Angular version (usage of stand alone components, etc);\n●As part of a frontend team, launch MVP version of the PIN-UP.TEAM project;',
  vacationAvailablePaid: null,
  personalYearEnd: '2024-06-28',
  updated: '2023-06-29T14:59:05+03:00',
  balance: 0,
  is2faEnabled: true,
  balanceSkin: {
    id: 1,
    url: `/assets/images/balance/balance-card-mock.jpg`,
    name: 'balance-card-mock.jpg',
    isDark: false,
  },
};
