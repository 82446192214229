import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, Observable, switchMap, tap } from 'rxjs';
import { createAsyncActionEffect, KeyboardActions, RouterActions } from '@pu/store';
import { environment } from '@pu/environment';
import { CommonMediaQueries, DialogRef, MediaScreenService } from '@pu/services';

import { GlobalSearchActions } from './index';
import { GetGlobalSearchRes } from '../models';
import { getGlobalSearchMock$ } from '../mocks';
import { NotificationsActions } from '../../notifications/store';
import { GlobalSearchDialogService } from '../dialog/global-search-dialog.service';
import { GlobalSearchComponent } from '../global-search.component';

@Injectable()
export class GlobalSearchEffects {
  private _dialogRef: DialogRef;

  searchStringChanged$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GlobalSearchActions.searchStringChanged),
      switchMap(({ search }) =>
        search
          ? [GlobalSearchActions.open(), GlobalSearchActions.getReq.action({ payload: { search } })]
          : [GlobalSearchActions.clearResults()],
      ),
    ),
  );

  getGlobalSearchReq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GlobalSearchActions.getReq.action),
      switchMap(action => createAsyncActionEffect(this.getGlobalSearch(action.payload.search), GlobalSearchActions.getReq)),
    ),
  );

  openSearch$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GlobalSearchActions.open),
        tap(() => {
          if (!this._dialogRef) {
            this._dialogRef = this._dialog.open(GlobalSearchComponent);
          }
        }),
      ),
    { dispatch: false },
  );

  closeSearch$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(GlobalSearchActions.close),
        tap(() => {
          this._dialogRef?.close();
          this._dialogRef = null;
        }),
      ),
    { dispatch: false },
  );

  closeSearchOn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(RouterActions.routerNavigatedAction, NotificationsActions.open, KeyboardActions.escapePressed),
      map(() => GlobalSearchActions.close()),
    ),
  );

  closeOnTabletBreakpoint$ = createEffect(() =>
    this._mediaScreen.mediaMatcher(CommonMediaQueries.MD).pipe(map(() => GlobalSearchActions.close())),
  );

  constructor(
    private _actions$: Actions,
    private _http: HttpClient,
    private _dialog: GlobalSearchDialogService,
    private _mediaScreen: MediaScreenService,
  ) {}

  getGlobalSearch(search: string): Observable<GetGlobalSearchRes> {
    if (environment.useMocks) {
      return getGlobalSearchMock$(search);
    }

    return this._http.get<GetGlobalSearchRes>(environment.apiHost + 'global-search/', { params: { search } });
  }
}
