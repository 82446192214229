<form
  class="pu-tfa__body"
  [formGroup]="form"
  (ngSubmit)="reset()">

  <ui-icon
    iconName="p"
    size="96">
  </ui-icon>

  <h4
    class="pu-tfa__header"
    translate="tfaReset.header">
  </h4>

  <p
    class="pu-tfa__message"
    translate="tfaReset.message1">
  </p>

  <pu-simple-input
    class="pu-form-container"
    formControlName="code"
    [errorDictionary]="errorDictionary"
    [label]="'tfaReset.control.code' | translate">
  </pu-simple-input>

  <div class="pu-tfa__actions">
    <button
      type="submit"
      uiButton
      size="l"
      uiType="primary"
      [disabled]="!form.valid || isLoading"
      translate="tfa.btn.verify">
    </button>
  </div>

  <p
    class="pu-tfa__message"
    translate="tfaReset.help">
  </p>
</form>
