import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SumInputComponent } from './sum-input.component';
import { InputNumberDirectiveModule } from '../../directives';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { ValidationMessageModule } from '../../components/validation-message/validation-message.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InputNumberDirectiveModule, FloatingLabelOutlineComponentModule, ValidationMessageModule],
  declarations: [SumInputComponent],
  exports: [SumInputComponent],
})
export class SumInputComponentModule {}
