import { Directive, HostBinding, Input } from '@angular/core';

import { ButtonSize } from './models/button-size';
import { ButtonUiType } from './models/button-ui-types';

@Directive({
  selector: '[uiButton]',
})
export class ButtonDirective {
  /**
   * Button size
   */
  @Input() set size(size: ButtonSize) {
    this._size = size;
    this._parentClassList = `ui-button ui-button_${this._size} ui-button_${this._type}`;
  }

  /**
   * Button type
   */
  @Input() set uiType(type: ButtonUiType) {
    this._type = type;
    this._parentClassList = `ui-button ui-button_${this._size} ui-button_${this._type}`;
  }

  /**
   * Is disabled
   * Use only for links, for button disabled attr should be used
   */
  @HostBinding('class.ui-button_disabled')
  @Input()
  linkDisabled = false;

  /**
   * If button has only icon
   */
  @HostBinding('class.ui-button_only-icon')
  @Input()
  onlyIcon = false;

  /**
   * If button has pressed state
   */
  @HostBinding('class.ui-button_pressed')
  @Input()
  isPressed = false;

  private _size: ButtonSize = 's';
  private _type: ButtonUiType = 'primary';

  @HostBinding('class')
  private _parentClassList = `ui-button ui-button_${this._size} ui-button_${this._type}`;
}
