import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClickOutsideDirective, LetModule } from '@pu/directives';
import { TimeagoModule } from 'ngx-timeago';
import { TranslateModule } from '@ngx-translate/core';
import { LinkDataPipe } from '@pu/pipes';

import { NotificationsComponent } from './notifications.component';
import { NotificationsEffects, notificationsFeature } from './store';
import { NotificationsDialogService } from './dialog/notifications-dialog.service';
import { NotificationsOverlayContainer } from './dialog/notifications-overlay-container';
import { NotificationsOverlay } from './dialog/notifications-overlay';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(notificationsFeature),
    EffectsModule.forFeature([NotificationsEffects]),
    LetModule,
    TimeagoModule,
    TranslateModule,
    ClickOutsideDirective,
    RouterLink,
    LinkDataPipe,
  ],
  exports: [NotificationsComponent],
  providers: [NotificationsDialogService, NotificationsOverlay, NotificationsOverlayContainer],
})
export class NotificationsModule {}
