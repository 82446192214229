<div class="pu-support-tips__header">
  <h4 class="pu-support-tips__header-text"
      translate="support.tipsDialog.header">
  </h4>

  <button
    class="pu-support-tips__close"
    type="button"
    uiButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()">
    <ui-icon iconName="close" size="16"></ui-icon>
  </button>
</div>

<div class="pu-support-tips__body">
  <section
    class="pu-support-tips__tip"
    *ngFor="let tip of tips; trackBy: trackById"
    (click)="open(tip.id)">

    <div class="pu-support-tips__tip-question">
      {{ tip.question }}
      <ui-icon
        class="pu-support-tips__tip-arrow"
        [class.opened]="openedId === tip.id"
        iconName="chevron-1">
      </ui-icon>
    </div>

    <div
      class="pu-support-tips__tip-answer"
      *ngIf="openedId === tip.id">
      {{ tip.answer }}
    </div>
  </section>
</div>
