/**
 * App validation error interface
 */
export interface AppValidationError {
  /**
   * Error code
   */
  code: number | string;

  /**
   * Api error message
   */
  message: string;

  /**
   * Original http error
   */
  field: string;
}
