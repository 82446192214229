import { Entity, ImageModel } from '@pu/models';

export interface SupportTip extends Entity {
  question: string;
  answer: string;
}

export interface SupportContact {
  question: string;
  images: ImageModel[];
}
