<div class="pu-article-card-small__image">
  <img [src]="article.imageUrl" [alt]="article.title"/>
</div>

<div class="pu-article-card-small__container">
  <h3 class="pu-article-card-small__title">
    {{ article.title }}
  </h3>
  <p class="pu-article-card-small__published-at">
    {{ article.publishedAt | date: 'dd MMM YYYY' }}
    <span>
      {{ article.publishedAt | date: 'HH:mm' }}
    </span>
  </p>
  <p class="pu-article-card-small__tag">
    {{ article.tag.name }}
  </p>
</div>
