import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';

import { PhoneSelectInputComponent } from './phone-select-input.component';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { SelectDropdownListComponentModule } from '../../components/select-dropdown-list/select-dropdown-list.module';
import { ValidationMessageModule } from '../../components/validation-message/validation-message.module';
import { InputNumberDirectiveModule } from '../../directives';
import { DisablePasteDirectiveModule } from '../../directives';
import { DropdownModule } from '../../components/dropdown';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    FloatingLabelOutlineComponentModule,
    IconModule,
    SelectDropdownListComponentModule,
    ValidationMessageModule,
    TranslateModule,
    InputNumberDirectiveModule,
    DisablePasteDirectiveModule,
    ButtonModule,
  ],
  declarations: [PhoneSelectInputComponent],
  exports: [PhoneSelectInputComponent],
})
export class PhoneSelectInputComponentModule {}
