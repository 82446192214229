<ui-icon
  [puDropdownTriggerFor]="tooltipTemplate"
  [reference]="reference"
  [offsetY]="tooltipOffset"
  [class.pu-control-tooltip__icon_active]="dropdownRef.isDropdownOpened$ | async"
  #dropdownRef="puDropdownTrigger"
  class="pu-control-tooltip__icon"
  size="20"
  iconName="info-simple"
></ui-icon>

<pu-dropdown #tooltipTemplate>
  <div class="pu-control-tooltip__content">
    {{ tooltip }}
  </div>
</pu-dropdown>
