<ng-container *puLet="isDarkTheme$ | async as isDarkTheme">
  <ng-container *puLet="notificationsCount$ | async as notifications">
    <button
      class="pu-header-controls__link"
      uiButton
      size="m"
      [onlyIcon]="true"
      uiType="quaternary"
      (click)="setTheme(isDarkTheme)">
      <ui-icon
        [iconName]="isDarkTheme ? 'light' : 'dark'">
      </ui-icon>
    </button>

    <pu-support></pu-support>

    <button
      class="pu-header-controls__link"
      uiButton
      size="m"
      [onlyIcon]="true"
      uiType="quaternary"
      (click)="toggleNotifications($event)">
      <ui-icon iconName="notifications"></ui-icon>
      <span
        class="pu-header-controls__badge"
        *ngIf="notifications">
        {{ notifications }}
      </span>
    </button>

    <a
      class="pu-header-controls__link"
      [routerLink]="'/cart'"
      uiButton
      size="m"
      uiType="quaternary"
      [onlyIcon]="true">
      <ui-icon iconName="cart"></ui-icon>
      <pu-cart-counter></pu-cart-counter>
    </a>
  </ng-container>
</ng-container>
