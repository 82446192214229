import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { trackById } from '@pu/utils';
import { SelectDropdownListItem } from '@pu/forms';
import { StrNum } from '@pu/models';

@Component({
  selector: 'pu-select-dropdown-list',
  templateUrl: './select-dropdown-list.component.html',
  styleUrls: ['./select-dropdown-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDropdownListComponent implements OnInit {
  /**
   * Value field
   */
  @Input() valueField = 'id';

  /**
   * Name field
   */
  @Input() nameField = 'name';

  /**
   * It is a function, that returns true if item must be disabled
   */
  @Input() isDisabledItem: (item: SelectDropdownListItem<StrNum>) => boolean;

  @Input() set itemList(items: SelectDropdownListItem<StrNum>[]) {
    this.items = items;
    this.disabledIndex = this.initiated ? this._getDisabledIndex(items) : {};
  }

  @Input() selectedValue: string | number;

  @Output() itemSelected = new EventEmitter<SelectDropdownListItem<StrNum>>();

  trackById = trackById;

  items: SelectDropdownListItem<StrNum>[];
  disabledIndex: Record<StrNum, boolean> = {};
  initiated = false;

  ngOnInit() {
    this.disabledIndex = this._getDisabledIndex(this.items);
    this.initiated = true;
  }

  select(event: Event, item: SelectDropdownListItem<StrNum>): void {
    if (this.disabledIndex[item[this.valueField]]) {
      event.stopPropagation();
    } else {
      this.itemSelected.emit(item);
    }
  }

  private _getDisabledIndex(items: SelectDropdownListItem<StrNum>[]): Record<StrNum, boolean> {
    return items.reduce((acc, item) => {
      acc[item[this.valueField]] = this.isDisabledItem ? this.isDisabledItem(item) : false;

      return acc;
    }, <Record<StrNum, boolean>>{});
  }
}
