import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleInputComponentModule } from '@pu/forms';

import { TagsComponent } from './tags.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, IconModule, ButtonModule, SimpleInputComponentModule],
  declarations: [TagsComponent],
  exports: [TagsComponent],
})
export class TagsModule {}
