<ng-container *puLet="vm$ | async as vm">
  <div class="pu-global-search">
    <div class="pu-global-search__header">
      <div class="pu-global-search__title">
        {{ 'globalSearch.people' | translate }}
      </div>

      <div
        class="pu-global-search__found"
        [innerHTML]="vm.people.length ? ('globalSearch.foundUsers' | translate: { amount: vm.people.length }) : 'globalSearch.foundUsers_zero' | translate">
      </div>
    </div>

    <div class="pu-global-search__items-container pu-global-search__items-container_people">
      <a
        class="pu-global-search__link"
        *ngFor="let user of vm.people; trackBy: trackById"
        [routerLink]="['/colleague', user.id]"
        (click)="close()">
        <pu-user-profile-card [user]="user">
        </pu-user-profile-card>
      </a>
    </div>

    <div class="pu-global-search__header">
      <div class="pu-global-search__title">
        {{ 'globalSearch.news' | translate }}
      </div>

      <div
        class="pu-global-search__found"
        [innerHTML]="vm.news.length ? ('globalSearch.foundArticles' | translate: { amount: vm.news.length }) : 'globalSearch.foundArticles_zero' | translate">
      </div>
    </div>

    <div class="pu-global-search__items-container pu-global-search__items-container_news">
      <a
        class="pu-global-search__link"
        *ngFor="let article of vm.news; trackBy: trackById"
        [routerLink]="['/article', article.id]"
        (click)="close()">
        <pu-article-card-small [article]="article">
        </pu-article-card-small>
      </a>
    </div>
  </div>
</ng-container>
