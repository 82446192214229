import { AppValidationError } from '@pu/models';
import { FormGroup } from '@ngneat/reactive-forms';

export function setApiValidationErrors<T extends FormGroup<any>>(form: T, errors: AppValidationError[]) {
  if (errors.length === 0) {
    return;
  }

  for (const { field, message } of errors) {
    const control = form.get(field);

    if (control) {
      control.setErrors({ apiValidation: message });
    }
  }
}
