import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule, SpinnerModule } from '@pu/ui';
import { LetModule } from '@pu/directives';
import { EffectsModule } from '@ngrx/effects';

import { BalanceComponent } from './balance.component';
import { BalanceEffects, balanceFeature } from './store';

@NgModule({
  declarations: [BalanceComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    TranslateModule,
    ButtonModule,
    LetModule,
    SpinnerModule,
    StoreModule.forFeature(balanceFeature),
    EffectsModule.forFeature([BalanceEffects]),
  ],
  exports: [BalanceComponent],
})
export class BalanceModule {}
