import { Entity } from './entity.model';

export interface Category extends Entity {
  name: string;
}

export interface CategoryAdmin extends Category {
  catalogId: number; // Catalog
  visible: boolean;
}
