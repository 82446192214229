import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pu-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
})
export class switcherComponent {
  @Input() isDarkTheme: boolean;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
