import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'pu-status',
  template: `{{ i18nPrefix + '.' + statusType | translate }}`,
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  @HostBinding('class')
  private _hostClasses = '';

  @Input() set type(statusType: string) {
    this.statusType = statusType;
    this._hostClasses = `pu-status pu-status_${this.statusType}`;
  }

  @Input() i18nPrefix: string;

  statusType: string;
}
