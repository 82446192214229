import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';

import { DIALOG_DATA, DialogRef } from '@pu/services';

import { OkOptions } from './models/ok-options.model';

@Component({
  selector: 'pu-ok',
  templateUrl: './ok.component.html',
  styleUrls: ['./ok.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OkComponent {
  @Input() headerKey: string;
  @Input() messageKey?: string;
  @Input() messageParams: Record<string, string | number> = {};
  @Input() subMessageKey?: string;
  @Input() subMessageParams: Record<string, string | number> = {};
  @Input() btnOkKey = 'dialogOk.btn.ok';
  @Input() btnCancelKey = 'dialogOk.btn.cancel';
  @Input() iconName?: string;
  @Input() imageUrl?: string;
  @Input() notCanceled?: boolean;

  constructor(private _dialogRef: DialogRef, @Optional() @Inject(DIALOG_DATA) public data: OkOptions) {
    if (data) {
      this.headerKey = data.headerKey;
      this.messageKey = data.messageKey;
      this.messageParams = data.messageParams || this.messageParams;
      this.subMessageKey = data.subMessageKey;
      this.subMessageParams = data.subMessageParams || this.subMessageParams;
      this.btnOkKey = data.btnOkKey || this.btnOkKey;
      this.btnCancelKey = data.btnCancelKey || this.btnCancelKey;
      this.iconName = data.iconName;
      this.imageUrl = data.imageUrl;
      this.notCanceled = data.notCanceled;
    }
  }

  ok(): void {
    this._dialogRef.close(true);
  }

  cancel(): void {
    this._dialogRef.close(false);
  }
}
