import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ComponentType, Overlay, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { concatMap, Subject } from 'rxjs';

import { DialogRef } from './dialog-ref';
import { CommonMediaQueries, MediaScreenService } from '../media-screen/media-screen.service';
import { UaParserService } from '../ua-parser/ua-parser.service';

export const DIALOG_DATA = new InjectionToken<string>('DIALOG_DATA');

/**
 * Config for custom ref settings
 * Extend when necessary
 */
interface CustomOverlayProps {
  hasBackdrop?: boolean;
  right?: string;
  bottom?: string;
  centerHorizontally?: boolean;
  overlayBackdropClass?: string;
  centerVertically?: string;
  panelClass?: string;
  isOutOfQueue?: boolean;
  scrollStrategy?: keyof ScrollStrategyOptions;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogsQueueSubject = new Subject<{
    portal: ComponentPortal<unknown>;
    overlayRef: OverlayRef;
    dialogRef: DialogRef;
  }>();

  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
    private _mediaScreenService: MediaScreenService,
    private _uaParserService: UaParserService,
  ) {
    this._dialogsQueueSubject
      .pipe(
        concatMap(({ portal, overlayRef, dialogRef }) => {
          overlayRef.attach(portal);

          return dialogRef.afterClosed();
        }),
      )
      .subscribe();
  }

  /**
   * Open a custom component in an overlay
   */
  open<T, V>(component: ComponentType<T>, config?: V, props?: CustomOverlayProps): DialogRef {
    const overlayRef = this._overlay.create({
      positionStrategy: this._positionStrategy,
      hasBackdrop: true,
      backdropClass: props?.overlayBackdropClass || 'pu-overlay-backdrop',
      panelClass: props?.panelClass || 'pu-overlay-panel',
      scrollStrategy: this._scrollStrategy(props?.scrollStrategy),
    });

    return this._attachDialogRef(component, overlayRef, config, props?.isOutOfQueue);
  }

  /**
   * Open with custom ref
   * @param component
   * @param overlayRef
   * @param config
   */
  openWithCustomRef<T, V>(component: ComponentType<T>, overlayRef: OverlayRef, config?: V): DialogRef {
    return this._attachDialogRef(component, overlayRef, config);
  }

  /**
   * Create custom ref
   * @param props
   */
  createCustomRef(props: CustomOverlayProps): OverlayRef {
    const positionStrategy = this._overlay.position().global();

    if (props.centerHorizontally) {
      positionStrategy.centerHorizontally();
    }

    if (props.centerVertically) {
      positionStrategy.centerVertically();
    }

    if (props.bottom) {
      positionStrategy.bottom(props.bottom);
    }

    if (props.right) {
      positionStrategy.right(props.right);
    }

    return this._overlay.create({
      disposeOnNavigation: true,
      maxWidth: '100%',
      scrollStrategy: this._scrollStrategy(props?.scrollStrategy),
      positionStrategy,
      hasBackdrop: props.hasBackdrop ?? true,
      panelClass: props.panelClass || 'pu-overlay-panel',
      backdropClass: 'pu-overlay-backdrop',
    });
  }

  private _attachDialogRef<T, V>(component: ComponentType<T>, overlayRef: OverlayRef, config?: V, isOutOfQueue?: boolean): DialogRef {
    // Create dialogRef to return
    const dialogRef = new DialogRef(overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this._injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);

    if (isOutOfQueue) {
      overlayRef.attach(portal);
    } else {
      this._dialogsQueueSubject.next({ portal, overlayRef, dialogRef });
    }

    return dialogRef;
  }

  /**
   * Get _positionStrategy
   */
  private get _positionStrategy() {
    const positionStrategy = this._overlay.position().global();
    if (this._isTablet) {
      positionStrategy.centerHorizontally().centerVertically();
    } else {
      positionStrategy.centerHorizontally().bottom('0');
    }

    return positionStrategy;
  }

  /**
   * Get _scrollStrategy. If no strategy is provided, return block. Firefox has bug with sticky elements when scroll
   * is blocked and page was scrolled before, so strategy is noop for Firefox.
   */
  private _scrollStrategy(strategy: keyof ScrollStrategyOptions) {
    if (!strategy && !this._uaParserService.isMozillaBrowser) {
      return this._overlay.scrollStrategies.block();
    }

    if (!strategy && this._uaParserService.isMozillaBrowser) {
      return this._overlay.scrollStrategies.noop();
    }

    return this._overlay.scrollStrategies[strategy]();
  }

  /**
   * Get _isDesktop mediaScreen: boolean
   */
  private get _isTablet(): boolean {
    return this._mediaScreenService.isMatched(CommonMediaQueries.MD);
  }
}
