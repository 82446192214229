import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { ButtonModule, IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleInputComponentModule } from '@pu/forms';

import { DropdownModule } from '../../components/dropdown';
import { DatePickerComponent } from './date-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatNativeDateModule,
    MatDatepickerModule,
    IconModule,
    ButtonModule,
    DropdownModule,
    SimpleInputComponentModule,
  ],
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent],
  providers: [DatePipe],
})
export class DatePickerModule {}
