import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { RootState } from '@pu/store';
import { ButtonModule, IconModule, SpinnerModule, UploaderModule } from '@pu/ui';
import { DialogRef } from '@pu/services';
import { ImageModel, SupportContact } from '@pu/models';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TextAreaComponentModule } from '@pu/forms';
import { provideNgxMask } from 'ngx-mask';

import { SupportActions } from '../../store';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    SpinnerModule,
    ButtonModule,
    IconModule,
    TextAreaComponentModule,
    SpinnerModule,
    UploaderModule,
  ],
  providers: [provideNgxMask()],
  selector: 'pu-support-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent implements OnInit {
  form: FormGroup<GroupResolverFormBuilder<SupportContact>>;
  imagesControl: FormControl<ImageModel[]>;
  isLoading = false;

  constructor(
    private _store: Store<RootState>,
    private _actionsSubject: ActionsSubject,
    private _fb: FormBuilder,
    private _dialogRef: DialogRef,
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      question: ['', Validators.required],
      images: [<any>[]],
    });

    this.imagesControl = <any>this.form.controls.images;

    this._actionsSubject.pipe(ofType(SupportActions.sendMessageReq.succeededAction), untilDestroyed(this)).subscribe({
      next: () => {
        this.isLoading = false;
        this._dialogRef.close();
      },
    });
  }

  send(): void {
    this.isLoading = true;
    this._store.dispatch(SupportActions.sendMessageReq.action({ payload: { ...(<any>this.form.value) } }));
  }

  cancel(): void {
    this._dialogRef.close();
  }
}
