import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';

import { ActionsSubject, Store } from '@ngrx/store';
import { FormControl } from '@ngneat/reactive-forms';
import { debounce, of, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';

import { GlobalSearchActions, GlobalSearchSelectors } from '../store';

@UntilDestroy()
@Component({
  selector: 'pu-global-search-input',
  templateUrl: './global-search-input.component.html',
  styleUrls: ['./global-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchInputComponent implements OnInit {
  private _store = inject(Store);
  private _actionsSubject = inject(ActionsSubject);

  opened$ = this._store.select(GlobalSearchSelectors.selectOpened);

  searchControl = new FormControl('');

  ngOnInit() {
    this.searchControl.value$
      .pipe(
        debounce(value => (value ? timer(500) : of(value))),
        untilDestroyed(this),
      )
      .subscribe({
        next: search => this._store.dispatch(GlobalSearchActions.searchStringChanged({ search })),
      });

    this._actionsSubject.pipe(ofType(GlobalSearchActions.close), untilDestroyed(this)).subscribe({
      next: () => {
        this.searchControl.setValue('');
      },
    });
  }

  onClick(event: Event) {
    event.stopPropagation();
    this._store.dispatch(GlobalSearchActions.open());
  }
}
