<ng-container *puLet="isTablet$ | async as isTablet">
  <pu-global-search-input *ngIf="!isTablet">
  </pu-global-search-input>

  <pu-user-profile></pu-user-profile>

  <div class="pu-menu__nav-box">
    <nav class="pu-menu__nav">
      <ui-nav-item
        *ngFor="let item of navItems; trackBy: trackByIndex"
        [item]="item"
        (click)="onClose()"
      ></ui-nav-item>
    </nav>

    <pu-support
      type="list"
      (clickItem)="onClose()">
    </pu-support>
  </div>

  <ng-container *puLet="isDarkTheme$ | async as isDarkTheme">
    <pu-switcher
      class="pu-menu__theme-switcher"
      *ngIf="!isTablet"
      [isDarkTheme]="isDarkTheme"
      type="theme"
      (clicked)="setTheme(isDarkTheme)"
    ></pu-switcher>
  </ng-container>

  <a
    *ngIf="!isTablet"
    routerLink="/birthdays"
    puBirthdaysWidget
    [isVertical]="true">&nbsp;</a>

  <div class="pu-menu__close">
    <button
      class="pu-menu__close-btn"
      type="button"
      (click)="onClose()">
      <ui-icon
        class="pu-menu__close-icon"
        iconName="close"
        size="16"></ui-icon>
    </button>

    <span
      class="pu-menu__close-text"
      *ngIf="isTablet">
      {{ 'menu.button.close' | translate }}
    </span>
  </div>
</ng-container>
