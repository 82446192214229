import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncActionReducer } from '@pu/store';

import { BalanceActions, balanceFeatureKey, BalanceState } from '.';
import { GetTagsRes } from '../models';

const initialBalance = 0;
const balanceReducer = createReducer<number>(
  initialBalance,
  on(BalanceActions.init, (state): number => initialBalance),
  on(BalanceActions.getBalanceReq.succeededAction, (state, { payload }): number => payload.balance),
);

const tagsReducer = createReducer<GetTagsRes>(
  { itemsCount: 0, tags: [] },
  on(BalanceActions.getTagsReq.succeededAction, (state, action): GetTagsRes => action.payload),
);

export const balanceFeature = createFeature({
  name: balanceFeatureKey,
  reducer: combineReducers<BalanceState>({
    balance: balanceReducer,
    tags: tagsReducer,
    getBalanceAction: createAsyncActionReducer(BalanceActions.getBalanceReq),
    getBalanceSkinsAction: createAsyncActionReducer(BalanceActions.getBalanceSkinsReq),
    applyBalanceSkinAction: createAsyncActionReducer(BalanceActions.applyBalanceSkinReq),
    getTagsAction: createAsyncActionReducer(BalanceActions.getTagsReq),
  }),
});
