import { AsyncActionState } from '@pu/store';

import { ApplyBalanceSkinRes, GetBalanceRes, GetBalanceSkinsRes, GetTagsRes } from '../models';

export const balanceFeatureKey = 'Balance';

export interface BalanceState {
  balance: number;
  tags: GetTagsRes;
  getBalanceAction: AsyncActionState<GetBalanceRes>;
  getBalanceSkinsAction: AsyncActionState<GetBalanceSkinsRes>;
  applyBalanceSkinAction: AsyncActionState<ApplyBalanceSkinRes>;
  getTagsAction: AsyncActionState<GetTagsRes>;
}
