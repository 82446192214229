export function isDate(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object Date]';
}

export function isFile(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object File]';
}

export function isFormData(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object FormData]';
}

export function isBlob(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object Blob]';
}

export function isRegExp(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object RegExp]';
}

export function isPromise(value: any): boolean {
  return value && typeof value.then === 'function';
}

export function isObservable(value: any): boolean {
  return value && typeof value.pipe === 'function' && typeof value.subscribe === 'function';
}

export function isEmpty(value: any): boolean {
  if (typeof value === 'number' || typeof value === 'boolean') {
    return false;
  } else if (!value) {
    return true;
  } else if (isDate(value)) {
    return false;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  }

  for (const key in value) {
    if (value.hasOwnProperty(key)) return false;
  }

  return true;
}
