import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { LetModule } from '@pu/directives';
import { IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { SimpleInputComponent } from './simple-input.component';
import { ControlTooltipComponentModule } from '../../components/control-tooltip/control-tooltip.module';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { ValidationMessageModule } from '../../components/validation-message/validation-message.module';
import { DropdownModule } from '../../components/dropdown';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    DropdownModule,
    ControlTooltipComponentModule,
    FloatingLabelOutlineComponentModule,
    LetModule,
    ValidationMessageModule,
    TranslateModule,
    NgxMaskDirective,
  ],
  declarations: [SimpleInputComponent],
  exports: [SimpleInputComponent],
})
export class SimpleInputComponentModule {}
