import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@pu/ui';

import { CategoryTabComponent } from './category-tab.component';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [CategoryTabComponent],
  exports: [CategoryTabComponent],
})
export class CategoryTabComponentModule {}
