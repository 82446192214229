import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

import { Actions, concatLatestFrom, createEffect, ofType, CreateEffectMetadata } from '@ngrx/effects';
import { debounceTime, filter, map, merge, Observable, Subject, tap } from 'rxjs';
import { ActionCreator, createAction, Selector, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { isEmpty, equals, mapObjToArr } from '@pu/utils';
import { untilDestroyed } from '@ngneat/until-destroy';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { FormGroup } from '@ngneat/reactive-forms';

import { RootState, RouterSelectors } from '../';

export interface RouteFiltersActions<T extends Params = Params> {
  init: ActionCreator<string, (props: { routeData: Data }) => { routeData: Data } & TypedAction<string>>;
  initFilters: ActionCreator<string, () => TypedAction<string>>;
  routeChanged: ActionCreator<string, () => TypedAction<string>>;
  setFilters: ActionCreator<
    string,
    (props: { filters: Partial<T>; external?: boolean }) => { filters: Partial<T>; external?: boolean } & TypedAction<string>
  >;
  changeFilters: ActionCreator<
    string,
    (props: { filters: Partial<T>; external?: boolean }) => { filters: Partial<T>; external?: boolean } & TypedAction<string>
  >;
  filterChangeActions?: ActionCreator[];
}

export interface RouteFiltersSelectors<T extends Params = Params> {
  selectFilters: Selector<any, T>;
}

export interface RouteFiltersController {
  _router: Router;
  _actions$: Actions;
  _store: Store<RootState>;
}

export interface RouteFiltersSettings<T extends Params = Params> {
  actions: RouteFiltersActions<T>;
  selectors: RouteFiltersSelectors<T>;
  initialFilters: T;
  segments?: (string | number)[];
  params?: string[]; // 'paramName:type:ignore' - 'search:string:true', ignore - not for route
  queryParams?: string[]; // 'paramName:type:ignore' - 'search:string:true', ignore - not for route
  ignoreRoute?: boolean; // if true, all params don't change a route
}

type IndexedParamTypes = Record<string, { key: string; type: string; ignore: string }>;

interface RouteState {
  params?: Params;
  queryParams?: Params;
  indexedParamTypes?: IndexedParamTypes;
}

const routeStates: Map<RouteFiltersSettings<any>, RouteState> = new Map();
const fakeAction = createAction(`[Route filters]: Fake action ` + Math.random());

export const noopAction = createAction(`[Noop]: Noop action`);

export function subscribeOnRouteParams<T extends Params = Params>(
  ctrl: { _route: ActivatedRoute; _store: Store<RootState> },
  settings: RouteFiltersSettings<T>,
): void {
  ctrl._route.params.pipe(untilDestroyed(ctrl)).subscribe({
    next: params => {
      const routeState = getRouteState(settings);
      const normalizedParams = getNormalizedParams(settings, params, null, false);
      if (routeState.params && !equals(normalizedParams, routeState.params)) {
        ctrl._store.dispatch(settings.actions.routeChanged());
      }
      routeState.params = normalizedParams;
    },
  });

  ctrl._route.queryParams.pipe(untilDestroyed(ctrl)).subscribe({
    next: queryParams => {
      const routeState = getRouteState(settings);
      const normalizedParams = getNormalizedParams(settings, null, queryParams, false);
      if (routeState.queryParams && !equals(normalizedParams, routeState.queryParams)) {
        ctrl._store.dispatch(settings.actions.routeChanged());
      }
      routeState.queryParams = normalizedParams;
    },
  });

  new Subject().pipe(untilDestroyed(ctrl)).subscribe({
    complete: () => {
      routeStates.set(settings, {});
    },
  });
}

export function subscribeOnControls<T extends Params = Params>(
  ctrl: { filtersForm: FormGroup<GroupResolverFormBuilder<T>>; _store: Store<RootState> },
  settings: RouteFiltersSettings<T>,
): void {
  merge(
    ...mapObjToArr(ctrl.filtersForm.controls, (control, key) => {
      return control.valueChanges.pipe(
        ['search'].includes(key) ? debounceTime(700) : tap(),
        concatLatestFrom(() => [ctrl._store.select(settings.selectors.selectFilters)]),
        filter(([value, filters]) => value !== filters[key]),
        map(([value]) => (key === 'page' ? { [key]: value } : { [key]: value, page: 1 })),
      );
    }),
  )
    .pipe(untilDestroyed(ctrl))
    .subscribe({ next: (filters: any) => ctrl._store.dispatch(settings.actions.changeFilters({ filters })) });
}

export function changePage<T extends Params = Params>(
  ctrl: { filtersForm: FormGroup<GroupResolverFormBuilder<T>> },
  event: PageEvent,
): void {
  const controls: GroupResolverFormBuilder<{ limit: number; page: number }> = <any>ctrl.filtersForm.controls;
  if (event.pageSize !== controls.limit.value) {
    controls.limit.setValue(event.pageSize);
  } else if (event.pageIndex + 1 !== controls.page.value) {
    controls.page.setValue(event.pageIndex + 1);
  }
}

export function createRouteFiltersEffect<T extends Params>(
  ctrl: RouteFiltersController,
  settings: RouteFiltersSettings<T>,
): Observable<{ filters: Partial<T> } & TypedAction<string>> & CreateEffectMetadata {
  const paramTypes = (settings.params || []).concat(settings.queryParams || []);
  const indexedParamTypes = getIndexedParamTypes(settings);

  return createEffect(() =>
    ctrl._actions$.pipe(
      ofType(settings.actions.init, settings.actions.initFilters, settings.actions.routeChanged),
      concatLatestFrom(() => [
        ctrl._store.select(RouterSelectors.selectRouteParams),
        ctrl._store.select(RouterSelectors.selectQueryParams),
        ctrl._store.select(settings.selectors.selectFilters),
      ]),
      map(([action, params, queryParams, filters]) => {
        const isInit = action.type === settings.actions.init.type;
        const isInitFilters = action.type === settings.actions.initFilters.type;
        const normalizedParams: Params = {};
        const routeParams = settings.ignoreRoute ? {} : Object.assign({}, params, queryParams);

        paramTypes.forEach(param => {
          const { key, type } = indexedParamTypes[param];
          if (routeParams[key]) {
            normalizedParams[key] = type === 'string' ? routeParams[key] : +routeParams[key];
          } else {
            normalizedParams[key] = settings.initialFilters[key];
          }
        });

        return { isInit, isInitFilters, normalizedParams, filters };
      }),
      filter(
        ({ isInit, isInitFilters, normalizedParams, filters }) =>
          isInit ||
          isInitFilters ||
          paramTypes.some(param => {
            const { key } = indexedParamTypes[param];

            return normalizedParams[key] !== filters[key];
          }),
      ),
      map(({ isInit, normalizedParams }) =>
        isInit
          ? settings.actions.setFilters({ filters: <Partial<T>>normalizedParams })
          : settings.actions.changeFilters({ filters: <Partial<T>>normalizedParams, external: true }),
      ),
    ),
  );
}

export function createChangeRouteEffect<T extends Params>(
  ctrl: RouteFiltersController,
  settings: RouteFiltersSettings<T>,
): Observable<{ filters: T }> & CreateEffectMetadata {
  const paramTypes = (settings.params || []).concat(settings.queryParams || []);
  const indexedParamTypes = getIndexedParamTypes(settings);

  return createEffect(
    () =>
      ctrl._actions$.pipe(
        ofType(...(settings.actions.filterChangeActions || [fakeAction])),
        filter(action => !settings.ignoreRoute && ((<any>action).type !== settings.actions.changeFilters.type || !(<any>action).external)),
        concatLatestFrom(() => [
          ctrl._store.select(RouterSelectors.selectRouteParams),
          ctrl._store.select(RouterSelectors.selectQueryParams),
          ctrl._store.select(settings.selectors.selectFilters),
        ]),
        filter(([, params, queryParams, filters]) => {
          const normalizedParams = getNormalizedParams(settings, params, queryParams, true);

          return paramTypes.some(param => {
            const { key } = indexedParamTypes[param];

            return normalizedParams[key] !== filters[key];
          });
        }),
        map(([, , , filters]) => ({ filters })),
        tap(({ filters }) => {
          const segmentParams: any[] = [];
          const params = (settings.params || []).reduce((acc: Params, param) => {
            const { key, ignore } = indexedParamTypes[param];

            if (!ignore && !isEmpty(filters[key])) {
              acc[key] = filters[key];
              segmentParams.push(filters[key]);
            }

            return acc;
          }, {});

          const queryParams = (settings.queryParams || []).reduce((acc: Params, param) => {
            const { key, ignore } = indexedParamTypes[param];

            if (!ignore && !isEmpty(filters[key])) {
              acc[key] = filters[key];
            }

            return acc;
          }, {});

          const routeState = getRouteState(settings);
          routeState.params = params;
          routeState.queryParams = queryParams;

          ctrl._router.navigate([...(settings.segments || []), ...segmentParams], { queryParams });
        }),
      ),
    { dispatch: false },
  );
}

function getRouteState<T extends Params>(settings: RouteFiltersSettings<T>): RouteState {
  if (!routeStates.has(settings)) {
    routeStates.set(settings, {});
  }

  return routeStates.get(settings);
}

function getNormalizedParams<T extends Params>(
  settings: RouteFiltersSettings<T>,
  params?: Params,
  queryParams?: Params,
  empty = true,
): Params {
  const normalizedParams: Params = {};
  const routeParams = Object.assign({}, params || {}, queryParams || {});
  const paramTypes = (params ? settings.params || [] : []).concat(queryParams ? settings.queryParams || [] : []);

  paramTypes.forEach(param => {
    const indexedParamTypes = getIndexedParamTypes(settings);
    const { key, type } = indexedParamTypes[param];
    if (routeParams[key]) {
      normalizedParams[key] = type === 'string' ? routeParams[key] : +routeParams[key];
    } else if (empty) {
      normalizedParams[key] = settings.initialFilters[key];
    }
  });

  return normalizedParams;
}

function getIndexedParamTypes<T extends Params>(settings: RouteFiltersSettings<T>): IndexedParamTypes {
  const routeState = getRouteState(settings);

  if (routeState.indexedParamTypes) {
    return routeState.indexedParamTypes;
  } else {
    const paramTypes = (settings.params || []).concat(settings.queryParams || []);

    return (routeState.indexedParamTypes = paramTypes.reduce((acc: IndexedParamTypes, param) => {
      const [key, type, ignore] = param.split(':');
      acc[param] = { key, type, ignore };

      return acc;
    }, {}));
  }
}
