export interface Notification {
  id: number;
  title: string;
  text: string;
  isRead: boolean;
  createdAt: string;
  linkData: LinkData | null;
}

export interface LinkData {
  name: 'transactions' | 'news' | 'orders' | 'faq' | 'quests' | 'my-quests' | 'shop' | 'personal-info' | 'my-wishlist';
  params: string[];
}
