<ng-container *ngIf="img; else icon">
  <img class="ui-category-tab__img"
       [src]="img"
       [alt]="label"/>

  <img class="ui-category-tab__img ui-category-tab__img_initial"
       *ngIf="initialImg"
       [src]="initialImg"
       [alt]="label"/>
</ng-container>

<span class="ui-category-tab__title">
  {{ label }}
</span>

<span class="ui-category-tab__count"
      *ngIf="countLabel">
  {{ countLabel }}
</span>

<ng-template #icon>
  <ui-icon class="ui-category-tab__icn"
           *ngIf="!isTextOnly"
           size="32"
           [iconName]="iconName || 'default'"></ui-icon>
</ng-template>
