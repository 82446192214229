import { ActivatedRouteSnapshot, Router } from '@angular/router';

/**
 * Returns a route key, consisting of config path parts
 */
export function getRouteKey(router: Router): string {
  return getPath('', router.routerState.snapshot.root.children);
}

function getPath(routeKey: string, routeChildren: ActivatedRouteSnapshot[]): string {
  if (routeChildren?.length) {
    const segment = routeChildren[0];
    if (segment.routeConfig.path) {
      return getPath(routeKey + '/' + segment.routeConfig.path, segment.children);
    } else {
      return getPath(routeKey, segment.children);
    }
  } else {
    return routeKey;
  }
}
