export const accessDataMock = {
  permissions: [
    'adminPinCoins',
    'adminPromotion',
    'adminQuestCatalog',
    'user',
    'adminProductsEditable',
    'adminOrders',
    'adminAchievements',
    'adminProducts',
    'adminAnalytics',
    'adminUsers',
    'admin',
    'adminNews',
    'adminQuests',
    'adminLocations',
  ],
};
