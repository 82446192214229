<ng-container *puLet="dateControl.touch$ | async as controlTouched">
  <div class="pu-control__content">
    <input
      class="pu-control__input pu-control__input_padding"
      [type]="controlTypes.Text"
      [id]="controlId"
      [class.pu-control__input_invalid]="controlTouched && control.invalid"
      [readonly]="isReadonly"
      [placeholder]="placeholder"
      [mask]="ngxDateMask"
      [dropSpecialCharacters]="false"
      [leadZeroDateTime]="true"
      (focus)="setIsFocused(true)"
      (blur)="setIsFocused(false)"
      [formControl]="dateControl"
      [attr.data-testid]="controlTestId"
    />
    <label
      *ngIf="label"
      [for]="controlId"
      class="pu-control__label"
      [ngClass]="{
        'pu-control__label_invalid': controlTouched && control.invalid,
        'pu-control__label_active': control.value || placeholder,
        'pu-control__label_small': labelSize === 's'
      }"
      >{{ label }}</label
    >
    <pu-floating-label-outline
      [hasGap]="isFocused || !!control.value || !!placeholder"
      [label]="label"
      [labelSize]="labelSize"
      [isHighlighted]="isFocused"
      [isInvalid]="controlTouched && control.invalid"
      [isReadOnly]="isReadonly"
    ></pu-floating-label-outline>
  </div>
  <pu-validation-message
    *ngIf="controlTouched && control.invalid && showValidationMessage"
    [errors]="control.errors$ | async"
  ></pu-validation-message>
</ng-container>
