import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pu/services';

import { OkComponent } from './ok.component';
import { OkOptions } from './models/ok-options.model';

@Injectable({ providedIn: 'root' })
export class OkDialog {
  constructor(private _dialog: DialogService) {}

  open(options: OkOptions, isOutOfQueue: boolean = false): DialogRef {
    return this._dialog.open(OkComponent, { ...options }, { isOutOfQueue });
  }
}
