import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { CommonMediaQueries, DialogRef, DialogService, MediaScreenService } from '@pu/services';
import { combineLatest, map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '@pu/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { MenuComponent } from './menu/menu.component';
import { ShellViewModel } from './store/models/shell-vm.model';
import { ShellVmSelectors } from './store/shell-vm.selectors';
import { NotificationsActions } from './components/notifications/store';
import { BirthdaysWidgetActions } from './components/birthdays-widget/store';

@UntilDestroy()
@Component({
  selector: 'pu-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit {
  breakpoints$: Observable<{ isTabletMode: boolean; isDesktopMode: boolean }> = combineLatest([
    this._mediaScreenService.mediaMatcher(CommonMediaQueries.MD),
    this._mediaScreenService.mediaMatcher(CommonMediaQueries.LG),
  ]).pipe(map(([isTabletMode, isDesktopMode]) => ({ isTabletMode, isDesktopMode })));

  vm$: Observable<ShellViewModel> = this._store.select(ShellVmSelectors.selectShellViewModel);

  private _navbarMenuRef: DialogRef;

  constructor(private _mediaScreenService: MediaScreenService, private _dialogService: DialogService, private _store: Store<RootState>) {}

  ngOnInit() {
    // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
    this._store.dispatch(NotificationsActions.initCount());
    // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
    this._store.dispatch(BirthdaysWidgetActions.init());
  }

  openNavbarMenu() {
    this._navbarMenuRef = this._dialogService.openWithCustomRef(
      MenuComponent,
      this._dialogService.createCustomRef({
        centerHorizontally: true,
        bottom: '0',
        panelClass: 'pu-overlay-panel_navbar-menu',
      }),
    );
  }

  closeNavbarMenu() {
    this._navbarMenuRef?.close();
  }

  toggleNotifications(event: Event) {
    event.stopPropagation();
    this._store.dispatch(NotificationsActions.toggle());
  }
}
