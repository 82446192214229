import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { RootState, UiActions, UiSelectors } from '@pu/store';
import { AuthService } from '@pu/services';

import { NotificationsActions, NotificationsSelectors } from '../../components/notifications/store';

@Component({
  selector: 'pu-header-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlsComponent {
  isDarkTheme$ = this._store.select(UiSelectors.selectIsDarkTheme);
  notificationsCount$ = this._store.select(NotificationsSelectors.selectCount);

  constructor(private _store: Store<RootState>, private _auth: AuthService) {}

  setTheme(isDarkTheme: boolean): void {
    this._store.dispatch(UiActions.setTheme({ isDarkTheme: !isDarkTheme }));
  }

  logout(): void {
    this._auth.logout();
  }

  toggleNotifications(event: Event) {
    event.stopPropagation();
    this._store.dispatch(NotificationsActions.toggle());
  }
}
