import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { DropdownTriggerForDirective } from './directives';
import { DropdownComponent } from './components';

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [DropdownTriggerForDirective, DropdownComponent],
  exports: [DropdownTriggerForDirective, DropdownComponent],
})
export class DropdownModule {}
