<pu-avatar
  class="pu-user-profile-card__avatar"
  size="40"
  [userId]="colorfulAvatar ? user.id : undefined"
  [image]="user.imageUrl"
  [nameFirstLetter]="user.email && user.email[0]"
></pu-avatar>

<div class="pu-user-profile-card__container">
  <h3 class="pu-user-profile-card__name">{{ user.fullName }}</h3>
  <p class="pu-user-profile-card__position">{{ user.position }}</p>
  <p class="pu-user-profile-card__email">{{ user.email }}</p>
</div>
