import { EnvName } from './env-name.enum';

/**
 * Environment configuration model
 */
export interface EnvironmentModel {
  /**
   * If this app runs in production mode
   */
  production: boolean;

  /**
   * If you set this option, api methods will return mock data without requests on api server
   */
  useMocks: boolean;

  /**
   * Display name of the environment. Useful for debugging.
   */
  envName: EnvName;

  /**
   * Hostname of the backend server
   */
  apiHost: string;

  /**
   * Hostname of websocket server
   */
  wssHost: string;

  /**
   * Default lang
   */
  defaultLang: string;

  /**
   * Supported ui languages
   */
  supportedUiLanguages: string[];

  /**
   * UI theme
   */
  theme: 'dark' | 'light';

  /**
   * Google client id for auth
   */
  googleClientId: string;

  /**
   * Slack team id
   */
  slackTeamId: string;
}
