<h3
  class="pu-achievement__title"
  *ngIf="type !== 'only-icon'"
>
  {{ achievement.title }}
</h3>

<div class="pu-achievement__img-wrapper">
  <img
    class="pu-achievement__img"
    [src]="achievement.picture"
    [alt]="achievement.title"
  >
</div>
