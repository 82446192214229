export interface RadioOptionModel {
  /**
   * Radio control label
   */
  label: string;
  /**
   * Radio control value
   */
  value: string;
  /**
   * Is radio control default checked
   */
  isDefaultChecked?: boolean;
}
