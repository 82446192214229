import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { Gender } from '@pu/models';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'pu-gender-picker',
  templateUrl: './gender-picker.component.html',
  styleUrls: ['./gender-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenderPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GenderPickerComponent),
      multi: true,
    },
  ],
})
export class GenderPickerComponent extends ControlValueAccessor<Gender> implements OnInit, Validator {
  /**
   * Form control binding
   */
  @Input() formControl: FormControl<Gender>;
  /**
   * Form control name binding
   */
  @Input() formControlName: string;
  /**
   * Indicates if input is non-editable and readonly
   */
  @HostBinding('class.pu-gender-picker_readonly')
  @Input()
  isReadonly: boolean;

  control: FormControl<Gender>;
  gender: typeof Gender = Gender;

  constructor(
    @SkipSelf()
    @Optional()
    private _controlContainer: ControlContainer,
  ) {
    super();
  }

  ngOnInit(): void {
    this.control = this.formControl || (this._controlContainer.control.get(this.formControlName) as FormControl<Gender>);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  writeValue(value: Gender): void {
    // do nothing
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if ([Gender.Male, Gender.Female].includes(control.value)) {
      return null;
    }

    return { required: true };
  }
}
