export type NewStatePath = (string | [string, any])[];

/**
 * Returns a new state with updated data
 * Recursively passes a state by a path
 * @param state
 * @param path - one element of array is 'key: string || [key: string, data: any]' for objects
 * and '[key: string, value: string | number]' for arrays
 */
export function getNewState<T>(state: any, path: NewStatePath): T {
  let newState: any;
  const partPath = [...path];

  if (Array.isArray(state)) {
    newState = [...state];
    const [key, value] = <[string, string | number]>partPath.shift();
    const i = newState.findIndex((item: any) => item[key] === value);

    if (partPath.length) {
      const item = newState.find((item: any) => item[key] === value);
      const newItem = getNewState(item, partPath);
      newState.splice(i, 1, newItem);
    } else {
      newState.splice(i, 1);
    }
  } else if (state !== null && typeof state === 'object') {
    const part = partPath.shift();
    const key = Array.isArray(part) ? part[0] : part;
    const data = Array.isArray(part) ? part[1] : undefined;
    newState = { ...state, ...(data || {}) };

    if (partPath.length) {
      newState[key] = getNewState(newState[key], partPath);
    } else if (key) {
      delete newState[key];
    }
  } else {
    newState = state;
  }

  return newState;
}
