/**
 * Return array with values from item field
 * @param arr
 * @param fieldName
 * @returns T[]
 */
export function pluck<T = number>(arr: Record<string, any>[], fieldName: string): T[] {
  const result: T[] = [];
  arr.forEach((item: any) => {
    result.push(item[fieldName]);
  });

  return result;
}
