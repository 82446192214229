import { Component, HostBinding, Input } from '@angular/core';

import { IconSize } from '@pu/ui';

@Component({
  selector: 'ui-slider-controls',
  templateUrl: './slider-controls.component.html',
  styleUrls: ['./slider-controls.component.scss'],
})
export class SliderControlsComponent {
  /**
   * has slider button should shown, depends on resolution
   */
  @Input() hasSliderNavButtons: boolean;

  /**
   * Has controls bottom gap in a height. (Needs to handle controls height with tabs bottom border).
   */
  @HostBinding('class.ui-slider-controls_bottom-gap')
  @Input()
  hasBottomGap: boolean;

  /**
   * Class for prev arrow control
   */
  @Input() prevControlClass: string;

  /**
   * Class for next arrow control
   */
  @Input() nextControlClass: string;

  /**
   * Arrow icon size
   */
  @Input() iconSize: IconSize = '24';
}
