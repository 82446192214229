import { KeyValue } from '@angular/common';

export const trackByIndex = (index: number): number => index;

export const trackById = <T extends { id: number | string }>(index: number, { id }: T): T['id'] => id;

export const trackByField = (field: string): ((obj: any) => number | string) => {
  return (obj: any): number | string => obj[field];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackByKey = (index: number, a: KeyValue<string | number, any>): string | number => a.key;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackByValue = (index: number, entry: any) => entry;

// eslint-disable-next-line
export const originalKeyValueOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
  return 0;
};
