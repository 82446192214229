<input class="ui-search-input__control"
       type="text"
       [placeholder]="placeholder"
       [formControl]="control"/>

<ui-icon
  class="ui-search-input__icon md:ui-icon_m"
  *ngIf="hasSearchIcon"
  iconName="search"
  size="20">
</ui-icon>
