<pu-simple-input
  [formControl]="inputControl"
  [hint]="hint"
  [label]="label"
  [labelSize]="labelSize"
  [placeholder]="placeholder"
  [errorDictionary]="errorDictionary"
  [iconName]="value ? undefined : 'calendar'"
  [isReadonly]="true"
  [offReadonlyStyle]="true"
  #dropdownTrigger="puDropdownTrigger"
  [puDropdownTriggerFor]="calendar">
</pu-simple-input>

<button
  class="pu-date-picker__clear"
  type="button"
  *ngIf="value"
  uiButton
  uiType="quaternary"
  size="s"
  [onlyIcon]="true"
  (click)="remove($event)">
  <ui-icon iconName="close-2"></ui-icon>
</button>

<pu-dropdown
  #calendar
  [closeOnClick]="false">
  <mat-calendar
    [maxDate]="maxDate"
    [selected]="calendarValue"
    (selectedChange)="setValue($event)">
  </mat-calendar>
</pu-dropdown>
