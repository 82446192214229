import { OverlayRef } from '@angular/cdk/overlay';

import { Observable, Subject } from 'rxjs';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef {
  private _afterClosedSubject = new Subject<unknown>();

  constructor(private _overlayRef: OverlayRef) {}

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  close<T = unknown>(result?: T) {
    this._overlayRef.dispose();
    this._afterClosedSubject.next(result);
    this._afterClosedSubject.complete();
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  afterClosed<T = unknown>(): Observable<T> {
    return <Observable<T>>this._afterClosedSubject.asObservable();
  }
}
