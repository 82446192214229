import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { UserStatusComponent } from './user-status.component';

@NgModule({
  declarations: [UserStatusComponent],
  imports: [CommonModule, TranslateModule],
  exports: [UserStatusComponent],
})
export class UserStatusModule {}
