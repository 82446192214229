import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@pu/environment';
import { BehaviorSubject, map, Observable, switchMap, tap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeDeltaService extends BehaviorSubject<number> {
  constructor(private _http: HttpClient) {
    super(0);

    timer(0, 30000)
      .pipe(
        switchMap(() => this._getServerTime()),
        tap(serverTime => this.next(this._calculateDelta(serverTime))),
      )
      .subscribe();
  }

  private _calculateDelta(serverTime: number): number {
    return serverTime - Date.now();
  }

  private _getServerTime(): Observable<number> {
    return this._http
      .get(environment.apiHost + 'health-check/', { observe: 'response' })
      .pipe(map(response => Date.parse(response.headers.get('Date'))));
  }
}
