import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileComponent } from './user-profile.component';
import { AvatarModule } from '../avatar/avatar.module';
import { AchievementComponent } from '../achievement/achievement.component';
import { LetModule } from '../../directives/let/let.module';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [CommonModule, AvatarModule, LetModule, AchievementComponent],
  exports: [UserProfileComponent],
})
export class UserProfileModule {}
