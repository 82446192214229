import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { environment } from '@pu/environment';
import { createAsyncActionEffect } from '@pu/store';

import { BirthdaysWidgetActions } from './index';
import { todayBirthdaysMock } from '../mocks';

@Injectable()
export class BirthdaysWidgetEffects {
  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BirthdaysWidgetActions.init),
      map(() => BirthdaysWidgetActions.getTodayBirthdays.action()),
    ),
  );

  getTodayBirthdays$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BirthdaysWidgetActions.getTodayBirthdays.action),
      switchMap(() => createAsyncActionEffect(this.getTodayBirthdays(), BirthdaysWidgetActions.getTodayBirthdays)),
    ),
  );

  constructor(private _actions$: Actions, private _http: HttpClient) {}

  getTodayBirthdays() {
    if (environment.useMocks) {
      return todayBirthdaysMock();
    }

    return this._http.get(environment.apiHost + `user/birthdays/today/`);
  }
}
