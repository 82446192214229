import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, Observable, of, switchMap } from 'rxjs';
import { createAsyncActionEffect } from '@pu/store';
import { environment } from '@pu/environment';
import { Store } from '@ngrx/store';

import { CartCounterActions } from './';
import { GetQuantityRes } from '../models';

@Injectable()
export class CartCounterEffects {
  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CartCounterActions.init),
      map(() => CartCounterActions.getQuantityReq.action()),
    ),
  );

  getQuantityReq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CartCounterActions.getQuantityReq.action),
      switchMap(() => createAsyncActionEffect(this.getQuantity(), CartCounterActions.getQuantityReq)),
    ),
  );

  constructor(private _actions$: Actions, private _http: HttpClient, private _store: Store) {}

  getQuantity(): Observable<GetQuantityRes> {
    if (environment.useMocks) {
      return of({ quantity: 5 });
    } else {
      return this._http.get<GetQuantityRes>(environment.apiHost + 'ecommerce/carts-quantity/');
    }
  }
}
