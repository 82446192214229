import { Pipe, PipeTransform } from '@angular/core';

import { LinkData } from '@pu/models';

@Pipe({
  name: 'linkData',
  standalone: true,
})
export class LinkDataPipe implements PipeTransform {
  transform(value: LinkData): any[] {
    if (value.name === 'transactions') {
      return ['/transactions'];
    }

    if (value.name === 'orders') {
      return ['/my-orders'];
    }

    if (value.name === 'news') {
      return ['/news'];
    }

    if (value.name === 'faq') {
      return ['/faq'];
    }

    if (value.name === 'quests') {
      return ['/quests'];
    }

    if (value.name === 'my-quests') {
      return ['/my-quests'];
    }

    if (value.name === 'personal-info') {
      return ['/personal-info'];
    }

    return null;
  }
}
