import { TemplateRef } from '@angular/core';

import { ToastType } from './toast-type.enum';

export interface ToastData {
  /**
   * Toast template ref
   */
  tpl?: TemplateRef<any>;

  /**
   * Icon name
   */
  iconName?: string;

  /**
   * Image url
   */
  imageUrl?: string;

  /**
   * Toast message
   */
  message?: string;

  /**
   * Toast title
   */
  title?: string;

  /**
   * Toast type
   */
  type?: ToastType;

  /**
   * Toast timeout
   */
  timeout?: number;

  /**
   * Accrued toast count
   */
  count?: number;
}
