import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { CommonMediaQueries, DialogRef, MediaScreenService } from '@pu/services';
import { trackByIndex } from '@pu/utils';
import { Observable } from 'rxjs';
import { RootState, RouterActions, UiActions, UiSelectors } from '@pu/store';
import { NavItem } from '@pu/ui';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pu-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
  navItems: NavItem[] = [
    {
      label: 'nav.personalInfo',
      link: '/personal-info',
    },
    {
      label: 'nav.myQuests',
      link: '/my-quests',
    },
    {
      label: 'nav.myWishlist',
      link: '/my-wishlist',
    },
    {
      label: 'nav.myOrders',
      link: '/my-orders',
    },
    {
      label: 'nav.shop',
      link: '/shop',
    },
  ];

  isTablet$: Observable<boolean> = this._mediaScreenService.mediaMatcher(CommonMediaQueries.MD);
  isDarkTheme$ = this._store.select(UiSelectors.selectIsDarkTheme);

  trackByIndex = trackByIndex;

  constructor(
    private _store: Store<RootState>,
    private _dialogRef: DialogRef,
    private _mediaScreenService: MediaScreenService,
    private _actionsSubject: ActionsSubject,
  ) {}

  ngOnInit(): void {
    this._actionsSubject.pipe(ofType(RouterActions.routerNavigatedAction), untilDestroyed(this)).subscribe(() => this.onClose());
  }

  setTheme(isDarkTheme: boolean): void {
    this._store.dispatch(UiActions.setTheme({ isDarkTheme: !isDarkTheme }));
  }

  onClose() {
    this._dialogRef.close();
  }
}
