import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { map, Observable } from 'rxjs';
import { UaParserService } from '@pu/services';

/**
 * Common PU breakpoints
 */
export enum CommonMediaQueries {
  XS = 320,
  SM = 360,
  MD = 768,
  LG = 1280,
  XL = 1366,
  XXL = 1921,
}

@Injectable({
  providedIn: 'root',
})
export class MediaScreenService {
  private _desktopBreakpoint = CommonMediaQueries.XL;
  private _tabletBreakpoint = CommonMediaQueries.MD;

  constructor(private _breakpointObserver: BreakpointObserver, private _uaParser: UaParserService) {}

  mediaMatcher(breakpoint: CommonMediaQueries | number): Observable<boolean> {
    return this._breakpointObserver.observe(`(min-width: ${breakpoint}px)`).pipe(map(({ matches }: BreakpointState) => matches));
  }

  isMatched(breakpoint: CommonMediaQueries | number): boolean {
    return this._breakpointObserver.isMatched(`(min-width: ${breakpoint}px)`);
  }

  private _calculateUaMedia(breakpoint: CommonMediaQueries | number): boolean {
    if (breakpoint >= this._tabletBreakpoint) {
      if (breakpoint >= this._desktopBreakpoint) {
        return this._uaParser.isDesktop;
      } else {
        return this._uaParser.isTablet;
      }
    } else {
      // if breakpoint less than tablet, than we can decide check is it mobile device
      // edge case for promo banners, as we have there 429px breakpoint
      // (fixes issues on desktop SSR)
      return !this._uaParser.isMobile;
    }
  }
}
