import { ToastConfig } from './models';
import { ToasterComponent } from './toaster/toaster.component';

/**
 * Default config for toast service
 */
export const DEFAULT_TOAST_CONFIG: ToastConfig = {
  component: ToasterComponent,
  timeout: 5000,
  position: {
    top: 80,
    right: 24,
  },
};
