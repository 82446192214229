import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { StrNum, StrNumDate } from '@pu/models';
import { uniqueId } from '@pu/utils';
import { FormControl } from '@ngneat/reactive-forms';
import { DropdownTriggerForDirective } from '@pu/forms';

import { PuControl } from '../../models/pu-control.model';

@Component({
  selector: 'pu-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements PuControl<StrNumDate>, OnInit {
  /**
   * Id of control and label for
   */
  @Input() controlId = '' + uniqueId();

  /**
   * Placeholder for input
   */
  @Input() placeholder = '';

  /**
   * Label of input
   */
  @Input() label: string;

  /**
   * Size of label
   */
  @Input() labelSize: 's' | 'm' = 'm';

  /**
   * Hint of input
   */
  @Input() hint: string;

  /**
   * Indicates if input is non-editable and readonly
   */
  @Input() isReadonly: boolean;

  /**
   * Form control binding
   */
  @Input() formControl: FormControl<StrNumDate>;

  /**
   * Form control name binding
   */
  @Input() formControlName: string;

  /**
   * Optional error dictionary for custom errors support
   * Key - custom error key, value - translation key for error txt
   */
  @Input() errorDictionary: Record<string, string> = {};

  /**
   * A max date, which you can pick up in a date picker
   */
  @Input() maxDate: Date = null;

  /**
   * Value date format (only year, month and day)
   * 'dd-MM-yyyy' 'yy:dd:MM' 'YYYY/MM/dd'
   * Value will be returned with this format else Date
   */
  @Input() valueFormat?: string;

  /**
   * Any format like for DatePipe
   * Value will be displayed with this format
   */
  @Input() pickerFormat = 'dd-MM-yyyy';

  /**
   * Set a time to a day start or a day end or a current time
   */
  @Input() timeTo?: 'start' | 'end' | 'current' = 'current';

  @ViewChild('dropdownTrigger') dropdownTrigger: DropdownTriggerForDirective;

  control: FormControl<StrNumDate>;
  touched = false;
  disabled = false;
  value: StrNumDate = null;
  calendarValue: Date = null;
  inputControl = new FormControl<string>('');

  constructor(
    @SkipSelf()
    @Optional()
    private _controlContainer: ControlContainer,
    private _elementRef: ElementRef,
    private _http: HttpClient,
    private _cd: ChangeDetectorRef,
    private _datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.control = this.formControl || (this._controlContainer.control.get(this.formControlName) as FormControl<StrNumDate>);

    if (this.isReadonly) {
      this.disabled = true;
    }
  }

  onChange = (value: StrNumDate): void => {
    //
  };
  onTouched = (): void => {
    //
  };

  setValue(value: Date): void {
    if (!this.disabled) {
      this._markAsTouched();
      this.calendarValue = this._timeTo(value);
      this.value = this.valueFormat ? this._getFormatValue(value, this.valueFormat) : this._timeTo(value);
      this.inputControl.setValue(this._getFormatValue(value, this.pickerFormat) + '');
      this.onChange(this.value);
      this.dropdownTrigger.toggleDropdown();
    }
  }

  remove(event: Event): void {
    if (!this.disabled) {
      event.stopPropagation();
      this._markAsTouched();
      this.value = null;
      this.calendarValue = null;
      this.inputControl.setValue(null);
      this.onChange(null);
    }
  }

  writeValue(value: StrNumDate): void {
    const date = typeof value === 'number' ? new Date(value * 1000) : typeof value === 'string' ? this._srtToDate(value) : value;
    this.value = this.valueFormat ? this._getFormatValue(date, this.valueFormat) : this._timeTo(date);
    this.inputControl.setValue(date ? this._getFormatValue(date, this.pickerFormat) + '' : null);
    this.calendarValue = this._timeTo(date);
    if (this.value !== value) {
      this.onChange(this.value);
    }
  }

  registerOnChange(onChange: (value: StrNumDate) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private _markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _getFormatValue(value: Date, format: string): StrNum {
    if (value) {
      this._timeTo(value);

      return format === 'timestamp' ? Math.round(value.getTime() / 1000) : this._datePipe.transform(value, format);
    } else {
      return null;
    }
  }

  private _srtToDate(value: string): Date {
    const formatParts = (<string>this.valueFormat).split(/[ :\-/]/g);
    const valueParts = value.split(/[ :\-/]/g);
    const data = formatParts.reduce((acc, item, index) => {
      switch (item) {
        case 'yy':
        case 'YY':
        case 'yyyy':
        case 'YYYY':
          acc.year = +valueParts[index];
          break;

        case 'mm':
        case 'MM':
          acc.month = +valueParts[index] - 1;
          break;

        case 'dd':
        case 'DD':
          acc.day = +valueParts[index];
          break;
      }

      return acc;
    }, <{ year: number; month: number; day: number }>{});

    return new Date(data.year, data.month, data.day);
  }

  private _timeTo(date?: Date): Date {
    if (this.timeTo === 'start') {
      date?.setHours(0, 0, 0, 0);
    } else if (this.timeTo === 'end') {
      date?.setHours(23, 59, 59, 999);
    }

    return date;
  }
}
