import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@pu/ui';

import { DropdownModule } from '../dropdown';
import { ControlTooltipComponent } from './control-tooltip.component';

@NgModule({
  imports: [CommonModule, IconModule, DropdownModule],
  declarations: [ControlTooltipComponent],
  exports: [ControlTooltipComponent],
})
export class ControlTooltipComponentModule {}
