import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'pu-text-area',
  templateUrl: './text-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent extends AbstractInputComponent<string> {
  @Input() rows = 5;
  @Input() maxLength: number = null;

  @HostBinding('class.pu-text-area_resize-disabled')
  @Input()
  resizeDisabled = false;
}
