import { ComponentRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { SpinnerComponent } from '../spinner.component';

/**
 * Directive starts a spinner by a condition
 */
@Directive({
  selector: '[uiSpinner]',
})
export class SpinnerDirective implements OnInit {
  @Input() set uiSpinner(condition: boolean) {
    if (condition !== this._isSpinning) {
      this._isSpinning = condition;
      if (condition) {
        this._spinnerRef = this._viewContainer.createComponent(SpinnerComponent);
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => (this.uiSpinner = false), 15000);
      } else if (this._spinnerRef) {
        this._spinnerRef.destroy();
        this._spinnerRef = null;
        clearTimeout(this._timerId);
      }
    }
  }

  private _timerId: any;
  private _isSpinning: boolean;
  private _spinnerRef: ComponentRef<SpinnerComponent>;

  constructor(private _templateRef: TemplateRef<any>, private _viewContainer: ViewContainerRef) {}

  ngOnInit() {
    this._viewContainer.createEmbeddedView(this._templateRef);
  }
}
