import { createSelector } from '@ngrx/store';

import { birthdaysWidgetFeature } from './birthdays-widget.reducers';
import { GetTodayBirthdaysRes } from '../models';

const { selectTodayBirthdays, selectGetTodayBirthdaysAction } = birthdaysWidgetFeature;

export interface BirthdaysWidgetViewModel {
  todayBirthdays: GetTodayBirthdaysRes['todayBirthdays'];
  isLoading: boolean;
}

const selectViewModel = createSelector(
  selectTodayBirthdays,
  selectGetTodayBirthdaysAction,
  (todayBirthdays, getTodayBirthdaysAction): BirthdaysWidgetViewModel => ({
    todayBirthdays,
    isLoading: getTodayBirthdaysAction.inProgress,
  }),
);
export const BirthdaysWidgetSelectors = {
  selectViewModel,
};
