<div class="pu-control__content">
  <input
    class="pu-control__input pu-control__input_sum"
    puInputNumber
    [type]="controlTypes.Text"
    [id]="controlId"
    [ngClass]="{
      'pu-control__input_invalid': control.touched && control.invalid,
      'pu-control__input_right': direction === 'right'
    }"
    [readonly]="isReadonly"
    [placeholder]="placeholder"
    (focus)="setIsFocused(true)"
    (blur)="setIsFocused(false)"
    [formControl]="control"
    [attr.data-testid]="controlTestId"
  />
  <label
    *ngIf="label"
    [for]="controlId"
    class="pu-control__label pu-control__label_active"
    [ngClass]="{
      'pu-control__label_invalid': control.touched && control.invalid,
      'pu-control__label_small': labelSize === 's'
    }"
    >{{ label }}</label
  >
  <pu-floating-label-outline
    [hasGap]="!!label"
    [label]="label"
    [labelSize]="labelSize"
    [isHighlighted]="isFocused"
    [isInvalid]="control.touched && control.invalid"
    [isReadOnly]="isReadonly"
  ></pu-floating-label-outline>
  <span class="pu-control__currency" [class.pu-control__currency_right]="direction === 'right'">{{ currencyLabel }}</span>
  <span *ngIf="hint && !isReadonly" class="pu-control__hint" [class.pu-control__hint_invalid]="control.touched && control.invalid">
    {{ hint }}
  </span>
</div>
<pu-validation-message
  *ngIf="control.touched && control.invalid && showValidationMessage"
  [errors]="control.errors$ | async"
  [errorDictionary]="errorDictionary"
  [errorParams]="errorParams"
></pu-validation-message>
