import { Directive, OnInit, Optional, SkipSelf } from '@angular/core';

import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ExpansionPanelComponent } from './expansion-panel.component';
import { AccordionComponent } from './accordion.component';

/**
 * Accordion item directive provides functionality to close other expansion panels when one opens.
 *   ```
 *   <pu-accordion>
 *     <pu-expansion-panel puAccordionItem></pu-expansion-panel>
 *     <pu-expansion-panel puAccordionItem></pu-expansion-panel>
 *   </pu-accordion>
 *   ```
 *
 * `<pu-expansion-panel>` not necessarily should be the first child of `<pu-accordion>`.
 * If custom element uses expansion panel inside itself, and custom element wrapped with `<pu-accordion>`,
 * expansion panel will be controlled by outer `<pu-accordion>`
 *   ```
 *   <pu-accordion>
 *     <custom-element> has expansion panel in its template </custom-element>
 *     <custom-element> has expansion panel in its template </custom-element>
 *   </pu-accordion>
 *   ```
 */
@UntilDestroy()
@Directive({
  selector: 'pu-expansion-panel[puAccordionItem]',
})
export class AccordionItemDirective implements OnInit {
  constructor(@Optional() private _accordion: AccordionComponent, private _expansionPanel: ExpansionPanelComponent) {
    if (!_accordion) {
      throw Error('[puAccordionItem] can be used only inside <pu-accordion>');
    }
  }

  ngOnInit() {
    this._accordion.openedPanel$
      .pipe(
        filter(panel => panel !== this._expansionPanel),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => this._expansionPanel.close(),
      });

    this._expansionPanel.opened
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this._accordion.emitOpenedPanel(this._expansionPanel),
      });
  }
}
