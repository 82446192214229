import { ChangeDetectionStrategy, Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractInputComponent } from '../abstract-input.component';
import { ControlType } from '../../models/control-type';

@Component({
  selector: 'pu-simple-input',
  templateUrl: './simple-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleInputComponent),
      multi: true,
    },
  ],
})
export class SimpleInputComponent extends AbstractInputComponent<string | number> {
  /**
   * Tooltip text of input
   */
  @Input() tooltip: string;
  /**
   * Icon of input
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() iconTemplate: TemplateRef<any>;
  /**
   * Control type
   */
  @Input() controlType: ControlType = this.controlTypes.Text;
  /**
   * Icon name
   */
  @Input() iconName?: string;
  /**
   * Input autocomplete
   */
  @Input() autocomplete: string;
  /**
   * Label should be pinned as active state
   */
  @Input() isLabelPinned: boolean;
  /**
   * Turn off readonly style, when isReadonly is true
   */
  @Input() offReadonlyStyle: boolean;

  override ngOnInit() {
    super.ngOnInit();
    /* Value interceptor for type="number"
       Converts a value from string to number,
       because a form set an incorrect string value instead of a number value */
    const originalSetValue = this.control.setValue;
    this.control.setValue = (value: any, ...args): any => {
      if (this.controlType === this.controlTypes.Number && typeof value === 'string') {
        value = value ? +value : null;
      }

      return originalSetValue.call(this.control, value, ...args);
    };
  }
}
