export interface OkOptions {
  headerKey: string;
  messageKey?: string;
  messageParams?: Record<string, string | number>;
  subMessageKey?: string;
  subMessageParams?: Record<string, string | number>;
  btnOkKey?: string;
  btnCancelKey?: string;
  iconName?: string;
  imageUrl?: string;
  notCanceled?: boolean;
}
