import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TextAreaComponent } from './text-area.component';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FloatingLabelOutlineComponentModule],
  declarations: [TextAreaComponent],
  exports: [TextAreaComponent],
})
export class TextAreaComponentModule {}
