export type FroalaButton =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikeThrough'
  | 'subscript'
  | 'superscript'
  | 'fontFamily'
  | 'fontSize'
  | 'textColor'
  | 'backgroundColor'
  | 'inlineClass'
  | 'inlineStyle'
  | 'clearFormatting'
  | 'alignLeft'
  | 'alignCenter'
  | 'formatOLSimple'
  | 'alignRight'
  | 'alignJustify'
  | 'formatOL'
  | 'formatUL'
  | 'paragraphFormat'
  | 'paragraphStyle'
  | 'lineHeight'
  | 'outdent'
  | 'indent'
  | 'quote'
  | 'insertLink'
  | 'insertImage'
  | 'insertVideo'
  | 'insertTable'
  | 'emoticons'
  | 'fontAwesome'
  | 'specialCharacters'
  | 'embedly'
  | 'insertFile'
  | 'insertHR'
  | 'undo'
  | 'redo'
  | 'fullscreen'
  | 'print'
  | 'getPDF'
  | 'spellChecker'
  | 'selectAll'
  | 'html'
  | 'help';

export type FroalaMoreButton = 'moreText' | 'moreParagraph' | 'moreRich' | 'moreMisc';
