<div class="pu-empty">
  <div class="pu-empty__icon">
    <ui-icon [iconName]="iconName"
             size="48"></ui-icon>
  </div>

  <h2 class="pu-empty__header">
    {{ titleKey | translate }}
  </h2>

  <p class="pu-empty__description"
     *ngIf="messageKey">
    {{ messageKey | translate }}
  </p>

  <div class="pu-empty__controls">
    <a uiButton
       uiType="secondary"
       size="l"
       [routerLink]="btnLink">
      {{ btnKey | translate }}
    </a>

    <a uiButton
       uiType="secondary"
       size="l"
       [routerLink]="'/'">
      {{ 'empty.btn.homePage' | translate }}
    </a>
  </div>
</div>
