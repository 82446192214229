/**
 * Returns a new object, which has only fields of obj1 different from obj2
 * @param obj1
 * @param obj2
 */
export function diffObj(obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> {
  const obj: Record<string, any> = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
      obj[key] = obj1[key];
    }
  }

  return obj;
}
