import { createAction, props } from '@ngrx/store';
import { createAsyncAction } from '@pu/store';

import { globalSearchFeatureKey } from './index';
import { GetGlobalSearchReq, GetGlobalSearchRes } from '../models';

const searchStringChanged = createAction(`[${globalSearchFeatureKey}]: Search string`, props<{ search: string }>());
const getReq = createAsyncAction<GetGlobalSearchReq, GetGlobalSearchRes>(`[${globalSearchFeatureKey}]: Get global search`);
const clearResults = createAction(`[${globalSearchFeatureKey}]: Clear results`);
const open = createAction(`[${globalSearchFeatureKey}]: Open global search`);
const close = createAction(`[${globalSearchFeatureKey}]: Close global search`);

export const GlobalSearchActions = {
  searchStringChanged,
  getReq,
  clearResults,
  open,
  close,
};
