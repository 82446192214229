import { ActivatedRouteSnapshot, CanMatchFn, DetachedRouteHandle, RouteReuseStrategy, Routes } from '@angular/router';
import { inject } from '@angular/core';

import { AuthService } from '@pu/services';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ShellComponent } from './shell/shell.component';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(current: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot): boolean {
    const data = <any>current.routeConfig?.data;

    // reuse if routeConfig and component class are equal
    // routeParamsToCheck is needed for check of particular route params
    // routeQueryParamsToCheck is needed for check of particular route query params
    return (
      current.routeConfig === future.routeConfig &&
      (!data?.routeParamsToCheck || data.routeParamsToCheck.every((key: string) => current.params[key] === future.params[key])) &&
      (!data?.routeQueryParamsToCheck ||
        data.routeQueryParamsToCheck.every((key: string) => current.queryParams[key] === future.queryParams[key]))
    );
  }
}

const signedOutUser: CanMatchFn = () => {
  const auth = inject(AuthService);

  return auth.signedOutCheck();
};

const signedInUser: CanMatchFn = () => {
  const auth = inject(AuthService);

  return auth.signedInCheck();
};

export const appRoutes: Routes = [
  {
    path: 'signin',
    title: 'Sign In',
    loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninModule),
    canMatch: [signedOutUser],
  },
  {
    path: 'user-not-found',
    title: 'User Not Found',
    loadChildren: () => import('./pages/user-not-found/user-not-found.module').then(m => m.UserNotFoundModule),
  },
  {
    path: 'org-tree',
    title: 'Organization Tree',
    loadChildren: () => import('./pages/org-tree/org-tree.module').then(m => m.OrgTreeModule),
    canMatch: [signedInUser],
  },
  {
    path: '',
    component: ShellComponent,
    canMatch: [signedInUser],
    children: [
      {
        path: 'admin',
        title: 'Admin Panel',
        canMatch: [NgxPermissionsGuard],
        data: { permissions: { only: 'admin', redirectTo: '/' } },
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'personal-info',
        title: 'Personal Info',
        loadChildren: () => import('./pages/personal-info/personal-info.module').then(m => m.PersonalInfoModule),
      },
      {
        path: 'shop',
        title: 'Shop',
        loadChildren: () => import('./pages/shop/shop.module').then(m => m.ShopModule),
      },
      {
        path: 'my-quests',
        title: 'My Quests',
        loadChildren: () => import('./pages/my-quests/my-quests.module').then(m => m.MyQuestsModule),
      },
      {
        path: 'cart',
        title: 'Cart',
        loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule),
      },
      {
        path: 'my-tasks',
        title: 'My Tasks',
        loadChildren: () => import('./pages/my-tasks/my-tasks.module').then(m => m.MyTasksModule),
      },
      {
        path: 'my-orders',
        title: 'My Orders',
        loadChildren: () => import('./pages/my-orders/my-orders.module').then(m => m.MyOrdersModule),
      },
      {
        path: 'news',
        title: 'News',
        data: { articleType: 'news' },
        loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
      },
      {
        path: 'faq',
        title: 'FAQ',
        data: { articleType: 'faq' },
        loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
      },
      {
        path: 'article',
        title: 'Article',
        loadChildren: () => import('./pages/article/article.module').then(m => m.ArticleModule),
      },
      {
        path: 'colleague/:userId',
        title: 'Colleague Profile',
        loadChildren: () => import('./pages/colleague/colleague.module').then(m => m.ColleagueModule),
      },
      {
        path: 'transactions',
        title: 'Transactions History',
        loadChildren: () => import('./pages/transactions/transactions.module').then(m => m.TransactionsModule),
      },
      {
        path: 'my-wishlist',
        title: 'My wishlist',
        loadChildren: () => import('./pages/my-wishlist/my-wishlist.module').then(m => m.MyWishlistModule),
      },
      {
        path: 'birthdays',
        title: 'Birthdays calendar',
        loadChildren: () => import('./pages/birthdays/birthdays.module').then(m => m.BirthdaysModule),
      },
      {
        path: 'quests',
        title: 'Quest Catalog',
        loadChildren: () => import('./pages/quests/quests.module').then(m => m.QuestsModule),
      },
      {
        path: '404',
        title: '404',
        component: NotFoundComponent,
      },
      {
        path: '',
        title: 'Home Page',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: '**',
        title: '404',
        component: NotFoundComponent,
      },
    ],
  },
];
