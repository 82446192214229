import { Notification } from '@pu/models';

import { GetNotificationsCountRes } from '../models';

export const notificationsFeatureKey = 'Notifications';

export type NotificationsCountState = GetNotificationsCountRes & { prevTimestamp: string };

export interface NotificationsState {
  notifications: Notification[];
  notificationsCount: NotificationsCountState;
  opened: boolean;
}
