import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';

import { SelectComponent } from './select.component';
import { SelectDropdownListComponentModule } from '../../components/select-dropdown-list/select-dropdown-list.module';
import { ControlTooltipComponentModule } from '../../components/control-tooltip/control-tooltip.module';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { DropdownModule } from '../../components/dropdown';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    DropdownModule,
    ReactiveFormsModule,
    SelectDropdownListComponentModule,
    ControlTooltipComponentModule,
    FloatingLabelOutlineComponentModule,
    TranslateModule,
  ],
  declarations: [SelectComponent],
  exports: [SelectComponent],
})
export class SelectComponentModule {}
