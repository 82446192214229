/**
 * Replace something like this:
 * - "huy234ns.78.9f" to "234.78".
 * - "4a5b5c78.9f" to "45578.9".
 *
 * @param maybeNumber {string}
 * @returns {string}
 */
export function sanitizeNumeric(maybeNumber: string): string {
  let value = (maybeNumber ? maybeNumber.toString() : '')
    .replace(/,/g, '.')
    .replace(/[^\d.]/g, '')
    .replace(/^\./g, '');

  const position = value.indexOf('.');

  if (position !== -1) {
    const substring = value
      .substring(position + 1)
      .replace(/\./g, '')
      .substring(0, 2);
    value = value.slice(0, position + 1) + substring;
  }

  return value;
}

/**
 * Replace something like this:
 * - "huy234ns.78.9f" to "234".
 * - "4a5b5c78.9f" to "45578".
 *
 * @param maybeNumber {string}
 * @returns {string}
 */
export function sanitizeInteger(maybeNumber: string): string {
  let value = (maybeNumber ? maybeNumber.toString() : '')
    .replace(/,/g, '.')
    .replace(/[^\d.]/g, '')
    .replace(/^\./g, '');

  const position = value.indexOf('.');

  if (position !== -1) {
    value = value.slice(0, position);
  }

  return value;
}
