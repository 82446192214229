import { OptionSize } from './option-size.model';
import { OptionColor } from './option-color.model';
import { Entity } from './entity.model';

export interface ProductOption extends Entity {
  optionColor: OptionColor;
  optionSize: OptionSize;
}

export interface ProductOptionStock extends ProductOption {
  quantity: number;
}

export interface ProductOptionDetails extends ProductOption {
  inCart: boolean;
  inWishlist: boolean;
}
