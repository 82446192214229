import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective, LetModule } from '@pu/directives';
import { ArticleCardSmallModule, UserProfileCardModule } from '@pu/components';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SearchInputModule } from '@pu/forms';

import { GlobalSearchComponent } from './global-search.component';
import { GlobalSearchEffects, globalSearchFeature } from './store';
import { GlobalSearchDialogService } from './dialog/global-search-dialog.service';
import { GlobalSearchOverlay } from './dialog/global-search-overlay';
import { GlobalSearchOverlayContainer } from './dialog/global-search-overlay-container';
import { GlobalSearchInputComponent } from './global-search-input/global-search-input.component';

@NgModule({
  declarations: [GlobalSearchComponent, GlobalSearchInputComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(globalSearchFeature),
    EffectsModule.forFeature([GlobalSearchEffects]),
    TranslateModule,
    LetModule,
    UserProfileCardModule,
    ArticleCardSmallModule,
    RouterLink,
    ClickOutsideDirective,
    SearchInputModule,
  ],
  exports: [GlobalSearchComponent, GlobalSearchInputComponent],
  providers: [GlobalSearchDialogService, GlobalSearchOverlay, GlobalSearchOverlayContainer],
})
export class GlobalSearchModule {}
