<img class="ui-toast__image"
     *ngIf="imageUrl; else icon"
     [src]="imageUrl"
     [alt]="title"/>

<ng-template #icon>
  <ui-icon
    class="ui-toast__icon"
    size="24"
    [iconName]="iconName || toastTypeDataRecord[type].iconName">
  </ui-icon>
</ng-template>

<div class="ui-toast__body">
  <div class="ui-toast__title"
       *ngIf="title">
    {{ title }}
  </div>

  <div class="ui-toast__message">
    {{ message }}
  </div>
</div>

<button
  class="ui-toast__close-btn"
  type="button"
  uiButton
  uiType="quaternary"
  size="m"
  [onlyIcon]="true"
  (click)="close()">
  <ui-icon iconName="close-2"></ui-icon>
</button>
