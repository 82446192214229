export class FroalaUtils {
  static applyStylesConfig(froalaEditorRef: Element, config: Record<string, string>): void {
    for (const selector in config) {
      this.setStyles(froalaEditorRef.querySelectorAll(selector), config[selector]);
    }
  }

  static setStyles(list: any, styles: string): void {
    for (const elem of list) {
      elem.style.cssText = styles;
    }
  }
}
