import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FileUploader, FileItem, FileLikeObject, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from '@pu/environment';
import { trackByField, uniqueId } from '@pu/utils';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { FileModel } from '@pu/models';
import { ToastService, ToastType } from '@pu/ui';
import { TranslateService } from '@ngx-translate/core';

export type UploaderValue = FileModel | FileModel[];

@Component({
  selector: 'ui-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploaderComponent),
      multi: true,
    },
  ],
})
export class UploaderComponent implements OnInit, ControlValueAccessor<UploaderValue> {
  @Input() api = '';
  @Input() apiS3 = '';
  @Input() method = 'PUT';
  @Input() params = {};
  @Input() disableMultipart = false;
  @Input() accept = ''; // '.txt,.rtf,.xls,.xlsx,.doc,.docx,.pdf,.jpg,.jpeg,.png,.ppt,.pptx,.ods,.odt,.odp,.tiff'
  @Input() labelKey = '';
  @Input() placeholderKey = 'Browse';
  @Input() small = false;
  @Input() multiple = false;
  @Input() hiddenBrowse?: boolean;
  @Input() hiddenPlaceholder?: boolean;
  /**
   * Indicates if input is non-editable and readonly
   */
  @Input() isReadonly: boolean;

  /**
   * Form control binding
   */
  @Input() formControl: FormControl<UploaderValue>;

  /**
   * Form control name binding
   */
  @Input() formControlName: string;

  @ViewChild('uploaderInputRef', { static: true }) uploaderInputRef: ElementRef;

  /**
   * Item template
   * @internal
   */
  @ContentChild('filesTpl') filesTpl: TemplateRef<any>;

  uploader: FileUploader;
  control: FormControl<UploaderValue>;
  trackByIndex = trackByField('index');
  inputId = uniqueId();
  hasHiddenBrowse: boolean;
  hasHiddenPlaceholder: boolean;

  constructor(
    @SkipSelf()
    @Optional()
    private _controlContainer: ControlContainer,
    private _cd: ChangeDetectorRef,
    private _http: HttpClient,
    private _translate: TranslateService,
    private _toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.control = this.formControl || (this._controlContainer.control.get(this.formControlName) as FormControl<UploaderValue>);
    this.disableMultipart = this.apiS3 ? true : this.disableMultipart;
    this.hasHiddenBrowse = typeof this.hiddenBrowse === 'undefined';
    this.hasHiddenPlaceholder = typeof this.hiddenPlaceholder === 'undefined';

    this.uploader = new FileUploader({
      method: this.method,
      url: environment.apiHost + this.api,
      disableMultipart: this.disableMultipart,
      autoUpload: false,
      removeAfterUpload: false,
      queueLimit: 30,
      additionalParameter: this.params,

      filters: [
        {
          name: 'byType',
          fn: (item: FileLikeObject) => {
            const ext = item.name.slice(item.name.lastIndexOf('.') + 1);

            return !this.isReadonly && this.accept.indexOf(ext) !== -1;
          },
        },
      ],
    });

    this.uploader.onAfterAddingFile = (item: FileItem) => {
      if (environment.useMocks) {
        item.url = '/assets/images/mock-product.png';
        this.uploader.onSuccessItem(item, '', 200, {});
        item.remove();
      } else {
        if (this.apiS3) {
          this._http.get<{ preSignedUrl: string; fileUrl: string }>(environment.apiHost + this.apiS3).subscribe({
            next: data => {
              item.url = data.preSignedUrl;
              (<any>item).fileUrl = data.fileUrl;
              item.upload();
            },
          });
        } else {
          item.upload();
        }
      }
    };

    this.uploader.onSuccessItem = (item: FileItem, response, status, headers: ParsedResponseHeaders) => {
      const file: FileModel = { id: null, url: (<any>item).fileUrl, name: item.file.name };
      item.remove();
      this._clearInput();

      if (this.multiple) {
        const value = this.control.value;
        this.control.setValue(Array.isArray(value) ? [...value, file] : [file]);
      } else {
        this.control.setValue(file);
      }

      this.control.markAsTouched();
      this._cd.detectChanges();
    };

    this.uploader.onErrorItem = (item: FileItem, response, status, headers: ParsedResponseHeaders) => {
      let error;
      const defaultMessage = this._translate.instant('errors.failed_to_save_file');

      try {
        error = JSON.parse(response);
      } catch (e) {
        error = new Error(defaultMessage);
      }

      this._toast.show({
        type: ToastType.Error,
        message: error?.message || defaultMessage,
      });

      item.remove();
      this._clearInput();
      this.control.markAsTouched();
      this._cd.detectChanges();
    };
  }

  get files(): FileModel[] {
    if (this.multiple) {
      return Array.isArray(this.control.value) ? this.control.value : [];
    } else {
      return this.control.value ? [<FileModel>this.control.value] : [];
    }
  }

  removeItem(event: Event, item: FileItem): void {
    if (!this.isReadonly) {
      event.preventDefault();
      event.stopPropagation();
      item.remove();
      this.control.markAsTouched();
      this._cd.detectChanges();
    }
  }

  removeFile = (event: Event, index: number): void => {
    event.preventDefault();
    event.stopPropagation();

    if (this.multiple) {
      const files = Array.isArray(this.control.value) ? [...this.control.value] : [];
      files.splice(index, 1);
      this.control.setValue(files);
    } else {
      this.control.setValue(null);
    }

    this.control.markAsTouched();
    this._cd.detectChanges();
  };

  onChange = (value: UploaderValue) => {
    //
  };

  onTouched = () => {
    //
  };

  writeValue(value: UploaderValue): void {
    //
  }

  registerOnChange(onChange: (value: UploaderValue) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private _clearInput(): void {
    setTimeout(() => {
      this.uploaderInputRef.nativeElement.value = null;
    });
  }
}
