<ui-logo></ui-logo>

<pu-user-profile></pu-user-profile>

<pu-balance></pu-balance>

<nav class="pu-sidebar__nav">
  <ui-nav-item
    *ngFor="let item of sidebarItems; trackBy: trackByIndex"
    [item]="item">
  </ui-nav-item>
</nav>
