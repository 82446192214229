<ng-container *puLet="notifications$ | async as notifications">
  <div class="pu-notifications">
    <div class="pu-notifications__header">
      <div class="pu-notifications__title">
        {{ 'notifications.myNotifications' | translate }}
      </div>

      <button
        class="pu-notifications__mark-btn"
        type="button"
        (click)="readAll()">
        {{ 'notifications.markAllAsRead' | translate }}
      </button>
    </div>

    <div
      class="pu-notifications__notifications"
      *ngIf="notifications.length; else noNotifications">
      <ng-container *ngFor="let notification of notifications; trackBy: trackById; let last = last">
        <a
          class="pu-notification pu-notification_link"
          [class.pu-notification_read]="notification.isRead"
          *ngIf="notification.linkData"
          [routerLink]="notification.linkData | linkData"
          (click)="readOne(notification.id)">
          <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
        </a>

        <div
          class="pu-notification"
          [class.pu-notification_read]="notification.isRead"
          *ngIf="!notification.linkData"
          (click)="readOne(notification.id)">
          <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
        </div>
        <div
          class="pu-notifications__separator"
          *ngIf="!last">
        </div>

        <ng-template #notificationContent>
          <div
            class="pu-notification__not-read-dot"
            *ngIf="!notification.isRead">
          </div>

          <div class="pu-notification__title">
            {{ notification.title }}
          </div>

          <div class="pu-notification__text">
            {{ notification.text }}
          </div>

          <div class="pu-notification__time">
            {{ notification.createdAt | timeago: false }}
          </div>
        </ng-template>
      </ng-container>
    </div>

    <ng-template #noNotifications>
      <div class="pu-notifications__notifications_zero">
        {{ 'notifications.noNotifications' | translate }}
      </div>
    </ng-template>
  </div>
</ng-container>
