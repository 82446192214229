import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { RootState } from '@pu/store';
import { Store } from '@ngrx/store';
import { PersonalInfo } from '@pu/models';

import { AuthSelectors } from '../../store/auth';

@Component({
  selector: 'pu-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  profile$: Observable<PersonalInfo> = this._store.select(AuthSelectors.selectProfile);

  constructor(private _store: Store<RootState>) {}
}
