import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { ControlType } from '@pu/forms';
import { uniqueId, trackById } from '@pu/utils';
import { AnyEntity } from '@pu/models';

@Component({
  selector: 'ui-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent implements OnInit {
  @Input() tags: AnyEntity[] = [];
  @Input() nameField = 'name';
  @Input() extraField?: string;
  @Input() extraInitValue?: string | number = '';
  @Input() nameLabelKey = 'Name';
  @Input() addBtnKey = 'Add tag';
  @Output() changeTags = new EventEmitter<AnyEntity[]>();
  @ContentChild('extraFieldTpl') extraFieldTpl: TemplateRef<any>;

  trackById = trackById;

  controlTypes = ControlType;
  form: FormGroup<GroupResolverFormBuilder<Record<string, any>>>;

  constructor(private _fb: FormBuilder, private _translate: TranslateService) {}

  ngOnInit(): void {
    this.form = this._fb.group(
      this.extraField
        ? {
            [this.nameField]: ['', Validators.required],
            [this.extraField]: [this.extraInitValue, Validators.required],
          }
        : { [this.nameField]: ['', Validators.required] },
    );
  }

  addTag(): void {
    this.tags.push({ id: 'guid_' + uniqueId(), ...this.form.value });
    this.form.patchValue(
      this.extraField
        ? { [this.nameField]: '' }
        : {
            [this.nameField]: '',
            [this.extraField]: this.extraInitValue,
          },
    );
    this.form.markAsUntouched();
    this.changeTags.emit(this.tags);
  }

  delTag(tag: AnyEntity): void {
    const index = this.tags.findIndex(item => tag.id === item.id);
    this.tags.splice(index, 1);
    this.changeTags.emit(this.tags);
  }
}
