/**
 * Succeeded async actions payload
 */
export interface SucceededAction<T, Initiator extends object | void = void> {
  /**
   * Optional actions payload
   */
  payload: T | undefined;

  /**
   * An action which triggered a request
   */
  initiator?: Initiator;
}
