import { ActionGroup } from '@ngrx/store/src/action_group_creator_models';
import { ActionCreatorProps } from '@ngrx/store/src/models';
import { AppError } from '@pu/models';

/**
 * Async action group
 */
export type AsyncActionGroup<ActionPayload = void, ActionResponse = void, Initiator extends object | void = void> = ActionGroup<
  string,
  {
    ['Action']: ActionCreatorProps<ActionPayload>;
    ['Succeeded Action']: ActionCreatorProps<{
      payload: ActionResponse;
      initiator?: Initiator;
    }>;
    ['Failed Action']: ActionCreatorProps<{
      error: AppError;
      initiator?: Initiator;
    }>;
    ['Clear Action']: ActionCreatorProps<void>;
  }
>;
