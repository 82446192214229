import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ExpansionPanelContentDirective } from './expansion-panel-content.directive';

/**
 * Expansion panel can be used either on its own or inside `<pu-accordion>`.
 * Templates provide `toggle()` and `expanded` variables to be able to implement own toggle button.
 *
 *   Simplest usage:
 *   ```
 *   <pu-expansion-panel>
 *     <ng-container>
 *       Content used as a header of expansion panel. Can be ng-container or any DOM element or component
 *     </ng-container>
 *
 *     <ng-template puExpansionPanelContent>
 *       Content used as a body of expansion panel
 *     </ng-template>
 *   </pu-expansion-panel>
 *   ```
 *
 *
 *   With custom button:
 *   ```
 *   <pu-expansion-panel #panel="pu-expansion-panel">
 *     <ng-container>
 *       Content used as a header of expansion panel. Can be ng-container or any DOM element or component
 *       <button (click)="panel.toggle()">{{ panel.expanded ? 'Close' : 'Open' }}</button>
 *     </ng-container>
 *
 *     <ng-template puExpansionPanelContent>
 *       Content used as a body of expansion panel
 *       <button (click)="panel.toggle()">{{ panel.expanded ? 'Close' : 'Open' }}</button>
 *     </ng-template>
 *   </pu-expansion-panel>
 *   ```
 *
 *   Usage inside accordion:
 *   ```
 *   <pu-accordion>
 *     <pu-expansion-panel puAccordionItem>
 *       <ng-container>
 *         Content used as a header of expansion panel. Can be ng-container or any DOM element or component
 *       </ng-container>
 *
 *       <ng-template puExpansionPanelContent>
 *         Content used as a body of expansion panel
 *       </ng-template>
 *     </pu-expansion-panel>
 *   </pu-accordion>
 *   ```
 */
@Component({
  selector: 'pu-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'pu-expansion-panel',
  animations: [
    trigger('bodyExpansion', [
      state('closed, void', style({ height: '0px', visibility: 'hidden' })),
      state('opened', style({ height: '*', visibility: '' })),
      transition('opened <=> closed, void => closed', animate('250ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
    trigger('beforeDestroyNgIf', [
      transition(':enter', [style({}), animate('250ms cubic-bezier(0.4,0.0,0.2,1)', style({}))]),
      transition(':leave', [style({}), animate('250ms cubic-bezier(0.4,0.0,0.2,1)', style({}))]),
    ]),
  ],
})
export class ExpansionPanelComponent {
  @ContentChild(ExpansionPanelContentDirective) content: ExpansionPanelContentDirective;

  /**
   * Hides default toggle button
   */
  @Input() hideToggle = false;
  /**
   * Whether header click should open panel or panel should open only by button click
   */
  @Input() isTogglableByHeader = false;
  /**
   * Whether body of panel should be rendered once on first open or each time panel opens.
   */
  @Input() renderOnce = true;
  /**
   * Event fires each time panel opens
   */
  @Output() opened = new EventEmitter<void>();
  /**
   * Event fires each time panel closes
   */
  @Output() closed = new EventEmitter<void>();
  /**
   * Event fires once when panel opens if `renderOnce === true` and each time if `renderOnce === false`
   */
  @Output() contentRendered = new EventEmitter<void>();

  hasRendered = false;
  expanded = false;

  constructor(private _cdr: ChangeDetectorRef) {}

  toggle = () => {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  };

  close() {
    this.expanded = false;
    this.closed.emit();
    this._cdr.markForCheck();
  }

  open() {
    this.hasRendered = true;
    this.expanded = true;
    this.opened.emit();
    this._cdr.markForCheck();
  }
}
