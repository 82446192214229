import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule, IconModule } from '@pu/ui';
import { LetModule, RenderedDirective } from '@pu/directives';

import { AccordionItemDirective } from './accordion-item.directive';
import { AccordionComponent } from './accordion.component';
import { ExpansionPanelComponent } from './expansion-panel.component';
import { ExpansionPanelContentDirective } from './expansion-panel-content.directive';

@NgModule({
  declarations: [AccordionComponent, AccordionItemDirective, ExpansionPanelComponent, ExpansionPanelContentDirective],
  imports: [CommonModule, ButtonModule, IconModule, LetModule, RenderedDirective],
  exports: [AccordionComponent, AccordionItemDirective, ExpansionPanelComponent, ExpansionPanelContentDirective],
})
export class ExpansionPanelModule {}
