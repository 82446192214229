import { Observable, of } from 'rxjs';

import { GetNotificationsCountRes, GetNotificationsRes } from '../models';

export function getNotificationsCountMock$(): Observable<GetNotificationsCountRes> {
  return of({
    count: 10,
    lastNotificationTimestamp: new Date().toUTCString(),
  });
}

export function getNotificationsListMock$(): Observable<GetNotificationsRes> {
  return of({
    notifications: Array(10)
      .fill(null)
      .map((_, i) => ({
        id: i,
        createdAt: new Date().toUTCString(),
        isRead: false,
        linkData: i % 2 ? { name: 'news' as const, params: [] } : null,
        text: `Notification ${i} text`,
        title: `Notification ${i} title`,
      })),
  });
}
