<button
  class="ui-emoji"
  type="button"
  uiButton
  size="l"
  uiType="quaternary"
  [onlyIcon]="true"
  (click)="toggle()">
  <ui-icon iconName="emoji" size="24"></ui-icon>
</button>

<emoji-mart
  class="ui-emoji__picker"
  *ngIf="opened"
  [darkMode]="isDarkTheme$ | async"
  [isNative]="true"
  [useButton]="true"
  (emojiClick)="add($event)">
</emoji-mart>
