import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootActions } from '@pu/store';

export function rootEffectsFactory(_store: Store) {
  return () =>
    new Observable(observer => {
      _store.dispatch(RootActions.appInitialized());

      observer.next();
      observer.complete();
    });
}
