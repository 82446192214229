import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { trackById } from '@pu/utils';
import { ImageModel } from '@pu/models';

@Component({
  selector: 'ui-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent implements OnInit {
  @Input() images: ImageModel[] = [];

  trackById = trackById;
  currentImage: ImageModel;
  index = 0;
  opened = false;

  ngOnInit(): void {
    this.currentImage = this.images[this.index];
  }

  open(index: number): void {
    this.opened = true;
    this.index = index;
    this.currentImage = this.images[this.index];
  }

  left(): void {
    this.open(this.index <= 0 ? this.images.length - 1 : --this.index);
  }

  right(): void {
    this.open(this.index >= this.images.length - 1 ? 0 : ++this.index);
  }

  close(): void {
    this.opened = false;
  }
}
