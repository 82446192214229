import { isDate, isRegExp } from './is';

export function equals(obj1: any, obj2: any): boolean {
  const obj: any = {};
  const t1 = typeof obj1;
  const t2 = typeof obj2;

  if (obj1 === obj2) {
    return true;
  }
  if (obj1 === null || obj2 === null) {
    return false;
  }
  if (obj1 !== obj1 && obj2 !== obj2) {
    return true;
  } // NaN === NaN

  if (t1 === t2 && t1 === 'object') {
    if (Array.isArray(obj1)) {
      if (!Array.isArray(obj2)) {
        return false;
      }

      if (obj1.length === obj2.length) {
        const length = obj1.length;
        for (let i = 0; i < length; i++) {
          if (!equals(obj1[i], obj2[i])) {
            return false;
          }
        }

        return true;
      }
    } else if (isDate(obj1)) {
      if (!isDate(obj2)) {
        return false;
      }

      return equals(obj1.getTime(), obj2.getTime());
    } else if (isRegExp(obj1)) {
      if (!isRegExp(obj2)) {
        return false;
      }

      return obj1.toString() === obj2.toString();
    } else {
      if (Array.isArray(obj2) || isDate(obj2) || isRegExp(obj2)) {
        return false;
      }

      for (const key in obj1) {
        if (!obj1.hasOwnProperty(key)) {
          continue;
        }
        if (typeof obj1[key] === 'function') {
          continue;
        }
        if (!equals(obj1[key], obj2[key])) {
          return false;
        }
        obj[key] = true;
      }

      for (const key in obj2) {
        if (!obj2.hasOwnProperty(key)) {
          continue;
        }
        if (typeof obj2[key] === 'function') {
          continue;
        }
        if (!(key in obj) && typeof obj2[key] !== 'undefined') {
          return false;
        }
      }

      return true;
    }
  }

  return false;
}
