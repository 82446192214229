import { Entity } from './entity.model';

export interface FileModel extends Entity {
  /**
   * File url
   */
  url: string;

  /**
   * File name
   */
  name: string;
}

export type ImageModel = FileModel;
