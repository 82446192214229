<swiper
  class="pu-swiper-autosize ui-category-tabs"
  #swiper
  [config]="sliderConfig">

  <ng-template
    class="ui-category-tabs__item"
    *ngFor="let category of categories; trackBy: trackById; let i = index"
    swiperSlide>

    <a class="ui-category-tabs__slide"
       *ngIf="!category.permission || permissions[category.permission]"
       uiCategoryTab
       #rla="routerLinkActive"
       routerLinkActive
       [routerLink]="categoryRoutes[category[paramField]].route"
       [queryParams]="categoryRoutes[category[paramField]].queryParams"
       [routerLinkActiveOptions]="{ exact: category.exactRoute || exactRoute }"
       [isActive]="isActive(rla, category)"
       [label]="category[nameField]"
       [img]="category[imgField]"
       [iconName]="category[iconField]"
       (click)="changeActiveTab(isActive(rla, category), i, category)">
      <!-- eslint-disable-next-line @angular-eslint/template/accessibility-elements-content -->
    </a>
  </ng-template>
</swiper>

<ui-slider-controls
  class="ui-category-tabs__swiper-controls"
  [hasSliderNavButtons]="hasSliderNavButtons$ | async"
  [hasBottomGap]="true"
  prevControlClass="ui-category-tabs__slide-btn-container_prev"
  nextControlClass="ui-category-tabs__slide-btn-container_next">
</ui-slider-controls>
