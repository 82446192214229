import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RootState, UiSelectors } from '@pu/store';
import { Store } from '@ngrx/store';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'ui-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiComponent {
  @Input() inputId: string;
  @Input() control: FormControl<string>;

  isDarkTheme$ = this._store.select(UiSelectors.selectIsDarkTheme);
  opened = false;
  inputElement: any;
  selectionStart: number;
  selectionEnd: number;

  constructor(private _store: Store<RootState>) {}

  toggle(): void {
    this.opened = !this.opened;
    if (this.opened) {
      this.inputElement = this.inputElement || document.getElementById(this.inputId);
      this.selectionStart = this.inputElement.selectionStart;
      this.selectionEnd = this.inputElement.selectionEnd;
    }
  }

  add(emoji: any): void {
    this.opened = false;

    this.control.setValue(
      this.inputElement.value.substring(0, this.selectionStart) +
        emoji.emoji.native +
        this.inputElement.value.substring(this.selectionEnd, this.inputElement.value.length),
    );

    this.selectionStart = this.selectionStart + 2;
    this.selectionEnd = this.selectionStart;

    this.inputElement.focus();
    this.inputElement.setSelectionRange(this.selectionStart, this.selectionStart);
  }
}
