/**
 * Check is provided value positive integer
 * @param str value
 * @returns boolean
 */
export function isPositiveInteger(str: string): boolean {
  const num = Number(str);

  return Number.isInteger(num) && num > 0;
}
