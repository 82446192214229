import { NavigationExtras } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { routerNavigatedAction } from '@ngrx/router-store';

const featureName = 'Router';

const goBack = createAction(`[${featureName}]: Go back`);
const navigate = createAction(`[${featureName}]: Navigate`, props<{ route: string[]; extras?: NavigationExtras }>());
const navigateByUrl = createAction(`[${featureName}]: Navigate by url`, props<{ url: string }>());
const navigateTo404 = createAction(`[${featureName}]: Navigate to 404 page`);
const navigateToHome = createAction(`[${featureName}]: Navigate to home page`);
const reloadCurrentRoute = createAction(`[${featureName}]: Reload current route`);

export const RouterActions = {
  goBack,
  navigate,
  navigateByUrl,
  navigateTo404,
  navigateToHome,
  reloadCurrentRoute,
  routerNavigatedAction,
};
