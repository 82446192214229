import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ToastType, toastTypeDataRecord } from '../models';
import { TOAST_TIMEOUT } from '../constants';

@Component({
  selector: 'ui-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit, OnDestroy {
  toastTypeDataRecord = toastTypeDataRecord;
  type: ToastType = ToastType.Info;

  /**
   * Toast Type: Info | Success | Warning | Fail
   */
  @Input() set toastType(type: ToastType) {
    this.type = type || ToastType.Info;
    this._class = `ui-toast__${toastTypeDataRecord[this.type].className}`;
  }

  /**
   * Icon name
   */
  @Input() iconName: string;

  /**
   * Image url
   */
  @Input() imageUrl: string;

  /**
   * Toast title
   */
  @Input() title: string;

  /**
   * Toast message
   */
  @Input() message: string;

  /**
   * Toast timeout
   */
  @Input() timeout: number;

  @Output() closeToast = new EventEmitter<void>();

  @HostBinding('class')
  private _class = `ui-toast__${toastTypeDataRecord[this.type].className}`;

  private _intervalId: ReturnType<typeof setTimeout>;

  ngOnInit(): void {
    this._intervalId = setTimeout(() => this.close(), this.timeout ?? TOAST_TIMEOUT);
  }

  ngOnDestroy(): void {
    clearInterval(this._intervalId);
  }

  close(): void {
    this.closeToast.emit();
  }
}
