/**
 * Sub Category tab
 */

export interface SubCategoryTab {
  id: number | string;
  exactRoute?: boolean;

  [key: string]: any;
}
