import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';

import { GenderPickerComponent } from './gender-picker.component';

@NgModule({
  imports: [CommonModule, IconModule, ReactiveFormsModule, TranslateModule],
  declarations: [GenderPickerComponent],
  exports: [GenderPickerComponent],
})
export class GenderPickerComponentModule {}
