<label
  class="ui-uploader"
  for="{{inputId}}"
  [class.ui-uploader_readonly]="isReadonly"
  [class.ui-uploader_invalid]="control.touched && control.invalid"
  [class.ui-uploader_small]="small">

  <input
    class="ui-uploader__input"
    id="{{inputId}}"
    type="file"
    accept="{{accept}}"
    [multiple]="multiple"
    #uploaderInputRef
    ng2FileSelect
    [uploader]="uploader"
  />

  <div class="ui-uploader__drop"
       ng2FileDrop
       [uploader]="uploader">

    <span class="ui-uploader__label"
          *ngIf="labelKey"
          [translate]="labelKey"></span>

    <div class="ui-uploader__chain">
      <div class="ui-uploader__browse"
           *ngIf="!hiddenBrowse && (!hasHiddenBrowse || !uploader.queue?.length)">

        <ui-icon
          class="ui-uploader__icon"
          iconName="upload">
        </ui-icon>

        <span class="ui-uploader__placeholder"
              *ngIf="!hiddenPlaceholder && (!hasHiddenPlaceholder || !uploader.queue?.length)"
              [translate]="placeholderKey">
        </span>
      </div>

      <ng-container *ngIf="uploader.queue?.length">
        <div class="ui-uploader__progress-item"
             *ngFor="let item of uploader.queue; trackBy: trackByIndex"
             [title]="item.file.name"
             (click)="removeItem($event, item)">

          <div class="ui-uploader__progress-spinner-container">
            <mat-spinner class="ui-uploader__progress-spinner"></mat-spinner>
          </div>

          <ui-icon
            class="ui-uploader__progress-item-remove"
            iconName="close"
            size="16">
          </ui-icon>
        </div>
      </ng-container>

      <ng-container
        *ngTemplateOutlet="filesTpl || defaultFilesTpl;
        context: { $implicit: files, removeFile: removeFile }">
      </ng-container>
    </div>
  </div>
</label>

<ng-template
  #defaultFilesTpl
  let-files
  let-removeFile="removeFile">

  <div class="pu-uploader-image"
       *ngFor="let file of files; let index = index; trackBy: trackByIndex"
       (click)="removeFile($event, index)">

    <img class="pu-uploader-image__img"
         [src]="file.url"
         [alt]="file.name">

    <div class="pu-uploader-image__remove">
      <ui-icon
        iconName="close"
        size="16">
      </ui-icon>
    </div>
  </div>
</ng-template>
