import { Directive, ElementRef, inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef, RepositionScrollStrategyConfig, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { delay, filter, fromEvent, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UaParserService } from '@pu/services';

import { PopoverComponent } from './popover.component';

/**
 * Diagram describes position of popover relative to origin element
 *                   +-----+  +-----+  +-----+
 *                   |     |  |     |  |     |
 *                   O-----+  +--O--+  +-----O
 *                   O-----+  +--O--+  +-----O
 *                   | tl  |  | tc  |  | tr  |
 *                   +-----+  +-----+  +-----+
 *   +-----O  O-----+                         +-----O  O-----+
 *   |     |  |  lt |                         | rt  |  |     |
 *   +-----+  +-----+                         +-----+  +-----+
 *   +-----+  +-----+                         +-----+  +-----+
 *   |     O  O  lc |                         | rc  O  O     |
 *   +-----+  +-----+                         +-----+  +-----+
 *   +-----+  +-----+                         +-----+  +-----+
 *   |     |  |  lb |                         | rb  |  |     |
 *   +-----O  O-----+                         +-----O  O-----+
 *                   +-----+  +-----+  +-----+
 *                   | bl  |  | bc  |  | br  |
 *                   O-----+  +--O--+  +-----O
 *                   O-----+  +--O--+  +-----O
 *                   |     |  |     |  |     |
 *                   +-----+  +-----+  +-----+
 */
type Positions = 'tl' | 'tc' | 'tr' | 'bl' | 'bc' | 'br' | 'lt' | 'lc' | 'lb' | 'rt' | 'rc' | 'rb';

/**
 * Usage inside global scrollable container (window or document)
 * <div
 *    [puPopover]="popover"
 *    [position]="['tl', 'tr]"
 *    [offsetX]="5"
 *    [offsetY]="5"
 *    scrollStrategy="close"
 *    [delay]="100">
 *    Origin element
 *
 *   Directive exposes close() method which allows to close popover manually from inside template
 *   <ng-template #popover let-close="close">
 *     <div>
 *        Popover content
 *     </div>
 *   </ng-template>
 * </div>
 *
 *
 * Usage inside custom scrollable container.
 * cdkScrollable makes close and reposition strategies works if scrollable container other than global
 *
 * <div cdkScrollable>
 *   <div
 *     [puPopover]="popover"
 *     [position]="['tl', 'tr]"
 *     [offsetX]="5"
 *     [offsetY]="5"
 *     scrollStrategy="close"
 *     [delay]="100">
 *     Origin element
 *
 *     Directive exposes close() method which allows to close popover manually from inside template
 *     <ng-template #popover let-close="close">
 *       <div>
 *         Popover content
 *       </div>
 *     </ng-template>
 *   </div>
 * </div>
 *
 */
@UntilDestroy()
@Directive({
  selector: '[puPopover]',
  standalone: true,
})
export class PopoverDirective implements OnInit, OnDestroy {
  /**
   * Template to be rendered as a popover
   */
  @Input('puPopover') popoverTemplate: TemplateRef<any>;

  /**
   * Scroll strategy:
   * noop - Do nothing on scroll.
   * close - Close the overlay as soon as the user scrolls.
   * block - Block scrolling.
   * reposition - Update the overlay's position on scroll.
   */
  @Input() scrollStrategy: keyof ScrollStrategyOptions = 'noop';

  /**
   * Position or array of positions from most to least desirable
   */
  @Input() position: Positions | Positions[] = 'tc';

  /**
   * Distance between origin element and popover horizontally
   */
  @Input() offsetX: `${number}` | number = 0;

  /**
   * Distance between origin element and popover vertically
   */
  @Input() offsetY: `${number}` | number = 0;

  /**
   * Open/close delay
   */
  @Input() delay = 300;

  private _overlay = inject(Overlay);
  private _elementRef = inject(ElementRef);
  private _uaParser = inject(UaParserService);

  private _overlayRef: OverlayRef;
  private _isOpened = false;
  private _isMouseOverPopover = false;
  private _isMouseOverOrigin = false;

  ngOnInit() {
    this._registerOriginListeners();
  }

  ngOnDestroy() {
    this._close();
  }

  private _show() {
    this._overlayRef = this._createOverlayRef();
    const portal = new ComponentPortal(PopoverComponent);
    const popoverInstance = this._overlayRef.attach(portal).instance;
    popoverInstance.templateRef = this.popoverTemplate;
    popoverInstance.context = { close: this._close.bind(this) };
    popoverInstance.offsetX = `${this.offsetX}px`;
    popoverInstance.offsetY = `${this.offsetY}px`;

    this._isOpened = true;
    this._registerPopoverListeners(this._overlayRef.overlayElement);
  }

  private _close() {
    if (this._isOpened) {
      this._isOpened = false;
    }

    if (this._overlayRef?.hasAttached()) {
      this._overlayRef.detach();
    }
  }

  private _registerOriginListeners() {
    const openEvent = this._uaParser.isTouchDevice ? 'click' : 'mouseenter';

    fromEvent(this._elementRef.nativeElement, openEvent)
      .pipe(
        tap(() => (this._isMouseOverOrigin = true)),
        delay(this.delay),
        filter(() => this._isMouseOverOrigin && !this._isOpened),
        tap(() => this._show()),
        untilDestroyed(this),
      )
      .subscribe();

    fromEvent(this._elementRef.nativeElement, 'mouseleave')
      .pipe(
        tap(() => (this._isMouseOverOrigin = false)),
        delay(this.delay),
        filter(() => !this._isMouseOverPopover),
        tap(() => this._close()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private _registerPopoverListeners(overlayElement: HTMLElement) {
    fromEvent(overlayElement, 'mouseenter')
      .pipe(
        tap(() => (this._isMouseOverPopover = true)),
        untilDestroyed(this),
      )
      .subscribe();

    fromEvent(overlayElement, 'mouseleave')
      .pipe(
        tap(() => (this._isMouseOverPopover = false)),
        delay(this.delay),
        filter(() => !this._isMouseOverOrigin),
        tap(() => this._close()),
        untilDestroyed(this),
      )
      .subscribe();

    this._overlayRef
      .detachments()
      .pipe(
        tap(() => this._close()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private _createOverlayRef() {
    const positionStrategy = this._overlay.position().flexibleConnectedTo(this._elementRef).withPositions(this._getPositions());
    const scrollStrategyConfig: RepositionScrollStrategyConfig = this.scrollStrategy === 'reposition' ? { autoClose: true } : null;
    const scrollStrategy =
      this.scrollStrategy === 'reposition'
        ? this._overlay.scrollStrategies[this.scrollStrategy](scrollStrategyConfig)
        : this._overlay.scrollStrategies[this.scrollStrategy]();

    return this._overlay.create({ positionStrategy, scrollStrategy });
  }

  private _getPositions(): ConnectedPosition[] {
    const originX = (position: Positions): ConnectedPosition['originX'] => {
      if (['tl', 'bl', 'lt', 'lc', 'lb'].includes(position)) {
        return 'start';
      }

      if (['tc', 'bc'].includes(position)) {
        return 'center';
      }

      if (['tr', 'br', 'rt', 'rc', 'rb'].includes(position)) {
        return 'end';
      }

      return 'center';
    };

    const originY = (position: Positions): ConnectedPosition['originY'] => {
      if (['tl', 'tc', 'tr', 'lt', 'rt'].includes(position)) {
        return 'top';
      }

      if (['lc', 'rc'].includes(position)) {
        return 'center';
      }

      if (['bl', 'bc', 'br', 'lb', 'rb'].includes(position)) {
        return 'bottom';
      }

      return 'top';
    };

    const overlayX = (position: Positions): ConnectedPosition['overlayX'] => {
      if (['tl', 'bl', 'rt', 'rc', 'rb'].includes(position)) {
        return 'start';
      }

      if (['tc', 'bc'].includes(position)) {
        return 'center';
      }

      if (['tr', 'br', 'lt', 'lc', 'lb'].includes(position)) {
        return 'end';
      }

      return 'center';
    };

    const overlayY = (position: Positions): ConnectedPosition['overlayY'] => {
      if (['lt', 'rt', 'bl', 'bc', 'br'].includes(position)) {
        return 'top';
      }

      if (['lc', 'rc'].includes(position)) {
        return 'center';
      }

      if (['lb', 'rb', 'tl', 'tc', 'tr'].includes(position)) {
        return 'bottom';
      }

      return 'top';
    };

    const positions = Array.isArray(this.position) ? this.position : [this.position];

    return positions.map(position => ({
      originX: originX(position),
      originY: originY(position),
      overlayX: overlayX(position),
      overlayY: overlayY(position),
    }));
  }
}
