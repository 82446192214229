import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

import { BehaviorSubject, merge, Observable, Subject, Subscription } from 'rxjs';

import { DropdownPanel } from '../models';

@Directive({
  selector: '[puDropdownTriggerFor]',
  exportAs: 'puDropdownTrigger',
})
export class DropdownTriggerForDirective implements OnDestroy {
  @Input('puDropdownTriggerFor') dropdownPanel: DropdownPanel;
  @Input() alignY: 'top' | 'bottom' = 'bottom';
  @Input() alignX: 'start' | 'end' = 'start';
  @Input() offsetY: number;
  @Input() offsetX: number;
  @Input() reference: HTMLElement;
  @Input() inheritWidth = true;
  @Input() isDisabled = false;
  @Input() autoOverlayPosition = false;

  @Output() opened = new EventEmitter<void>();

  isDropdownOpened$: Observable<boolean>;
  closed$: Observable<void>;

  private _isDropdownOpenedSubject = new BehaviorSubject<boolean>(false);
  private _closedSubject = new Subject<void>();
  private _overlayRef: OverlayRef;
  private _dropdownClosingActionsSub = Subscription.EMPTY;

  @HostListener('click', ['$event'])
  onClick() {
    // TODO: crutch for iOS devices. There is a bug when user click on host element, overlay is attached and detached immediately.
    //  It happens only when connected overlay is opening inside dialog overlay.
    //  Host element loses focus on iOS when we open overlay synchronously
    setTimeout(() => {
      this.toggleDropdown();
    }, 100);
  }

  constructor(private _elementRef: ElementRef<HTMLElement>, private _overlay: Overlay, private _viewContainerRef: ViewContainerRef) {
    this.isDropdownOpened$ = this._isDropdownOpenedSubject.asObservable();
    this.closed$ = this._closedSubject.asObservable();
  }

  ngOnDestroy() {
    if (this._isDropdownOpenedSubject.getValue()) {
      this._destroyDropdown();
    }
  }

  updateDropdownPosition(): void {
    this._overlayRef.updatePosition();
  }

  toggleDropdown(): void {
    if (this.isDisabled) {
      return;
    }

    this._isDropdownOpened ? this._destroyDropdown() : this.openDropdown();
  }

  closeDropdown(): void {
    this._destroyDropdown();
  }

  openDropdown(): void {
    if (this._isDropdownOpened) {
      return;
    }

    let width: number | 'auto' = 'auto';

    if (this.reference && this.inheritWidth) {
      width = this.reference.getBoundingClientRect().width;
    } else if (this.inheritWidth) {
      width = this._elementRef.nativeElement.getBoundingClientRect().width;
    }

    this._isDropdownOpenedSubject.next(true);
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this._overlay.scrollStrategies.close(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this.reference || this._elementRef)
        .withPositions(this._overlayPositions()),
      width,
    });

    const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this._viewContainerRef);
    this._overlayRef.attach(templatePortal);
    this.opened.emit();

    this._dropdownClosingActionsSub = this._dropdownClosingActions().subscribe(() => this._destroyDropdown());
  }

  private _overlayPositions(): ConnectedPosition[] {
    if (this.autoOverlayPosition) {
      return [
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: 44,
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetY: -46,
        },
      ];
    } else {
      const defaultOffsetY = this.alignY === 'top' ? -4 : 4;

      return [
        {
          originX: this.alignX,
          originY: this.alignY,
          overlayX: this.alignX,
          overlayY: this.alignY === 'top' ? 'bottom' : 'top',
          offsetY: this.offsetY ?? defaultOffsetY,
          offsetX: this.offsetX ?? undefined,
        },
      ];
    }
  }

  private _destroyDropdown(): void {
    if (!this._overlayRef || !this._isDropdownOpened) {
      return;
    }

    this._dropdownClosingActionsSub.unsubscribe();
    this._isDropdownOpenedSubject.next(false);
    this._closedSubject.next();
    this._overlayRef.detach();
  }

  private _dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this._overlayRef.backdropClick();
    const detachment$ = this._overlayRef.detachments();
    const dropdownClosed = this.dropdownPanel.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  private get _isDropdownOpened(): boolean {
    return this._isDropdownOpenedSubject.getValue();
  }
}
