<div class="pu-gender-picker__option">
  <input
    type="radio"
    id="male"
    [value]="gender.Male"
    name="gender-picker"
    class="pu-gender-picker__radio"
    [formControl]="control"
  />
  <ui-icon iconName="male" size="20" class="pu-gender-picker__icon"></ui-icon>
  <label for="male" class="pu-gender-picker__label">{{ 'profileData.male' | translate }}</label>
</div>
<div class="pu-gender-picker__option">
  <input
    type="radio"
    id="female"
    [value]="gender.Female"
    name="gender-picker"
    class="pu-gender-picker__radio"
    [formControl]="control"
  />
  <ui-icon iconName="female" size="20" class="pu-gender-picker__icon"></ui-icon>
  <label for="female" class="pu-gender-picker__label">{{ 'profileData.female' | translate }}</label>
</div>
