import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';

import { catchError, Observable, throwError, UnaryFunction } from 'rxjs';
import { RootState, AuthActions, RouterActions } from '@pu/store';
import { AppError } from '@pu/models';
import { Store } from '@ngrx/store';

export const DISABLE_ERROR_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

/**
 * Errors interceptor
 */
@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private _store: Store<RootState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._ignoreErrorHandling(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      adaptApiError(),
      catchError((err: AppError) => {
        if (err.originalError?.status === HttpStatusCode.Unauthorized) {
          this._store.dispatch(AuthActions.logout());
        }

        if (err.originalError?.status === HttpStatusCode.NotFound) {
          this._store.dispatch(RouterActions.navigateTo404());
        }

        if (err.code === 'user_is_not_registered') {
          this._store.dispatch(RouterActions.navigateByUrl({ url: '/user-not-found' }));
        }

        return throwError(() => err);
      }),
    );
  }

  private _ignoreErrorHandling(request: HttpRequest<any>): boolean {
    return request.context.get(DISABLE_ERROR_INTERCEPTOR);
  }
}

/**
 * Converts api error to common app error
 */
export function adaptApiError<T>(): UnaryFunction<Observable<T>, Observable<T> | Observable<never>> {
  return catchError((err: HttpErrorResponse) => {
    const error = err.error?.error || err.error || err;
    const appError: AppError = {
      code: error?.code || 'error' + err.status || 'unknown',
      message: error?.message || 'Error ' + err.status,
      data: error?.data,
      originalError: err,
      validationErrors:
        error?.validationErrors?.map((validErr: { code: number; error: string; field: string }) => ({
          code: validErr.code,
          message: validErr.error,
          field: validErr.field,
        })) || [],
    };

    return throwError(() => appError);
  });
}
