import { createSelector } from '@ngrx/store';

import { RootState } from '../';
import { AuthState } from './';

const selectAuthState = (state: RootState) => state.auth;

const selectProfile = createSelector(selectAuthState, (state: AuthState) => state.profile);
const selectRecoveryCodes = createSelector(selectAuthState, (state: AuthState) => state.recoveryCodes);
const selectIs2faEnabled = createSelector(selectAuthState, (state: AuthState) => state.is2faEnabled);

export const AuthSelectors = {
  selectProfile,
  selectRecoveryCodes,
  selectIs2faEnabled,
};
