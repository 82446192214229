import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pu/ui';
import { DIALOG_DATA, DialogRef } from '@pu/services';
import { SupportTip } from '@pu/models';
import { trackById } from '@pu/utils';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, IconModule],
  selector: 'pu-support-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipsComponent {
  @Input() tips: SupportTip[];

  trackById = trackById;
  openedId: number;

  constructor(
    private _dialogRef: DialogRef,
    private _translate: TranslateService,
    @Inject(DIALOG_DATA)
    public data: { tips: SupportTip[] },
  ) {
    this.tips = data.tips;
  }

  open(id: number): void {
    this.openedId = this.openedId === id ? null : id;
  }

  close(): void {
    this._dialogRef.close();
  }
}
