<swiper class="ui-sub-category-tabs pu-swiper-autosize"
        #swiper
        [config]="sliderConfig">

  <ng-template
    class="ui-sub-category-tabs__slide"
    *ngFor="let category of categories; trackBy: trackById; let i = index"
    swiperSlide>

    <a uiButton
       uiType="gradient"
       size="s"
       [class.ui-button_pressed]="isActive(rla, category)"
       routerLinkActive
       #rla="routerLinkActive"
       [routerLink]="categoryRoutes[category[paramField]].route"
       [queryParams]="categoryRoutes[category[paramField]].queryParams"
       [routerLinkActiveOptions]="{ exact: category.exactRoute || exactRoute }"
       (click)="changeActiveTab(isActive(rla, category), i, category)">
      {{category[nameField]}}
    </a>
  </ng-template>
</swiper>

<ui-slider-controls
  class="ui-sub-category-tabs__swiper-controls"
  [hasSliderNavButtons]="hasSliderNavButtons$ | async"
  prevControlClass="ui-sub-category-tabs__slide-btn-container_prev"
  nextControlClass="ui-sub-category-tabs__slide-btn-container_next"
></ui-slider-controls>
