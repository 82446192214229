<h4 class="pu-balance-skin-form__header">
  {{ 'balance.balanceSkinForm.header' | translate }}

  <button
    class="pu-balance-skin-form__close"
    type="button"
    uiButton
    uiType="quaternary"
    size="m"
    [onlyIcon]="true"
    (click)="close()">
    <ui-icon iconName="close-2"></ui-icon>
  </button>
</h4>

<div class="pu-balance-skin-form__body">
  <div class="pu-balance-skin-form__card"
       [class.pu-balance-skin-form__card_dark]="selectedSkin?.isDark">
    <img
      class="pu-balance-skin-form__card-img"
      [src]="selectedSkin?.url || '/assets/images/balance/balance-card-mock.jpg'"
      [alt]="selectedSkin?.name || ''">

    <h5 class="pu-balance-skin-form__card-title">
      {{ 'balance.title.currentBalance' | translate }}
    </h5>

    <p class="pu-balance-skin-form__card-balance">
      {{ balance }}

      <ui-icon
        class="md:ui-icon_m"
        iconName="pin-coin"
        size="32"></ui-icon>
    </p>

    <div class="pu-balance-skin-form__card-share">
      {{ 'balance.button.sharePincoins' | translate }}
    </div>
  </div>


  <div class="pu-balance-skin-form__skins">
    <h5 class="pu-balance-skin-form__skins-title">
      {{ 'balance.balanceSkinForm.skinsTitle' | translate }}
    </h5>

    <ul class="pu-balance-skin-form__skins-list">
      <li 
        class="pu-balance-skin-form__skins-list-item"
        [class.pu-balance-skin-form__skins-list-item_active]="selectedSkin?.id === skin.id"
        *ngFor="let skin of skins; trackBy: trackById"
        (click)="selectSkin(skin)">

        <img [src]="skin.url"
             [alt]="skin.name">
      </li>
    </ul>
  </div>

  <div class="pu-balance-skin-form__actions">
    <button
      type="submit"
      uiButton
      size="l"
      uiType="primary"
      (click)="apply()"
      [disabled]="!selectedSkin">
      {{ 'balance.balanceSkinForm.button.apply' | translate }}
    </button>

    <button
      type="button"
      uiButton
      size="l"
      uiType="secondary"
      (click)="close()">
      {{ 'balance.balanceSkinForm.button.cancel' | translate }}
    </button>
  </div>
</div>
