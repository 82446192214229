import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { EMPTY, Observable, delay, of, throwError } from 'rxjs';
import { environment } from '@pu/environment';
import { RootState } from '@pu/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { ButtonModule, EmojiModule, IconModule, SpinnerModule, ToastService, ToastType } from '@pu/ui';
import { ControlType, SearchSelectModule, SimpleInputComponentModule, TextAreaComponentModule } from '@pu/forms';
import { DIALOG_DATA, DialogRef } from '@pu/services';
import { provideNgxMask } from 'ngx-mask';
import { Store } from '@ngrx/store';
import { UserProfile } from '@pu/models';
import { AvatarModule, UserProfileCardModule } from '@pu/components';
import { normalizeNumber } from '@pu/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AccrueUserRes, AccrualUserForm } from './models';
import { BalanceActions } from '../balance/store';

export type AccrualType = 'admin' | 'site';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    IconModule,
    SimpleInputComponentModule,
    ReactiveFormsModule,
    SearchSelectModule,
    AvatarModule,
    UserProfileCardModule,
    SpinnerModule,
    EmojiModule,
    TextAreaComponentModule,
  ],
  selector: 'pu-accrual-user-form',
  templateUrl: './accrual-user-form.component.html',
  styleUrls: ['./accrual-user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideNgxMask()],
})
export class AccrualUserFormComponent implements OnInit {
  controlTypes = ControlType;
  form: FormGroup<GroupResolverFormBuilder<AccrualUserForm>>;
  isSaving = false;
  api: string;
  type: AccrualType;
  user: UserProfile;

  constructor(
    private _store: Store<RootState>,
    private _dialogRef: DialogRef,
    private _fb: FormBuilder,
    private _translate: TranslateService,
    private _http: HttpClient,
    private _cd: ChangeDetectorRef,
    private _toast: ToastService,
    @Inject(DIALOG_DATA) public data: { type: AccrualType; user: UserProfile },
  ) {
    this.type = data.type;
    this.user = data.user;
  }

  ngOnInit() {
    this.form = this._fb.group({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user: [{ value: <UserProfile>(this.user ? this.user : null), disabled: !!this.user }, Validators.required],
      amount: [1, Validators.required],
      comment: '',
    });

    this.form.controls.amount.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: value => {
        const maxValue = this.type === 'site' ? 10 : null;
        const validatedValue = normalizeNumber(value, 1, maxValue);

        this.form.controls.amount.setValue(validatedValue, { emitEvent: false });
      },
    });
  }

  accrue() {
    this.isSaving = true;

    this.api = this.type === 'admin' ? 'emission/admin/manual/user/' : 'banking/share/';

    const userAccrual = {
      userId: this.form.getRawValue().user.id,
      ...this.form.value,
    };

    delete userAccrual.user;

    let accrueUser$: Observable<AccrueUserRes>;

    if (environment.useMocks) {
      accrueUser$ = of(undefined);
    } else {
      accrueUser$ = this._http.post<AccrueUserRes>(environment.apiHost + this.api, userAccrual);
    }

    accrueUser$.pipe(delay(500)).subscribe({
      next: response => {
        this._dialogRef.close(true);

        if (this.type === 'site') {
          this._store.dispatch(BalanceActions.getBalanceReq.action());
        }
      },
      error: error => {
        this.isSaving = false;
        this._cd.detectChanges();

        if (error.code === 'transfer_limit') {
          this._toast.show({
            type: ToastType.Error,
            iconName: 'pin-coin',
            message: this._translate.instant('site.accrualUserForm.toast', { remainingLimit: error.data.remainingLimit }),
          });

          return EMPTY;
        } else {
          return throwError(() => error);
        }
      },
    });
  }

  close(): void {
    this._dialogRef.close();
  }
}
