import { inject, InjectionToken } from '@angular/core';

import { WINDOW } from './window.model';

export interface INavigator extends Navigator {
  standalone: boolean;
}

export const NAVIGATOR = new InjectionToken<Navigator>('An abstraction over window.navigator object', {
  factory: () => inject(WINDOW).navigator,
});
