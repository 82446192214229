import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SwiperModule } from 'swiper/angular';
import { LetModule } from '@pu/directives';
import { ButtonModule, CategoryTabComponentModule, IconModule, SliderControlsModule } from '@pu/ui';

import { SubCategoryTabsComponent } from './sub-category-tabs.component';

@NgModule({
  imports: [
    CommonModule,
    SwiperModule,
    RouterModule,
    IconModule,
    ButtonModule,
    LetModule,
    SliderControlsModule,
    CategoryTabComponentModule,
  ],
  declarations: [SubCategoryTabsComponent],
  exports: [SubCategoryTabsComponent],
})
export class SubCategoryTabsModule {}
