import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NavItem } from './models/nav-item.model';

/**
 * Navbar tab
 */
@Component({
  selector: 'ui-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent {
  /**
   * Item
   */
  @Input() item: NavItem;
  /**
   * Button click
   */
  @Output() clicked = new EventEmitter<Event>();
}
