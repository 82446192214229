<ng-container *puLet="control.touch$ | async as controlTouched">
  <ng-container *puLet="control.value$ | async as controlValue">
    <div class="pu-control__content">
      <input
        class="pu-control__input"
        #tooltipReference
        [type]="controlType"
        [id]="controlId"
        [ngClass]="{
        'pu-control__input_invalid': controlTouched && control.invalid,
        'pu-control__input_padding': !!tooltip || !!iconTemplate || !!iconName
      }"
        [readonly]="isReadonly"
        [placeholder]="placeholder"
        (focus)="setIsFocused(true)"
        (blur)="setIsFocused(false)"
        [formControl]="control"
        [mask]="maskPattern"
        [patterns]="ngxPatterns"
        [validation]="maskValidation"
        [dropSpecialCharacters]="dropSpecialCharacters"
        [autocomplete]="autocomplete"
        [attr.data-testid]="controlTestId"
        [attr.inputmode]="inputMode"
      />
      <label
        *ngIf="label"
        [for]="controlId"
        class="pu-control__label"
        [ngClass]="{
        'pu-control__label_invalid': controlTouched && control.invalid,
        'pu-control__label_active': controlValue || controlValue === 0 || placeholder || isLabelPinned,
        'pu-control__label_small': labelSize === 's'
      }"
      >{{ label }}</label>
      <pu-floating-label-outline
        [hasGap]="isFocused || !!controlValue || controlValue === 0 || !!placeholder || isLabelPinned"
        [labelSize]="labelSize"
        [label]="label"
        [isHighlighted]="isFocused"
        [isInvalid]="controlTouched && control.invalid"
        [isReadOnly]="isReadonly && !offReadonlyStyle"
      ></pu-floating-label-outline>
      <ui-icon
        class="pu-control__icon"
        *ngIf="iconName"
        [iconName]="iconName"
        size="20">
      </ui-icon>
      <div class="pu-control__icon"
           *ngIf="iconTemplate">
        <ng-container
          [ngTemplateOutlet]="iconTemplate">
        </ng-container>
      </div>
      <pu-control-tooltip
        *ngIf="tooltip"
        class="pu-control__icon pu-control__icon_tooltip"
        [tooltip]="tooltip"
        [reference]="tooltipReference"
      ></pu-control-tooltip>
    </div>
    <pu-validation-message
      *ngIf="controlTouched && (control.invalid$ | async) && showValidationMessage"
      [errors]="control.errors$ | async"
      [errorDictionary]="errorDictionary"
    ></pu-validation-message>
    <div
      class="pu-control__hint pu-control__hint_input"
      *ngIf="isHintVisible && hint"
      [innerHTML]="hint | translate">
    </div>
  </ng-container>
</ng-container>
