    <div class="pu-expansion-panel__header">
      <ng-content></ng-content>

      <button
        class="pu-expansion-panel__header_clickable"
        *ngIf="isTogglableByHeader"
        uiButton
        uiType="quaternary"
        (click)="toggle()">
      </button>

      <button
        class="pu-expansion-panel__toggle-btn"
        [class.pu-expansion-panel__toggle-btn_opened]="expanded"
        *ngIf="!hideToggle"
        type="button"
        uiButton
        uiType="quaternary"
        [onlyIcon]="true"
        (click)="toggle()">
        <ui-icon iconName="dropdown"></ui-icon>
      </button>
    </div>

    <div class="pu-expansion-panel__body"
         [class.pu-expansion-panel__body_closed]="!expanded"
         [@bodyExpansion]="expanded ? 'opened' : 'closed'">
      <div class="pu-expansion-panel__body-content"
           [@beforeDestroyNgIf]="true"
           *ngIf="expanded && !renderOnce || hasRendered && renderOnce"
           (puRendered)="contentRendered.emit()">
        <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
      </div>
    </div>
