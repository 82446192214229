import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { RefreshTokenInterceptor } from './refresh-token.interceptor';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { ErrorsInterceptor } from './errors.interceptor';

/**
 * App http interceptors
 */
export const APP_HTTP_INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorsInterceptor,
    multi: true,
  },
];
