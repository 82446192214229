import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@pu/ui';

import { SliderControlsComponent } from './slider-controls.component';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [SliderControlsComponent],
  exports: [SliderControlsComponent],
})
export class SliderControlsModule {}
