import { Entity } from './entity.model';

export type CatalogType = 'products' | 'quests';

export interface Catalog extends Entity {
  name: string;
  iconName: string; // from catalogIconNames
}

export interface CatalogAdmin extends Catalog {
  locationId: number; // LocationModel
  catalogType: CatalogType;
  visible: boolean;
}
