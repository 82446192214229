import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pu/services';
import { UserProfile } from '@pu/models';

import { AccrualType, AccrualUserFormComponent } from './accrual-user-form.component';

@Injectable({
  providedIn: 'root',
})
export class AccrualUserFormDialog {
  constructor(private _dialog: DialogService) {}

  open(type: AccrualType, user?: UserProfile): DialogRef {
    return this._dialog.open(AccrualUserFormComponent, { type, user });
  }
}
