import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { GlobalSearchArticle, GlobalSearchPerson } from '@pu/models';

import { GlobalSearchActions, globalSearchFeatureKey, GlobalSearchState } from './index';

const peopleReducer = createReducer<GlobalSearchPerson[]>(
  [],
  on(GlobalSearchActions.getReq.succeededAction, (state, { payload }): GlobalSearchPerson[] => payload.people),
  on(GlobalSearchActions.close, (): GlobalSearchPerson[] => []),
  on(GlobalSearchActions.clearResults, (): GlobalSearchPerson[] => []),
);

const newsReducer = createReducer<GlobalSearchArticle[]>(
  [],
  on(GlobalSearchActions.getReq.succeededAction, (state, { payload }): GlobalSearchArticle[] => payload.news),
  on(GlobalSearchActions.close, (): GlobalSearchArticle[] => []),
  on(GlobalSearchActions.clearResults, (): GlobalSearchArticle[] => []),
);

const openedReducer = createReducer<boolean>(
  false,
  on(GlobalSearchActions.open, (): boolean => true),
  on(GlobalSearchActions.close, (): boolean => false),
);

export const globalSearchFeature = createFeature({
  name: globalSearchFeatureKey,
  reducer: combineReducers<GlobalSearchState>({
    people: peopleReducer,
    news: newsReducer,
    opened: openedReducer,
  }),
});
