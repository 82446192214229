import { HttpStatusCode } from '@angular/common/http';

import { Observable, UnaryFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { AppError } from '@pu/models';
import { ErrorsActions } from '@pu/store';
import { environment } from '@pu/environment';

import { AsyncActionGroup } from '../models';

export function handleEffectError<Initiator extends object | void = void>(
  failAction: AsyncActionGroup<void, void, Initiator>['failedAction'],
  initiator: Initiator,
  ignoreUnexpectedErrors: boolean = false,
  expectedErrorCodes?: number[],
): UnaryFunction<Observable<Action>, Observable<Action | AsyncActionGroup<Initiator>['failedAction']>> {
  return catchError((error: AppError) => {
    const action = failAction({ error, initiator });

    if (
      ignoreUnexpectedErrors ||
      expectedErrorCodes?.includes(<number>error?.code) ||
      expectedErrorCodes?.includes(error?.originalError?.status) ||
      error?.originalError?.status === HttpStatusCode.NotFound ||
      error?.originalError?.status === HttpStatusCode.Unauthorized
    ) {
      if (!environment.production) {
        console.error('Async action error: ', error, initiator);
      }

      return [action];
    }

    return [ErrorsActions.unexpectedServerErrorHappened({ error }), action];
  });
}
