import { Entity, UserGroup, UserProfile } from '@pu/models';

export type GroupType = 'monthly' | 'yearly';

export interface Task extends Entity {
  name: string;
  runDate: string;
  amount: number;
  comment: string;
  active: boolean;
}

export interface UserTask extends Task {
  user: UserProfile;
}

export interface UserTaskForm extends Omit<UserTask, 'runDate'> {
  minute: number;
  hour: number;
  day: number;
}

export interface UserTaskFormDialog extends Omit<UserTaskForm, 'id'> {
  user: UserProfile;
}

export interface GroupTask extends Task {
  groups: UserGroup[];
}

export interface GroupTaskForm extends Omit<GroupTask, 'runDate'>, Omit<UserTaskForm, 'user'> {
  month?: number | null;
  groupIds?: number[];
}

export type EventReward = Omit<GroupTask, 'runDate'>;

export interface EventRewardForm extends EventReward {
  groupIds?: number[];
}

export interface ManualGroupTaskForm {
  amount: number;
  comment: string;
  groups: UserGroup[];
}
