<ng-container *puLet="vm$ | async as vm">
  <ng-container *puLet="breakpoints$ | async as breakpoints">
    <aside 
      puSidebar
      *ngIf="breakpoints.isDesktopMode; else navbar"
      [isTablet]="breakpoints.isTabletMode">
    </aside>
    <div class="pu-shell__container">
      <header
        puHeader
        [isShadowVisible]="vm.isShadowVisibleByScroll"
        [breakpoints]="breakpoints">
      </header>
      <main class="pu-shell__main">
        <div class="pu-shell__content container">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
    <ng-template #navbar>
      <nav
        class="pu-navbar"
        puNavbar
        [isTabletMode]="breakpoints.isTabletMode"
        [isHidden]="vm.isNavbarHiddenByScroll"
        (openMenu)="openNavbarMenu()"
        (toggleNotifications)="toggleNotifications($event)"
        (destroyed)="closeNavbarMenu()"
      ></nav>
    </ng-template>
  </ng-container>
</ng-container>
