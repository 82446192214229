import { HttpErrorResponse } from '@angular/common/http';

import { AppValidationError } from './app-validation-error.model';

/**
 * App error interface
 */
export interface AppError {
  /**
   * Error code
   */
  code: string | number;

  /**
   * Api error message
   */
  message?: string;

  /**
   * Additional data
   */
  data?: any;

  /**
   * Original http error
   */
  originalError?: HttpErrorResponse;

  /**
   * Validation errors
   */
  validationErrors?: AppValidationError[];
}
