import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from '@pu/environment';

import { RootState, authReducer, AuthEffects, RouterEffects, uiReducer, UiEffects, ErrorsEffects, KeyboardEffects } from './';

@NgModule({
  imports: [
    StoreModule.forRoot<RootState>({
      ui: uiReducer,
      router: routerReducer,
      auth: authReducer,
    }),
    EffectsModule.forRoot([AuthEffects, RouterEffects, UiEffects, ErrorsEffects, KeyboardEffects]),
    StoreDevtoolsModule.instrument({
      name: 'PINUP',
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class RootStoreModule {}
