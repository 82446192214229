import { StrNum } from './common.types';

/**
 * General entity model
 */
export interface Entity<T extends StrNum = number> {
  /**
   * The identifier of the entity
   */
  id: T;
}

/**
 * Any entity model with any count of fields
 */
export interface AnyEntity<T extends StrNum = StrNum> extends Entity<T> {
  [key: string]: any;
}
