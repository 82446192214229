import { filter, Observable, tap } from 'rxjs';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';

import { equals, diffObj } from './';

export function initFormControl<T>(value$: Observable<T>, control: FormControl<T>, ctrl: any): FormControl<T> {
  value$
    .pipe(
      filter(value => value !== control.value && typeof value !== 'undefined'),
      tap(value => control.setValue(value)),
      untilDestroyed(ctrl),
    )
    .subscribe();

  return control;
}

export function initFormGroup<T>(
  value$: Observable<T>,
  group: FormGroup<GroupResolverFormBuilder<T>>,
  ctrl: any,
): FormGroup<GroupResolverFormBuilder<T>> {
  value$
    .pipe(
      tap(value => {
        if (!equals(value, group.value)) {
          group.patchValue(diffObj(value, group.value), { emitEvent: false });
        }
      }),
      untilDestroyed(ctrl),
    )
    .subscribe();

  return group;
}
