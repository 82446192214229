import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, filter, map, switchMap, tap } from 'rxjs';
import { AUTH_TOKEN_KEY, GOOGLE_JWT_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@pu/constants';
import { AccessData, Enable2FAData, SigninWithCodeData } from '@pu/models';
import { environment } from '@pu/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastService, ToastType } from '@pu/ui';
import { AuthService, DialogService } from '@pu/services';
import { CodesComponent, TfaComponent, TfaEnableComponent } from '@pu/components';
import { Store } from '@ngrx/store';

import { AuthActions, AuthSelectors } from './';
import { createAsyncActionEffect, RootState, RouterActions } from '../';
import { adaptApiError, DISABLE_ERROR_INTERCEPTOR } from '../../interceptors/errors.interceptor';

@Injectable()
export class AuthEffects {
  signIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.signIn.action),
      switchMap(({ payload: { token }, initiator }) => createAsyncActionEffect(this.signIn(token), AuthActions.signIn, initiator, true)),
    ),
  );

  onSignInSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.signIn.succeededAction),
      tap(({ payload: { token } }) => window.localStorage.setItem(GOOGLE_JWT_TOKEN_KEY, token)),
      switchMap(({ payload }) => {
        if (payload.is2faEnabled) {
          this._dialog.open(TfaComponent, { token: payload.token });

          return EMPTY;
        }

        return [AuthActions.enable2fa.action({ payload: { token: payload.token } })];
      }),
    ),
  );

  onSignInFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.signIn.failedAction),
      switchMap(({ error }) => {
        if (error.code === 'user_is_not_found' || error.code === 'user_is_not_active') {
          return [RouterActions.navigateByUrl({ url: '/user-not-found' })];
        }

        this._toast.show({ type: ToastType.Error, message: error.message });

        return [];
      }),
    ),
  );

  enable2fa$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.enable2fa.action),
      switchMap(({ payload: { token }, initiator }) =>
        createAsyncActionEffect(this.enable2fa(token), AuthActions.enable2fa, initiator, true),
      ),
    ),
  );

  onEnable2faSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.enable2fa.succeededAction),
        tap(({ payload }) => {
          const token = window.localStorage.getItem(GOOGLE_JWT_TOKEN_KEY);
          this._dialog.open(TfaEnableComponent, { ...payload, token });
        }),
      ),
    { dispatch: false },
  );

  remove2fa$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.remove2fa.action),
      switchMap(() => createAsyncActionEffect(this.remove2fa(), AuthActions.remove2fa)),
    ),
  );

  onRemove2faSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.remove2fa.succeededAction),
        tap(() => this._auth.logout()),
      ),
    { dispatch: false },
  );

  verifyCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.verifyCode.action),
      switchMap(({ payload: { token, code }, initiator }) =>
        createAsyncActionEffect(this.verifyCode(token, code), AuthActions.verifyCode, initiator, true),
      ),
    ),
  );

  onVerifyCodeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.verifyCode.succeededAction),
      concatLatestFrom(() => [this._store.select(AuthSelectors.selectIs2faEnabled)]),
      switchMap(([{ payload }, is2faEnabled]) => {
        if (is2faEnabled) {
          return [AuthActions.updateAccessData(payload), AuthActions.navigateAfter()];
        }

        this._dialog.open(CodesComponent);

        return [AuthActions.updateAccessData(payload)];
      }),
    ),
  );

  onVerifyCodeFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.verifyCode.failedAction),
        filter(({ error }) => error.code === 'throttled'),
        tap(({ error }) => {
          this._toast.show({ type: ToastType.Error, message: `error.${error.code}` });
        }),
      ),
    { dispatch: false },
  );

  resetCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.resetCode.action),
      switchMap(({ payload: { token, code }, initiator }) =>
        createAsyncActionEffect(this.resetCode(token, code), AuthActions.resetCode, initiator, true),
      ),
    ),
  );

  onResetCodeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.resetCode.succeededAction),
      switchMap(({ payload }) => [AuthActions.updateAccessData(payload), AuthActions.navigateAfter()]),
    ),
  );

  onResetCodeFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.resetCode.failedAction),
        filter(({ error }) => error.code === 'throttled'),
        tap(({ error }) => {
          this._toast.show({ type: ToastType.Error, message: `error.${error.code}` });
        }),
      ),
    { dispatch: false },
  );

  navigateAfter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.navigateAfter),
      map(() => {
        const params = new URLSearchParams(window.location.search);
        const encodedUrl = params.get('url');
        let url = encodedUrl ? decodeURIComponent(encodedUrl) : null;
        url = url && !url.includes('signin') ? (url[0] === '/' ? url : '/' + url) : '/';

        return RouterActions.navigateByUrl({ url });
      }),
    ),
  );

  updateAccessData$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthActions.updateAccessData),
        tap(({ access, refresh, permissions }) => {
          if (access) {
            window.localStorage.setItem(AUTH_TOKEN_KEY, access);
          }

          if (refresh) {
            window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
          }

          if (permissions && permissions.length) {
            this._permissions.loadPermissions(permissions);
          }
        }),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => {
        let path = window.location.pathname;
        path = path.includes('signin') ? '' : path;
        this._permissions.flushPermissions();
        window.localStorage.removeItem(AUTH_TOKEN_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
        window.localStorage.removeItem(GOOGLE_JWT_TOKEN_KEY);

        return [
          AuthActions.setProfile({ profile: null }),
          RouterActions.navigate({
            route: ['/signin'],
            extras: { queryParams: { url: !path || path === '/' ? null : encodeURIComponent(path) } },
          }),
        ];
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _auth: AuthService,
    private _store: Store<RootState>,
    private _http: HttpClient,
    private _permissions: NgxPermissionsService,
    private _toast: ToastService,
    private _dialog: DialogService,
  ) {}

  signIn(token: string) {
    return this._http
      .post<SigninWithCodeData>(
        environment.apiHost + `auth/signin/`,
        { token },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  enable2fa(token: string) {
    return this._http
      .post<Enable2FAData>(
        environment.apiHost + `auth/enable/`,
        { token },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  verifyCode(token: string, code: string) {
    return this._http
      .post<AccessData>(
        environment.apiHost + `auth/verify/`,
        { token, code },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  resetCode(token: string, code: string) {
    return this._http
      .post<AccessData>(
        environment.apiHost + `auth/reset/`,
        { token, code },
        { context: new HttpContext().set(DISABLE_ERROR_INTERCEPTOR, true) },
      )
      .pipe(adaptApiError());
  }

  remove2fa() {
    return this._http.delete<void>(environment.apiHost + `auth/remove/`);
  }
}
