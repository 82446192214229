import { Component, HostBinding, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { Store } from '@ngrx/store';
import { RootState, UiSelectors } from '@pu/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, map, Observable } from 'rxjs';
import { CommonMediaQueries, MediaScreenService } from '@pu/services';

import { PwaSetupDialog } from './components/pwa-setup/pwa-setup.dialog';

@UntilDestroy()
@Component({
  selector: 'pu-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  @HostBinding('class.pu-root') isRoot = true;

  isDarkTheme$ = this._store.select(UiSelectors.selectIsDarkTheme);
  breakpoints$: Observable<{
    isDesktop: boolean;
  }> = combineLatest([this._mediaScreenService.mediaMatcher(CommonMediaQueries.LG)]).pipe(map(([isDesktop]) => ({ isDesktop })));

  constructor(
    private _meta: Meta,
    private _store: Store<RootState>,
    private _mediaScreenService: MediaScreenService,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer: Renderer2,
    private _pwaSetupDialog: PwaSetupDialog,
  ) {}

  ngOnInit() {
    this._pwaSetupDialog.init();

    this.isDarkTheme$.pipe(untilDestroyed(this)).subscribe({
      next: isDarkTheme => {
        isDarkTheme
          ? this._renderer.addClass(this._document.documentElement, 'pu-dark-theme')
          : this._renderer.removeClass(this._document.documentElement, 'pu-dark-theme');
      },
    });

    this.breakpoints$.pipe(untilDestroyed(this)).subscribe({
      next: ({ isDesktop }) => {
        if (isDesktop) {
          this._meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' }, 'name="viewport"');
        } else {
          this._meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' }, 'name="viewport"');
        }
      },
    });
  }
}
