import { Observable, of } from 'rxjs';
import { GlobalSearchArticle, GlobalSearchPerson } from '@pu/models';

import { GetGlobalSearchRes } from '../models';

const names: string[] = [
  'Savannah Graham',
  'Mollie Mullins',
  'Pearl Williamson',
  'Alfie Weaver',
  'Ava Shelton',
  'Christine Rogers',
  'Scarlet Drew',
  'Eleanor Bird',
  'Mary Mccoy',
  'Melissa Meyer',
];

const people: GlobalSearchPerson[] = names.map((name, i) => ({
  id: i + 1,
  fullName: name,
  imageUrl: `https://picsum.photos/200/200?random=${i + 1}`,
  position: 'CEO',
  email: `${name[0].toLowerCase()}.${name.split(' ')[1].toLowerCase()}@pin-up.team`,
}));

const news: GlobalSearchArticle[] = Array(10)
  .fill(null)
  .map((_, i) => ({
    id: i + 1,
    tag: { id: i, name: `Tag ${i}` },
    imageUrl: `https://picsum.photos/200/200?random=${i + 1}`,
    title: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. ${i}`,
    publishedAt: new Date().toUTCString(),
  }));

export function getGlobalSearchMock$(search: string): Observable<GetGlobalSearchRes> {
  return of({
    people: people.filter(person => person.fullName.toLowerCase().includes(search.toLowerCase())),
    news: news.filter(news => news.title.toLowerCase().includes(search.toLowerCase())),
  });
}
