import { createSelector } from '@ngrx/store';
import { GlobalSearchArticle, GlobalSearchPerson } from '@pu/models';

import { globalSearchFeature } from './global-search.reducers';

const { selectNews, selectPeople, selectOpened } = globalSearchFeature;

interface GlobalSearchViewModel {
  people: GlobalSearchPerson[];
  news: GlobalSearchArticle[];
}

const selectViewModel = createSelector(selectPeople, selectNews, (people, news): GlobalSearchViewModel => ({ people, news }));

export const GlobalSearchSelectors = {
  selectViewModel,
  selectOpened,
};
