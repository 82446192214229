import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { AnyEntity, StrNum } from '@pu/models';
import { trackById, uniqueId } from '@pu/utils';
import { FormControl } from '@ngneat/reactive-forms';
import { debounceTime, delay, distinctUntilChanged, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@pu/environment';
import { TranslateService } from '@ngx-translate/core';
import { DropdownTriggerForDirective } from '@pu/forms';

import { PuControl } from '../../models/pu-control.model';
import { getUsersMock } from './mocks';

@UntilDestroy()
@Component({
  selector: 'pu-search-multi-select',
  templateUrl: './search-multi-select.component.html',
  styleUrls: ['./search-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchMultiSelectComponent),
      multi: true,
    },
  ],
})
export class SearchMultiSelectComponent implements PuControl<AnyEntity<StrNum>[]>, OnInit {
  /**
   * Id of control and label for
   */
  @Input() controlId = '' + uniqueId();

  /**
   * Placeholder for input
   */
  @Input() placeholder = '';

  /**
   * Label of input and HostBinding of class
   */
  @HostBinding('class.pu-search-multi-select_labelled')
  @Input()
  label: string;

  /**
   * Size of label
   */
  @Input() labelSize: 's' | 'm' = 'm';

  /**
   * Hint of input
   */
  @Input() hint: string;

  /**
   * Indicates if hint has to be rendered
   */
  @Input() isHintVisible: boolean;

  /**
   * Indicates if input is non-editable and readonly
   */
  @Input() isReadonly: boolean;

  /**
   * Form control binding
   */
  @Input() formControl: FormControl<AnyEntity<StrNum>[]>;

  /**
   * Form control name binding
   */
  @Input() formControlName: string;

  /**
   * Optional error dictionary for custom errors support
   * Key - custom error key, value - translation key for error txt
   */
  @Input() errorDictionary: Record<string, string> = {};

  /**
   * Tooltip text of input
   */
  @Input() tooltip: string;

  /**
   * Api for items search
   */
  @Input() api: string;

  /**
   * Items field
   */
  @Input() itemsField = '';

  /**
   * Id field
   */
  @Input() idField = 'id';

  /**
   * Name field
   */
  @Input() nameField = 'name';

  /**
   * Item template
   * @internal
   */
  @ContentChild('itemTpl') itemTpl: TemplateRef<any>;
  @ViewChild('dropdownRef', { static: true }) dropdownRef: DropdownTriggerForDirective;

  /**
   * Class of component
   * @internal
   */
  @HostBinding('class.pu-control') hasClass = true;

  trackById = trackById;

  items: AnyEntity[] = [];
  selected: AnyEntity[] = [];
  selectedIndex: Record<StrNum, AnyEntity> = {};
  setValueSubject = new Subject<void>();
  control: FormControl<AnyEntity[]>;
  searchControl: FormControl<string>;
  computedPlaceholder = '';
  touched = false;
  disabled = false;
  isDropdownOpened = false;

  constructor(
    @SkipSelf()
    @Optional()
    private _controlContainer: ControlContainer,
    private _elementRef: ElementRef,
    private _http: HttpClient,
    private _cd: ChangeDetectorRef,
    private _translate: TranslateService,
  ) {}

  ngOnInit() {
    this.control = this.formControl || (this._controlContainer.control.get(this.formControlName) as FormControl<AnyEntity<StrNum>[]>);
    this._toggleDisabledAttr(this.control.disabled);
    this.searchControl = new FormControl<string>('');

    if (this.isReadonly) {
      this.searchControl.setDisable(true);
    } else {
      this.searchControl.value$
        .pipe(
          debounceTime(700),
          distinctUntilChanged(),
          switchMap(search => {
            let getItems$: Observable<AnyEntity[]>;

            if (environment.useMocks) {
              getItems$ = of(search && this.api.indexOf('users') >= 0 ? getUsersMock() : <AnyEntity[]>[]);
            } else {
              getItems$ = search
                ? this._http
                    .get<any>(environment.apiHost + this.api, { params: { search } })
                    .pipe(map(data => (this.itemsField ? <AnyEntity[]>data[this.itemsField] : <AnyEntity[]>data)))
                : of(<AnyEntity[]>[]);
            }

            return getItems$;
          }),
          untilDestroyed(this),
        )
        .subscribe({
          next: response => {
            this.items = response;
            this._cd.detectChanges();
          },
        });

      this.setValueSubject
        .asObservable()
        .pipe(
          tap(() => this._cd.detectChanges()),
          delay(300),
          untilDestroyed(this),
        )
        .subscribe({
          next: value => {
            this.control.setValue([...this.selected]);
            this.control.markAsDirty();
            this._cd.detectChanges();
          },
        });

      this.dropdownRef.isDropdownOpened$.pipe(untilDestroyed(this)).subscribe({
        next: opened => {
          this.isDropdownOpened = opened;
          if (!opened) {
            this.searchControl.setValue('');
          }
        },
      });
    }
  }

  onChange = (item: AnyEntity[]) => {
    //
  };
  onTouched = () => {
    //
  };

  /**
   * @internal
   * @param event
   * @param item
   */
  toggleItem(event: Event, item: AnyEntity): void {
    event.stopPropagation();
    const id = <StrNum>item[this.idField];
    const selected = this.selectedIndex[id];
    const index = this.selected.findIndex(entity => id === entity[this.idField]);
    this.selectedIndex[id] = selected ? null : item;

    if (selected) {
      if (index >= 0) {
        this.selected.splice(index, 1);
      }
    } else if (index === -1) {
      this.selected.push(item);
    }

    this.computedPlaceholder = this._computePlaceholder();
    this.setValueSubject.next();
  }

  /**
   * @internal
   */
  removeAll(): void {
    this._setValue([]);
    this.searchControl.setValue('');
    this.setValueSubject.next();
  }

  writeValue(value: AnyEntity[]): void {
    this._setValue(value);
  }

  registerOnChange(onChange: (items: AnyEntity[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this._toggleDisabledAttr(disabled);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  /**
   * Set the disabled attribute of the control native element
   * @param {boolean} isDisabled - boolean
   */
  private _toggleDisabledAttr(isDisabled: boolean): void {
    if (isDisabled) {
      this._elementRef.nativeElement.setAttribute('disabled', isDisabled);
    } else {
      this._elementRef.nativeElement.removeAttribute('disabled');
    }
  }

  private _computePlaceholder(): string {
    return this.selected.length
      ? this.selected.reduce((acc, item) => {
          let name: string;

          try {
            name = this._translate.instant(item[this.nameField]);
          } catch (e) {
            name = item[this.nameField];
          }

          return (acc ? acc + ', ' : acc) + name;
        }, '')
      : this.placeholder;
  }

  private _setValue(value: AnyEntity[]): void {
    this.selected = [...value];
    this.computedPlaceholder = this._computePlaceholder();
    this.selectedIndex = value.reduce((acc, item) => {
      acc[item[this.idField]] = item;

      return acc;
    }, <Record<StrNum, AnyEntity>>{});
  }
}
