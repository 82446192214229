import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

const TOOLTIP_OFFSET = -4;

@Component({
  selector: 'pu-control-tooltip',
  templateUrl: './control-tooltip.component.html',
  styleUrls: ['./control-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlTooltipComponent {
  /**
   * Tooltip text
   */
  @Input() tooltip: string;
  /**
   * Reference for input
   */
  @Input() reference: HTMLElement;
  /**
   * Offset for tooltip
   */
  tooltipOffset = TOOLTIP_OFFSET;
}
