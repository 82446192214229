import { NewsShop } from '@pu/models';

export function getNewsMock(count = 12): NewsShop[] {
  const newsMock: NewsShop[] = [];

  for (let i = 1; i <= count; i++) {
    newsMock.push({
      id: i,
      tag: { id: i, name: `Tag ${i}` },
      title: `News ${i}`,
      previewImage: {
        id: i,
        url: `/assets/images/mock-article.png`,
        name: `${i}.jpg`,
      },
      image: {
        id: i,
        url: `/assets/images/mock-article.png`,
        name: `${i}.jpg`,
      },
      previewText: 'Preview text',
      article:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta doloribus eius itaque officia officiis repellat rerum suscipit voluptas? A ab asperiores delectus dicta eum excepturi magnam non similique ut vero?',
      publishedAt: new Date().toString(),
      read: !(i % 2),
      saved: !(i % 2),
    });
  }

  return newsMock;
}
