import { createAction } from '@ngrx/store';
import { createAsyncAction, createAsyncActionNoProps } from '@pu/store';

import { balanceFeatureKey } from '.';
import { ApplyBalanceSkinReq, ApplyBalanceSkinRes, GetBalanceRes, GetBalanceSkinsRes, GetTagsRes } from '../models';

const init = createAction(`[${balanceFeatureKey}]: Init`);
const getBalanceReq = createAsyncActionNoProps<GetBalanceRes>(`[${balanceFeatureKey}]: Get balance`);
const getBalanceSkinsReq = createAsyncActionNoProps<GetBalanceSkinsRes>(`[${balanceFeatureKey}]: Get balance skins req`);
const applyBalanceSkinReq = createAsyncAction<ApplyBalanceSkinReq, ApplyBalanceSkinRes>(`[${balanceFeatureKey}]: Apply balance skin req`);
const getTagsReq = createAsyncActionNoProps<GetTagsRes>(`[${balanceFeatureKey}]: Get tags`);

export const BalanceActions = {
  init,
  getBalanceReq,
  getBalanceSkinsReq,
  applyBalanceSkinReq,
  getTagsReq,
};
