import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { AnyEntity, StrNum } from '@pu/models';
import { trackById, uniqueId } from '@pu/utils';
import { FormControl } from '@ngneat/reactive-forms';
import { debounceTime, distinctUntilChanged, map, Observable, of, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@pu/environment';

import { PuControl } from '../../models/pu-control.model';
import { getProductsMock, getUsersMock } from './mocks';

@UntilDestroy()
@Component({
  selector: 'pu-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true,
    },
  ],
})
export class SearchSelectComponent implements PuControl<AnyEntity<StrNum>>, OnInit {
  /**
   * Id of control and label for
   */
  @Input() controlId = '' + uniqueId();

  /**
   * Placeholder for input
   */
  @Input() placeholder = '';

  /**
   * Label of input and HostBinding of class
   */
  @HostBinding('class.pu-search-select_labelled')
  @Input()
  label: string;

  /**
   * Size of label
   */
  @Input() labelSize: 's' | 'm' = 'm';

  /**
   * Hint of input
   */
  @Input() hint: string;

  /**
   * Indicates if hint has to be rendered
   */
  @Input() isHintVisible: boolean;

  /**
   * Indicates if input is non-editable and readonly
   */
  @Input() isReadonly: boolean;

  /**
   * Form control binding
   */
  @Input() formControl: FormControl<AnyEntity<StrNum>>;

  /**
   * Form control name binding
   */
  @Input() formControlName: string;

  /**
   * Optional error dictionary for custom errors support
   * Key - custom error key, value - translation key for error txt
   */
  @Input() errorDictionary: Record<string, string> = {};

  /**
   * Tooltip text of input
   */
  @Input() tooltip: string;

  /**
   * Api for items search
   */
  @Input() api: string;

  /**
   * Items field
   */
  @Input() itemsField = '';

  @Input() dropdownAlignX: 'start' | 'end' = 'start';
  @Input() dropdownInheritWidth = true;

  /**
   * Item template
   * @internal
   */
  @ContentChild('itemTpl') itemTpl: TemplateRef<any>;

  /**
   * Class of component
   * @internal
   */
  @HostBinding('class.pu-control') hasClass = true;

  trackById = trackById;

  items: AnyEntity[] = [];
  selectedItem: AnyEntity = null;
  control: FormControl<AnyEntity>;
  searchControl: FormControl<string>;
  touched = false;
  disabled = false;

  constructor(
    @SkipSelf()
    @Optional()
    private _controlContainer: ControlContainer,
    private _elementRef: ElementRef,
    private _http: HttpClient,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.control = this.formControl || (this._controlContainer.control.get(this.formControlName) as FormControl<AnyEntity<StrNum>>);
    this._toggleDisabledAttr(this.control.disabled);
    this.searchControl = new FormControl<string>('');

    if (this.isReadonly) {
      this.searchControl.setDisable(true);
    } else {
      this.searchControl.value$
        .pipe(
          debounceTime(700),
          distinctUntilChanged(),
          switchMap(search => {
            let getItems$: Observable<AnyEntity[]>;

            if (environment.useMocks) {
              getItems$ = of(
                search
                  ? this.api.indexOf('users') >= 0
                    ? getUsersMock()
                    : this.api.indexOf('products') >= 0
                    ? getProductsMock()
                    : <AnyEntity[]>[]
                  : <AnyEntity[]>[],
              );
            } else {
              getItems$ = search
                ? this._http
                    .get<any>(environment.apiHost + this.api, { params: { search } })
                    .pipe(map(data => (this.itemsField ? <AnyEntity[]>data[this.itemsField] : <AnyEntity[]>data)))
                : of(<AnyEntity[]>[]);
            }

            return getItems$;
          }),
          untilDestroyed(this),
        )
        .subscribe({
          next: response => {
            this.items = response;
            this._cd.detectChanges();
          },
        });
    }
  }

  onChange = (item: AnyEntity) => {};
  onTouched = () => {};

  selectItem(item: AnyEntity): void {
    if (!this.disabled) {
      this.markAsTouched();
      this.selectedItem = item;
      this.searchControl.setValue('');
      this.onChange(item);
    }
  }

  removeItem(): void {
    if (!this.disabled) {
      this.markAsTouched();
      this.selectedItem = null;
      this.onChange(null);
    }
  }

  writeValue(item: AnyEntity): void {
    this.selectedItem = item;
  }

  registerOnChange(onChange: (item: AnyEntity) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this._toggleDisabledAttr(disabled);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  /**
   * Set the disabled attribute of the control native element
   * @param {boolean} isDisabled - boolean
   */
  private _toggleDisabledAttr(isDisabled: boolean): void {
    if (isDisabled) {
      this._elementRef.nativeElement.setAttribute('disabled', isDisabled);
    } else {
      this._elementRef.nativeElement.removeAttribute('disabled');
    }
  }
}
