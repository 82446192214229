import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { Notification } from '@pu/models';
import { AuthActions } from '@pu/store';

import { NotificationsActions, NotificationsCountState, notificationsFeatureKey, NotificationsState } from './';

const notificationsCountReducer = createReducer<NotificationsCountState>(
  { count: 0, lastNotificationTimestamp: '', prevTimestamp: '' },
  on(
    NotificationsActions.getNotificationsCountReq.succeededAction,
    (state, { payload }): NotificationsCountState => ({ prevTimestamp: state.lastNotificationTimestamp, ...payload }),
  ),
  on(AuthActions.logout, (): NotificationsCountState => ({} as NotificationsCountState)),
);

const initialNotifications: Notification[] = [];
const notificationsReducer = createReducer<Notification[]>(
  initialNotifications,
  on(NotificationsActions.getNotificationsReq.succeededAction, (state, { payload }): Notification[] =>
    [...payload.notifications].sort((a, b) => (Date.parse(a.createdAt) > Date.parse(b.createdAt) ? -1 : 1)),
  ),
  on(AuthActions.logout, (): Notification[] => initialNotifications),
);

const openedReducer = createReducer<boolean>(
  false,
  on(NotificationsActions.open, (): boolean => true),
  on(NotificationsActions.close, (): boolean => false),
);

export const notificationsFeature = createFeature({
  name: notificationsFeatureKey,
  reducer: combineReducers<NotificationsState>({
    notificationsCount: notificationsCountReducer,
    notifications: notificationsReducer,
    opened: openedReducer,
  }),
});
