import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { IconSize } from './models/icon-size';

@Component({
  selector: 'ui-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  /**
   * @internal
   */
  @HostBinding('class.ui-icon') hostClass = true;
  /**
   * Corresponds to svg file name inside /icons/svg-icons or bet folder.
   */
  @Input() iconName: string;

  /**
   * Icon size
   */
  @Input() size: IconSize = '24';
}
