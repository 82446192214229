import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormControlDirective } from '@angular/forms';

import { FormControl } from '@ngneat/reactive-forms';
import { uniqueId } from '@pu/utils';

import { PuControl } from '../models/pu-control.model';
import { ControlType } from '../models/control-type';
import { ngxPatterns } from '../models/ngx-mask-patterns';
import { InputModeOptions } from '../models/input-mode-options';

@Component({
  template: '',
})
export abstract class AbstractInputComponent<T> implements PuControl<T>, OnInit {
  /**
   * Form control directive
   * @internal
   */
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

  /**
   * Id of control and label for
   */
  @Input() controlId = '' + uniqueId();
  /**
   * Placeholder for input
   */
  @Input() placeholder = '';
  /**
   * Label of input
   */
  @Input() label: string;
  /**
   * Size of label
   */
  @Input() labelSize: 's' | 'm' = 'm';
  /**
   * Hint of input
   */
  @Input() hint: string;
  /**
   * Indicates if hint has to be rendered
   */
  @Input() isHintVisible: boolean;
  /**
   * Indicates if input is non-editable and readonly
   */
  @Input() isReadonly: boolean;
  /**
   * Form control binding
   */
  @Input() formControl: FormControl<T>;
  /**
   * Form control name binding
   */
  @Input() formControlName: string;
  /**
   * Ngx input mask
   */
  @Input() maskPattern = '';
  /**
   * Ngx mask validation property
   */
  @Input() maskValidation = true;
  /**
   * Ngx mask dropSpecialCharacters property
   */
  @Input() dropSpecialCharacters = true;
  /**
   * Is error message visible
   */
  @Input() showValidationMessage = true;
  /**
   * Optional error dictionary for custom errors support
   * Key - custom error key, value - translation key for error txt
   */
  @Input() errorDictionary: Record<string, string> = {};

  /**
   * Optional error param dictionary for custom errors with params
   * Key - custom error key, value - object with that contains: key - translation param, value - param's value
   */
  @Input() errorParams: Record<string, Record<string, string>>;

  /**
   * Test id
   */
  @Input() controlTestId: string;

  /**
   * Input mode property
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
   */
  @Input() inputMode: InputModeOptions = 'text';

  /**
   * Class of component
   * @internal
   */
  @HostBinding('class.pu-control') hasClass = true;

  /**
   * Is focused
   * Needed for floating label
   * @internal
   */
  isFocused: boolean;

  /**
   * @internal
   */
  @Output() focused = new EventEmitter();

  /**
   * @internal
   */
  control: FormControl<T>;

  /**
   * @internal
   */
  controlTypes: typeof ControlType = ControlType;

  /**
   * Mask pattern descriptor
   * @internal
   */
  ngxPatterns = ngxPatterns;

  constructor(
    @SkipSelf()
    @Optional()
    private _controlContainer: ControlContainer,
    private _elementRef: ElementRef,
    protected _cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.control = this.formControl || (this._controlContainer.control.get(this.formControlName) as FormControl<T>);
    this._toggleDisabledAttr(this.control.disabled);
  }

  /**
   * Register a function that will be called when the control is touched
   * @param fn - A function that is called when the control is touched.
   * @internal
   */
  registerOnTouched(fn: () => void): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  /**
   * Register a callback function that will be called when the value of the input changes
   * @param fn - A function that is called when the value changes.
   * @internal
   */
  registerOnChange(fn: (value: T) => void): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  /**
   * Write the value of the form control to the form control's value accessor
   * @param {string} value - The value to be written to the model.
   * @internal
   */
  writeValue(value: T): void {
    this.formControlDirective.valueAccessor.writeValue(value);
  }

  /**
   * Set the disabled state of the control
   * @param {boolean} isDisabled - boolean
   * @internal
   */
  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    this._toggleDisabledAttr(isDisabled);
  }

  /**
   * Sets is focused
   * @param isFocused
   * @internal
   */
  setIsFocused(isFocused: boolean): void {
    this.isFocused = isFocused;

    if (isFocused) {
      this.focused.emit();
    }
  }

  /**
   * Set the disabled attribute of the control native element
   * @param {boolean} isDisabled - boolean
   */
  private _toggleDisabledAttr(isDisabled: boolean) {
    if (isDisabled) {
      this._elementRef.nativeElement.setAttribute('disabled', isDisabled);
    } else {
      this._elementRef.nativeElement.removeAttribute('disabled');
    }
  }
}
