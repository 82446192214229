/**
 * Category tabs
 */
import { Entity } from '@pu/models';

export interface CategoryTab extends Entity {
  permission?: string;
  exactRoute?: boolean;

  [key: string]: any;
}
