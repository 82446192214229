<div class="error" *ngIf="errors['required']">{{ 'validationMessages.required' | translate }}</div>
<div class="error" *ngIf="errors['minlength']">
  {{ 'validationMessages.minlength' | translate : { minValue: errors['minlength']['requiredLength'] } }}
</div>
<div class="error" *ngIf="errors['maxlength']">
  {{ 'validationMessages.maxlength' | translate : { maxValue: errors['maxlength']['requiredLength'] } }}
</div>
<div class="error" *ngIf="errors['lowerAndUpperCase']">{{ 'validationMessages.lowerAndUpperCase' | translate }}</div>
<div class="error" *ngIf="errors['numberAndLetter']">{{ 'validationMessages.numberAndLetter' | translate }}</div>
<div class="error" *ngIf="errors['matchPasswords']">{{ 'validationMessages.matchPasswords' | translate }}</div>
<div class="error" *ngIf="errors['dateInvalid']">{{ 'validationMessages.dateInvalid' | translate }}</div>
<div class="error" *ngIf="errors['email']">{{ 'validationMessages.email' | translate }}</div>
<div class="error" *ngIf="errors['validationError']">{{ errors['validationError'] }}</div>
<ng-container *ngIf="errorDictionary">
  <ng-container *ngFor="let error of errorsList; trackBy: trackByIndex">
    <div class="error" *ngIf="errorDictionary[error] as errorDescription">
      {{ errorDescription | translate : errorParams && errorParams[error] }}
    </div>
  </ng-container>
</ng-container>