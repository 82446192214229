import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { equals, pluck, trackById } from '@pu/utils';
import { AnyEntity, StrNum } from '@pu/models';
import { delay, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AbstractInputComponent } from '../abstract-input.component';

@UntilDestroy()
@Component({
  selector: 'pu-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent extends AbstractInputComponent<StrNum[]> {
  /**
   * Tooltip text of input
   */
  @Input() tooltip: string;

  /**
   * Label of input and HostBinding of class
   */
  @HostBinding('class.pu-multi-select_labelled')
  @Input()
  override label: string;

  /**
   * Value field
   */
  @Input() valueField = 'id';

  /**
   * Name field
   */
  @Input() nameField = 'name';

  /**
   * Items for dropdown
   */
  @Input()
  items: AnyEntity[];

  /**
   * Select opened
   * @internal
   */
  @Output() selectOpened = new EventEmitter<void>();

  /**
   * Items map for dropdown
   * @internal
   */
  /**
   * Item template
   * @internal
   */
  @ContentChild('itemTpl') itemTpl: TemplateRef<any>;

  trackById = trackById;

  selected: AnyEntity[] = [];
  selectedIndex: Record<StrNum, AnyEntity> = {};
  setValueSubject = new Subject<void>();

  override ngOnInit() {
    super.ngOnInit();

    this.setValueSubject
      .asObservable()
      .pipe(delay(300), untilDestroyed(this))
      .subscribe({
        next: value => {
          this.control.setValue(pluck(this.selected, this.valueField));
          this.control.markAsDirty();
          this._cd.detectChanges();
        },
      });
  }

  /**
   * @internal
   * @param event
   * @param item
   */
  toggleItem(event: Event, item: AnyEntity): void {
    event.stopPropagation();
    const id = <StrNum>item[this.valueField];
    const selected = this.selectedIndex[id];
    const index = this.selected.findIndex(entity => id === entity[this.valueField]);
    this.selectedIndex[id] = selected ? null : item;

    if (selected) {
      if (index >= 0) {
        this.selected.splice(index, 1);
      }
    } else if (index === -1) {
      this.selected.push(item);
    }

    this.setValueSubject.next();
  }

  override writeValue(value: StrNum[]): void {
    value = value || [];
    if (!equals(value, pluck(this.selected, this.valueField))) {
      this.selected = [];
      this.selectedIndex = {};

      value.forEach(id => {
        const item = this.items.find(item => id === item[this.valueField]);

        if (item) {
          this.selected.push(item);
          this.selectedIndex[id] = item;
        } else {
          throw new Error(`MultiSelectComponent: Selected item with id ${id} was not found`);
        }
      });
    }
  }
}
