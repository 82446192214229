<div class="pu-control__content">
  <pu-checkbox
    class="pu-sum-with-option-input__checkbox"
    controlId="freebet"
    [formControl]="sumWithOptionFormGroup.controls.isOptionEnabled"
    [label]="optionLabel"
    [isReadonly]="isReadonly"
  ></pu-checkbox>

  <input
    class="pu-control__input pu-control__input_sum pu-control__input_right"
    puInputNumber
    [type]="controlTypes.Text"
    [id]="controlId"
    [class.pu-control__input_invalid]="sumWithOptionFormGroup.controls.sum.touched && sumWithOptionFormGroup.controls.sum.invalid"
    [readonly]="isReadonly"
    [placeholder]="placeholder"
    (focus)="setIsFocused(true)"
    (blur)="setIsFocused(false)"
    [formControl]="sumWithOptionFormGroup.controls.sum"
    [attr.data-testid]="controlTestId"
  />
  <label
    *ngIf="label"
    [for]="controlId"
    class="pu-control__label pu-control__label_active"
    [ngClass]="{
      'pu-control__label_invalid': sumWithOptionFormGroup.controls.sum.touched && sumWithOptionFormGroup.controls.sum.invalid,
      'pu-control__label_small': labelSize === 's'
    }"
    >{{ label }}</label
  >
  <pu-floating-label-outline
    [hasGap]="!!label"
    [label]="label"
    [labelSize]="labelSize"
    [isHighlighted]="isFocused"
    [isInvalid]="sumWithOptionFormGroup.controls.sum.touched && sumWithOptionFormGroup.controls.sum.invalid"
    [isReadOnly]="isReadonly"
  ></pu-floating-label-outline>
  <span class="pu-control__currency pu-control__currency_right">{{ currencyLabel }}</span>
  <span
    *ngIf="hint && !isReadonly"
    class="pu-control__hint"
    [class.pu-control__hint_invalid]="sumWithOptionFormGroup.controls.sum.touched && sumWithOptionFormGroup.controls.sum.invalid"
  >
    {{ hint }}
  </span>
</div>
<pu-validation-message
  *ngIf="sumWithOptionFormGroup.controls.sum.touched && sumWithOptionFormGroup.controls.sum.invalid && showValidationMessage"
  [errors]="sumWithOptionFormGroup.controls.sum.errors$ | async"
  [errorDictionary]="errorDictionary"
  [errorParams]="errorParams"
></pu-validation-message>
