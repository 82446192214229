import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractInputComponent } from '../abstract-input.component';
import { RadioOptionModel } from '../../models/radio-option.model';

@Component({
  selector: 'pu-radio-option',
  templateUrl: './radio-option.component.html',
  styleUrls: ['./radio-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioOptionComponent),
      multi: true,
    },
  ],
})
export class RadioOptionComponent extends AbstractInputComponent<string> {
  @Input() option: RadioOptionModel;
  @Input() isHtmlLabel = false;
}
