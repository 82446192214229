import { Directive, TemplateRef } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: 'ng-template[puExpansionPanelContent]',
})
export class ExpansionPanelContentDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
