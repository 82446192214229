import { createAction, props } from '@ngrx/store';
import { AccessData, Enable2FAData, PersonalInfo, SigninWithCodeData } from '@pu/models';

import { createAsyncAction, createAsyncActionNoProps } from '../helpers';

export const authFeatureKey = 'Auth';

const signIn = createAsyncAction<{ token: string }, SigninWithCodeData>(`[${authFeatureKey}] Signin`);
const enable2fa = createAsyncAction<{ token: string }, Enable2FAData>(`[${authFeatureKey}] Enable 2FA`);
const remove2fa = createAsyncActionNoProps(`[${authFeatureKey}] Remove 2FA`);
const verifyCode = createAsyncAction<{ token: string; code: string }, AccessData>(`[${authFeatureKey}] Verify code`);
const resetCode = createAsyncAction<{ token: string; code: string }, AccessData>(`[${authFeatureKey}] Reset code`);
const setProfile = createAction(`[${authFeatureKey}] Set profile`, props<{ profile: PersonalInfo }>());
const updateAccessData = createAction(
  `[${authFeatureKey}] Update access data`,
  props<{ access?: string; refresh?: string; permissions?: string[] }>(),
);
const navigateAfter = createAction(`[${authFeatureKey}] Navigate after`);
const logout = createAction(`[${authFeatureKey}] Logout`);

export const AuthActions = {
  setProfile,
  signIn,
  enable2fa,
  remove2fa,
  verifyCode,
  resetCode,
  updateAccessData,
  navigateAfter,
  logout,
};
