<a class="nav-item"
   *ngIf="!!item.link; else navButton"
   [routerLink]="item.link"
   [routerLinkActiveOptions]="{ exact: item.isRoot }"
   routerLinkActive="nav-item_active"
>
  <ui-icon *ngIf="item.icon" [iconName]="item.icon" class="nav-item__icon md:ui-icon_l"></ui-icon>
  {{ item.label | translate }}
</a>

<ng-template #navButton>
  <button
    class="nav-item__btn"
    type="button"
    (click)="clicked.emit($event)">
    <ui-icon [iconName]="item.icon" class="nav-item__icon md:ui-icon_l"></ui-icon>
    {{ item.label | translate }}
  </button>
</ng-template>

<ng-content></ng-content>
