import { FroalaEnter } from './enums/froala-enter.enum';
import { FroalaButton, FroalaMoreButton } from './froala-button.type';

/*
 * All available options and description to them, you can find by the link below:
 * https://froala.com/wysiwyg-editor/docs/options
 */

export type FroalaToolbarButtons = {
  [key in FroalaMoreButton]: {
    buttons?: FroalaButton[];
    align?: string;
    buttonsVisible?: number;
  };
};

export interface FroalaConfig {
  toolbarSticky?: boolean;
  quickInsertEnabled?: boolean;
  attribution?: boolean; // hide a watermark
  charCounterMax?: number;
  placeholderText?: string;
  emoticonsUseImage?: boolean;
  listAdvancedTypes?: boolean;
  events?: Record<string, (k?: any) => void>;
  theme?: string;
  enter?: FroalaEnter;
  imageCORSProxy?: string;
  imageUploadURL?: string;
  imageInsertButtons?: string[];
  videoInsertButtons?: string[];
  quickInsertTags?: string[];
  pasteDeniedAttrs?: string[];
  toolbarButtons?: FroalaToolbarButtons | FroalaButton[];
  toolbarButtonsMD?: FroalaToolbarButtons | FroalaButton[];
  toolbarButtonsSM?: FroalaToolbarButtons | FroalaButton[];
  toolbarButtonsXS?: FroalaToolbarButtons | FroalaButton[];
  pasteAllowedStyleProps?: string[];
  pastePlain?: boolean;
}
