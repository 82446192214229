import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AUTH_TOKEN_KEY } from '@pu/constants';

/**
 * Auth token interceptor
 */
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this._addAuthToken(request));
  }

  /**
   * Add auth token to request if token is present
   * @param request request
   */
  private _addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
    const signinReq = request.url.indexOf('signin') >= 0 && request.method !== 'DELETE';
    const healthCheckReq = request.url.indexOf('health-check') >= 0;
    const assetsI18nReq = request.url.indexOf('assets/i18n') >= 0;

    return !signinReq && !assetsI18nReq && !healthCheckReq && authToken
      ? request.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } })
      : request;
  }
}
