import { DictionaryItem } from './dictionary.model';

export type DeliveryOffice = 'delivery' | 'office';

export type Delivery =
  | DeliveryPickupForm
  | DeliveryInternationalForm
  | DeliveryUkrposhtaForm
  | DeliveryNovaPoshtaForm
  | DeliveryFedexForm
  | DeliveryDhlForm
  | DeliveryDpdForm
  | DeliveryInpostForm
  | DeliveryAcsForm
  | DeliveryGapAkisExpressForm;

export interface DeliveryMethod extends DictionaryItem<string> {
  locationId?: number; // LocationModel
}

export interface DeliveryPickupForm {
  location: string;
  address: string;
}

export interface DeliveryInternationalForm {
  country: string;
  postcode: number;
  regionState: string;
  city: string;
  postOffice: string;
  address: string;
  apartment: string;
  email: string;
}

export interface DeliveryUkrposhtaForm {
  country: string;
  postcode: number;
  regionState: string;
  city: string;
  address: string;
  apartment: string;
}

export interface DeliveryNovaPoshtaForm {
  country: string;
  city: string;
  postOffice: string;
  postTerminal: string;
}

export interface DeliveryFedexForm {
  country: string;
  postcode: number;
  regionState: string;
  city: string;
  address: string;
  apartment: string;
  email: string;
}

export interface DeliveryDhlForm {
  country: string;
  postcode: number;
  regionState: string;
  city: string;
  address: string;
  email: string;
}

export interface DeliveryDpdForm {
  country: string;
  postcode: number;
  city: string;
  address: string;
  email: string;
}

export interface DeliveryInpostForm {
  country: string;
  city: string;
  postOffice: string;
  postTerminal: string;
  email: string;
}

export interface DeliveryAcsForm {
  country: string;
  area: string;
  city: string;
  postOffice: string;
}

export interface DeliveryGapAkisExpressForm {
  country: string;
  postcode: number;
  city: string;
  address: string;
  email: string;
}
