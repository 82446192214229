import { AsyncActionState } from '@pu/store';

import { GetTodayBirthdaysRes } from '../models';

export const birthdaysWidgetFeatureKey = 'Birthdays Widget';

export interface BirthdaysWidgetState {
  todayBirthdays: GetTodayBirthdaysRes['todayBirthdays'];
  getTodayBirthdaysAction: AsyncActionState<GetTodayBirthdaysRes>;
}
