import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { trackById } from '@pu/utils';
import { Store } from '@ngrx/store';

import { BirthdaysWidgetSelectors } from './store';

@Component({
  selector: 'pu-birthdays-widget, [puBirthdaysWidget]',
  templateUrl: './birthdays-widget.component.html',
  styleUrls: ['./birthdays-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BirthdaysWidgetComponent {
  @HostBinding('class.pu-birthdays_vertical')
  @Input()
  isVertical = false;

  @Input() maxBirthdays = 3;

  trackById = trackById;

  vm$ = this._store.select(BirthdaysWidgetSelectors.selectViewModel);
  constructor(private _store: Store) {}
}
