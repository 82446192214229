import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, IconModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownModule } from '../../components/dropdown';
import { ControlTooltipComponentModule } from '../../components/control-tooltip/control-tooltip.module';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { SearchSelectComponent } from './search-select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    ButtonModule,
    DropdownModule,
    ReactiveFormsModule,
    ControlTooltipComponentModule,
    FloatingLabelOutlineComponentModule,
  ],
  declarations: [SearchSelectComponent],
  exports: [SearchSelectComponent],
})
export class SearchSelectModule {}
