import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@pu/ui';

import { ControlTooltipComponentModule } from '../../components/control-tooltip/control-tooltip.module';
import { FloatingLabelOutlineComponentModule } from '../../components/floating-label-outline/floating-label-outline.module';
import { DropdownModule } from '../../components/dropdown';
import { MultiSelectComponent } from './multi-select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IconModule,
    DropdownModule,
    ReactiveFormsModule,
    ControlTooltipComponentModule,
    FloatingLabelOutlineComponentModule,
  ],
  declarations: [MultiSelectComponent],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
