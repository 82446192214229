import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { delay, map, Observable, of, switchMap } from 'rxjs';
import { AuthActions, RootState, createAsyncActionEffect, noopAction } from '@pu/store';
import { environment } from '@pu/environment';
import { Store } from '@ngrx/store';
import { profileMock } from '@pu/services';

import { BalanceActions, BalanceSelectors } from '.';
import { ApplyBalanceSkinReq, GetBalanceRes, GetBalanceSkinsRes, GetTagsRes } from '../models';
import { BalanceSkinFormDialog } from '../components/balance-skin-form/balance-skin-form.dialog';
import { getBalanceSkinsMock } from '../mocks';
import { getTagsMock } from '../../../pages/news/mocks';

@Injectable()
export class BalanceEffects {
  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BalanceActions.init),
      switchMap(() => [BalanceActions.getBalanceReq.action(), BalanceActions.getTagsReq.action()]),
    ),
  );

  getBalanceReq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BalanceActions.getBalanceReq.action),
      switchMap(() => createAsyncActionEffect(this.getBalance(), BalanceActions.getBalanceReq)),
    ),
  );

  getBalanceSkinsReq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BalanceActions.getBalanceSkinsReq.action),
      switchMap(() => createAsyncActionEffect(this.getBalanceSkins(), BalanceActions.getBalanceSkinsReq)),
    ),
  );

  getTagsReq$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BalanceActions.getTagsReq.action),
      switchMap(action => createAsyncActionEffect(this.getTags(), BalanceActions.getTagsReq)),
    ),
  );

  openBalanceSkinsDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BalanceActions.getBalanceSkinsReq.succeededAction),
      concatLatestFrom(() => this._store.select(BalanceSelectors.selectViewModel)),
      switchMap(([{ payload }, vm]) => {
        const dialogRef = this._balanceSkinsDialog.open(payload.balanceSkins, vm.balance, vm.skin);

        return dialogRef.afterClosed().pipe(
          map(result => {
            return result ? BalanceActions.applyBalanceSkinReq.action({ payload: <ApplyBalanceSkinReq>result }) : noopAction();
          }),
        );
      }),
    ),
  );

  applyBalanceSkin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BalanceActions.applyBalanceSkinReq.action),
      switchMap(({ payload }) => createAsyncActionEffect(this.applyBalanceSkin(payload), BalanceActions.applyBalanceSkinReq)),
    ),
  );

  updateProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BalanceActions.applyBalanceSkinReq.succeededAction),
      map(({ payload }) => AuthActions.setProfile({ profile: payload })),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _http: HttpClient,
    private _store: Store<RootState>,
    private _balanceSkinsDialog: BalanceSkinFormDialog,
  ) {}

  getBalance(): Observable<GetBalanceRes> {
    if (environment.useMocks) {
      return of({ balance: 9999 });
    } else {
      return this._http.get<GetBalanceRes>(environment.apiHost + 'banking/balance/');
    }
  }

  getBalanceSkins(): Observable<GetBalanceSkinsRes> {
    if (environment.useMocks) {
      const balanceSkins = getBalanceSkinsMock();

      return of({ balanceSkins }).pipe(delay(500));
    } else {
      return this._http.get<GetBalanceSkinsRes>(environment.apiHost + 'structure/balance-skins/');
    }
  }

  applyBalanceSkin(params: ApplyBalanceSkinReq) {
    if (environment.useMocks) {
      return of(profileMock).pipe(delay(500));
    }

    return this._http.patch(environment.apiHost + `user/personal-info/`, params);
  }

  getTags(): Observable<GetTagsRes> {
    if (environment.useMocks) {
      const tags = getTagsMock();

      return of({ itemsCount: tags.length, tags }).pipe(delay(500));
    } else {
      return this._http.get<GetTagsRes>(environment.apiHost + `news/tags/`, { params: { articleType: 'faq' } });
    }
  }
}
