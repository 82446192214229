import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, switchMap, tap, throwError } from 'rxjs';
import { AuthActions, RootState } from '@pu/store';
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@pu/constants';
import { Store } from '@ngrx/store';
import { AuthService, TimeDeltaService } from '@pu/services';
import { environment } from '@pu/environment';
import { AccessData } from '@pu/models';

/**
 * Refresh token interceptor
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  /**
   * Extra time to be sure that request won't go to the server where token is already expired
   * @private
   */
  private _extraTime = 10000;

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
    private _store: Store<RootState>,
    private _timedeltaSubject: TimeDeltaService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const signinReq = request.url.indexOf('auth') >= 0;
    const assetsI18nReq = request.url.indexOf('assets/i18n') >= 0;

    if (environment.useMocks || signinReq || assetsI18nReq) {
      return next.handle(request);
    } else {
      const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
      const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);

      if (authToken && refreshToken) {
        const now = new Date().getTime();
        let authData;
        let refreshData;

        try {
          authData = this._auth.decodeJwt(authToken);
          refreshData = this._auth.decodeJwt(refreshToken);
        } catch (e) {
          this._store.dispatch(AuthActions.logout());

          return throwError(() => new Error('Auth or refresh token is incorrect'));
        }

        if ((<any>refreshData).exp * 1000 < now + this._timedeltaSubject.value + this._extraTime) {
          this._store.dispatch(AuthActions.logout());

          return throwError(() => new Error('Refresh token is expired'));
        } else if ((<any>authData).exp * 1000 < now + this._timedeltaSubject.value + this._extraTime) {
          return this._http
            .post<Pick<AccessData, 'access' | 'permissions'>>(environment.apiHost + `auth/refresh/`, {
              refresh: refreshToken,
            })
            .pipe(
              tap(response => this._store.dispatch(AuthActions.updateAccessData({ ...response }))),
              switchMap(() => next.handle(request)),
            );
        } else {
          return next.handle(request);
        }
      } else {
        return next.handle(request);
      }
    }
  }
}
