<div class="ui-gallery__preview">
  <img class="ui-gallery__preview-image"
       *ngFor="let img of images; let index = index; trackBy: trackById"
       [src]="img.url"
       [alt]="img.name"
       (click)="open(index)"
  />
</div>

<div class="ui-gallery__window"
     [ngClass]="{ 'ui-gallery__window_opened': opened }">

  <img class="ui-gallery__window-image"
       [src]="currentImage.url"
       [alt]="currentImage.name"
       (click)="right()"
  />

  <button
    class="ui-gallery__window-btn-left"
    type="button"
    uiButton
    size="m"
    uiType="tertiary"
    [onlyIcon]="true"
    (click)="left()">
    <ui-icon iconName="chevron-left" size="24"></ui-icon>
  </button>

  <button
    class="ui-gallery__window-btn-right"
    type="button"
    uiButton
    size="m"
    uiType="tertiary"
    [onlyIcon]="true"
    (click)="right()">
    <ui-icon iconName="chevron-1" size="24"></ui-icon>
  </button>

  <button
    class="ui-gallery__window-btn-close"
    type="button"
    uiButton
    size="m"
    uiType="tertiary"
    [onlyIcon]="true"
    (click)="close()">
    <ui-icon iconName="close-2" size="24"></ui-icon>
  </button>
</div>
