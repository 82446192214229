import { createAction } from '@ngrx/store';
import { createAsyncActionNoProps } from '@pu/store';

import { birthdaysWidgetFeatureKey } from './index';
import { GetTodayBirthdaysRes } from '../models';

const init = createAction(`[${birthdaysWidgetFeatureKey}]: Init`);
const getTodayBirthdays = createAsyncActionNoProps<GetTodayBirthdaysRes>(`[${birthdaysWidgetFeatureKey}]: Get today birthdays`);

export const BirthdaysWidgetActions = {
  init,
  getTodayBirthdays,
};
