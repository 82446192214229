import { SupportTip } from '@pu/models';
import { fillArr } from '@pu/utils';

export function getTipsMock(count = 12): SupportTip[] {
  return fillArr(count, i => ({
    id: i,
    question: `Question ${i}`,
    answer: `Answer ${i}`,
  }));
}
