import { InjectionToken } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';

export interface ToastConfig {
  component: ComponentType<unknown>;
  position?: {
    top: number;
    right: number;
  };
  timeout?: number;
}

export const TOAST_CONFIG_TOKEN = new InjectionToken('Toast Config');
