<div class="pu-header__top" *ngIf="!breakpoints.isDesktopMode">
  <ui-logo></ui-logo>
  <pu-balance [isCard]="true"
              [isTablet]="breakpoints.isTabletMode"></pu-balance>
</div>

<div class="pu-header__bottom" *ngIf="breakpoints.isTabletMode">
  <pu-global-search-input></pu-global-search-input>
   <a
     class="pu-header__birthdays"
     routerLink="/birthdays"
     puBirthdaysWidget>&nbsp;</a>
  <pu-header-controls></pu-header-controls>
</div>
