import { Injector } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export function registerLocaleFactory(injector: Injector) {
  const service = injector.get(TranslateService);

  return () =>
    new Observable(observer => {
      // It creates lazy chunks with given locales
      // import(
      //   /* webpackInclude: /node_modules\/@angular\/common\/locales\/(en|uk|ru)\.mjs/ */
      //   `node_modules/@angular/common/locales/${service.currentLang || 'en'}.mjs`
      // ).then(module => {
      //   registerLocaleData(module.default);
      //
      //   observer.next();
      //   observer.complete();
      // });
      observer.next();
      observer.complete();
    });
}
