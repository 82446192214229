import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ui-category-tab, [uiCategoryTab]',
  templateUrl: './category-tab.component.html',
  styleUrls: ['./category-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTabComponent {
  /**
   * Name
   */
  @Input() label: string;

  /**
   * Img
   */
  @Input() img: string;

  /**
   * Initial image, optional, if exists use this images as initial (not active, not hovered) instead of putting on css filter on img above
   */
  @HostBinding('class.ui-category-tab_has-initial-img')
  @Input()
  initialImg: string;

  /**
   * Icon name
   */
  @Input() iconName: string;

  /**
   * Count label
   */
  @Input() countLabel: number;

  /**
   * Is active
   */
  @HostBinding('class.ui-category-tab_active')
  @Input()
  isActive: boolean;

  /**
   * Is text only
   */
  @HostBinding('class.ui-category-tab_text')
  @Input()
  isTextOnly = false;
}
