<div class="select-dropdown-list__item"
     *ngFor="let item of items; trackBy: trackById"
     [class.select-dropdown-list__item_active]="item[valueField] === selectedValue"
     [class.select-dropdown-list__item_disabled]="disabledIndex[item[valueField]]"
     (click)="select($event, item)">

  <ui-icon
    class="select-dropdown-list__item-icon"
    *ngIf="item.icon"
    [iconName]="item.icon">
  </ui-icon>

  <img class="select-dropdown-list__item-img"
       loading="lazy"
       *ngIf="item.img"
       [src]="item.img"
       [alt]="item[nameField] | translate"
  />

  <span class="select-dropdown-list__item-label">
    {{ item[nameField] | translate }}
  </span>
</div>
