import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pu/services';
import { SupportTip } from '@pu/models';

import { TipsComponent } from './tips.component';

@Injectable()
export class TipsDialog {
  constructor(private _dialog: DialogService) {}

  open(tips: SupportTip[]): DialogRef {
    return this._dialog.open(TipsComponent, { tips });
  }
}
