<div class="pu-pwa-setup__header">
  <h4
    class="pu-pwa-setup__header-text"
    translate="pwaSetupDialog.header">
  </h4>

  <button
    class="pu-pwa-setup__close"
    type="button"
    uiButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()">
    <ui-icon
      iconName="close"
      size="16">
    </ui-icon>
  </button>
</div>

<div class="pu-pwa-setup__body">
  <ng-container *ngIf="isIOS">
    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.ios.setupStep1' | translate }}
      <ui-icon
        class="pu-pwa-setup__text-icon"
        iconName="share"
        size="16">
      </ui-icon>
    </p>

    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.ios.setupStep2' | translate }}
      <ui-icon
        class="pu-pwa-setup__text-icon"
        iconName="filled-plus"
        size="16">
      </ui-icon>
    </p>
  </ng-container>

  <ng-container *ngIf="!isIOS">
    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.setupStep1' | translate }}
      <ui-icon
        class="pu-pwa-setup__text-icon"
        iconName="dots"
        size="16">
      </ui-icon>
    </p>

    <p class="pu-pwa-setup__text">
      {{ 'pwaSetupDialog.setupStep2' | translate }}
      <ui-icon
        class="pu-pwa-setup__text-icon"
        iconName="app"
        size="16">
      </ui-icon>
    </p>
  </ng-container>

  <p
    class="pu-pwa-setup__text"
    translate="pwaSetupDialog.setupStep3">
  </p>
</div>
