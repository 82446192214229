import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileCardComponent } from './user-profile-card.component';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
  declarations: [UserProfileCardComponent],
  imports: [CommonModule, AvatarModule],
  exports: [UserProfileCardComponent],
})
export class UserProfileCardModule {}
