import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LetModule } from '@pu/directives';

import { CartCounterComponent } from './cart-counter.component';
import { CartCounterEffects, cartCounterFeature } from './store';

@NgModule({
  imports: [CommonModule, LetModule, StoreModule.forFeature(cartCounterFeature), EffectsModule.forFeature([CartCounterEffects])],
  declarations: [CartCounterComponent],
  exports: [CartCounterComponent],
})
export class CartCounterModule {}
