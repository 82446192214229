<input
  class="pu-control__input pu-select__input"
  readonly
  #inputReference
  #dropdownRef="puDropdownTrigger"
  [puDropdownTriggerFor]="selectDropdown"
  [reference]="inputReference"
  [id]="controlId"
  [ngClass]="{
    'pu-control__input_invalid': control.dirty && control.invalid,
    'pu-control__input_padding': !!tooltip,
    'pu-select__input_focus': dropdownRef.isDropdownOpened$ | async,
    'pu-select__input_readonly': isReadonly
  }"
  [placeholder]="placeholder"
  [formControl]="control"
  (opened)="selectOpened.emit()"
  [attr.data-testid]="controlTestId"
/>

<ng-container *ngIf="label">
  <label
    [for]="controlId"
    class="pu-control__label pu-control__label_active"
    [ngClass]="{
      'pu-control__label_invalid': control.dirty && control.invalid,
      'pu-control__label_small': labelSize === 's'
    }">
    {{ label }}
  </label>

  <pu-floating-label-outline
    [hasGap]="true"
    [label]="label"
    [labelSize]="labelSize"
    [isHighlighted]="dropdownRef.isDropdownOpened$ | async"
    [isInvalid]="control.dirty && control.invalid"
    [isReadOnly]="isReadonly">
  </pu-floating-label-outline>
</ng-container>

<ui-icon
  class="pu-select__chevron"
  iconName="chevron-1"
  size="20"
  *ngIf="!isReadonly"
  [ngClass]="{ 'pu-select__chevron_active': dropdownRef.isDropdownOpened$ | async, 'pu-select__chevron_tooltip': !!tooltip }">
</ui-icon>

<pu-control-tooltip
  class="pu-control__icon"
  *ngIf="tooltip"
  [tooltip]="tooltip"
  [reference]="inputReference">
</pu-control-tooltip>

<div class="pu-select__item"
     *ngIf="itemsRecord[inputReference.value === '' ? null : inputReference.value] as selectedItem">

  <ui-icon
    class="pu-select__item-icon"
    *ngIf="selectedItem.icon"
    [iconName]="selectedItem.icon"
    size="20">
  </ui-icon>

  <img class="pu-select__item-img"
       *ngIf="selectedItem.img"
       [src]="selectedItem.img"
       [alt]="selectedItem[nameField] | translate"
  />

  <span class="pu-select__item-label">
    {{ selectedItem[nameField] | translate }}
  </span>
</div>

<span class="pu-control__hint"
      *ngIf="hint && !isReadonly"
      [class.pu-control__hint_invalid]="control.dirty && control.invalid">
  {{ hint }}
</span>

<pu-dropdown #selectDropdown>
  <pu-select-dropdown-list
    [valueField]="valueField"
    [nameField]="nameField"
    [itemList]="itemList"
    [isDisabledItem]="isDisabledItem"
    [selectedValue]="control.value === '' ? null : control.value"
    (itemSelected)="onItemSelected($event)">
  </pu-select-dropdown-list>
</pu-dropdown>
