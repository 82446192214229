import { createAction } from '@ngrx/store';
import { createAsyncAction, createAsyncActionNoProps } from '@pu/store';

import { notificationsFeatureKey } from './';
import { GetNotificationsCountRes, GetNotificationsRes, PatchNotificationsReq, PatchNotificationsRes } from '../models';

const initCount = createAction(`[${notificationsFeatureKey}]: Init count`);
const initNotifications = createAction(`[${notificationsFeatureKey}]: Init notifications`);
const getNotificationsReq = createAsyncActionNoProps<GetNotificationsRes>(`[${notificationsFeatureKey}]: Get notifications`);
const getNotificationsCountReq = createAsyncActionNoProps<GetNotificationsCountRes>(
  `[${notificationsFeatureKey}]: Get notifications count`,
);
const readAllReq = createAsyncActionNoProps<PatchNotificationsRes>(`[${notificationsFeatureKey}]: Read all notifications`);
const readOneReq = createAsyncAction<PatchNotificationsReq, PatchNotificationsRes>(`[${notificationsFeatureKey}]: Read one notification`);
const open = createAction(`[${notificationsFeatureKey}]: Open`);
const close = createAction(`[${notificationsFeatureKey}]: Close`);
const toggle = createAction(`[${notificationsFeatureKey}]: Toggle`);

export const NotificationsActions = {
  initCount,
  initNotifications,
  getNotificationsReq,
  getNotificationsCountReq,
  readAllReq,
  readOneReq,
  open,
  close,
  toggle,
};
