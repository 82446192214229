import { AsyncActionState } from '@pu/store';

import { GetQuantityRes } from '../models';

export const cartCounterFeatureKey = 'Cart counter';

export interface CartCounterState {
  quantity: number;
  getQuantityAction: AsyncActionState<GetQuantityRes>;
}
