import { Entity } from './entity.model';

export interface LocationModel extends Entity {
  name: string;
}

export interface LocationDetails extends LocationModel {
  city: string;
  country: string;
  address: string;
}

export interface LocationAdmin extends LocationDetails {
  visible: boolean;
}

export interface LocationForm extends LocationAdmin {
  deliveryIds: string[];
}
