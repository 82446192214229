import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pu/ui';

import { OkComponent } from './ok.component';

@NgModule({
  declarations: [OkComponent],
  imports: [CommonModule, IconModule, TranslateModule, ButtonModule],
  exports: [OkComponent],
})
export class OkModule {}
