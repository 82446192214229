import { ChangeDetectionStrategy, Component, HostListener, inject, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { trackById } from '@pu/utils';
import { ClickOutsideDirective } from '@pu/directives';
import { Observable } from 'rxjs';
import { Notification } from '@pu/models';

import { NotificationsActions, NotificationsSelectors } from './store';

@Component({
  selector: 'pu-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: ClickOutsideDirective,
      // bug with eslint
      // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property
      outputs: ['clickOutside'],
    },
  ],
})
export class NotificationsComponent implements OnInit {
  private _store = inject(Store);
  notifications$: Observable<Notification[]> = this._store.select(NotificationsSelectors.selectNotifications);

  protected readonly trackById = trackById;

  ngOnInit() {
    this._store.dispatch(NotificationsActions.initNotifications());
  }

  @HostListener('clickOutside') clickedOutside() {
    this._store.dispatch(NotificationsActions.close());
  }

  readAll() {
    this._store.dispatch(NotificationsActions.readAllReq.action());
  }

  readOne(id: number) {
    this._store.dispatch(NotificationsActions.readOneReq.action({ payload: { id } }));
    this._store.dispatch(NotificationsActions.close());
  }
}
