import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pu/ui';
import { DialogRef, UaParserService } from '@pu/services';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, IconModule],
  selector: 'pu-pwa-setup',
  templateUrl: './pwa-setup.component.html',
  styleUrls: ['./pwa-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwaSetupComponent {
  isIOS = this._uaParser.isIOS;

  constructor(private _dialogRef: DialogRef, private _uaParser: UaParserService) {}

  close(): void {
    this._dialogRef.close();
  }
}
