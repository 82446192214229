import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

import { AbstractInputComponent } from '../abstract-input.component';
import { SumWithOptionControl } from '../../models/sum-with-option-control.model';

@UntilDestroy()
@Component({
  selector: 'pu-sum-with-option-input',
  templateUrl: 'sum-with-option-input.component.html',
  styleUrls: ['sum-with-option-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SumWithOptionInputComponent),
      multi: true,
    },
  ],
})
export class SumWithOptionInputComponent extends AbstractInputComponent<SumWithOptionControl> implements OnInit {
  /**
   * Currency label
   */
  @Input() currencyLabel = '';
  /**
   * Checkbox option label
   */
  @Input() optionLabel = '';

  /**
   * @internal
   */
  sumWithOptionFormGroup = new FormGroup({
    sum: new FormControl<number>(null),
    isOptionEnabled: new FormControl<boolean>(null),
  });

  /**
   * @internal
   */
  // eslint-disable-next-line
  onChange = (value: SumWithOptionControl) => {};

  override ngOnInit() {
    super.ngOnInit();

    this.control.disabled$.pipe(untilDestroyed(this)).subscribe(isDisabled => this.sumWithOptionFormGroup.setDisable(isDisabled));

    if (this.control.hasValidator(Validators.required)) {
      this.sumWithOptionFormGroup.controls.sum.setValidators(Validators.required);
    }

    this.sumWithOptionFormGroup.valueChanges
      .pipe(
        filter(() => !this.isReadonly),
        untilDestroyed(this),
      )
      .subscribe((value: SumWithOptionControl) => {
        this.onChange(value);
      });
  }

  /**
   * @internal
   * @param value
   */
  override writeValue(value: SumWithOptionControl) {
    this._setSumWithOptionValue(value);
  }

  /**
   * Register a function that will be called when the control is touched
   * @internal
   */
  override registerOnChange(onChange: (value: SumWithOptionControl) => void): void {
    this.onChange = onChange;
  }

  private _setSumWithOptionValue(value: SumWithOptionControl) {
    this.sumWithOptionFormGroup.setValue(value, { emitEvent: false });
  }
}
