import { UserProfile } from '@pu/models';

export const getUsersMock = (count = 12): UserProfile[] => {
  const usersMock: UserProfile[] = [];

  for (let i = 1; i <= count; i++) {
    usersMock.push({
      id: i,
      fullName: `Aleksander ${i}`,
      imageUrl: `/assets/images/user-avatar-mock.jpg`,
      position: 'Angular developer',
      email: `a.${i}@pin-up.business`,
    });
  }

  return usersMock;
};
