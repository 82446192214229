import { Pipe, PipeTransform } from '@angular/core';

import { DonutSlice } from './donut-chart.model';

interface DonutSliceWithCommands extends DonutSlice {
  offset: number;
  commands: string;
}

@Pipe({ name: 'puDonutChartSlices', pure: true })
export class DonutChartPipe implements PipeTransform {
  transform(slices: DonutSlice[], radius: number, svgSize: number, borderSize: number, gap: number): DonutSliceWithCommands[] {
    let previousPercent = 0;

    return slices.map(slice => {
      const sliceWithCommands: DonutSliceWithCommands = {
        ...slice,
        commands: this._getSliceCommands(slice, radius, svgSize, borderSize, gap),
        offset: previousPercent * 3.6 * -1,
      };
      previousPercent += slice.percent;

      return sliceWithCommands;
    });
  }

  private _getSliceCommands(donutSlice: DonutSlice, radius: number, svgSize: number, borderSize: number, gap: number): string {
    const degrees = this._percentToDegrees(gap > 0 ? donutSlice.percent - gap : donutSlice.percent);
    const longPathFlag = degrees > 180 ? 1 : 0;
    const innerRadius = radius - borderSize;
    const commands: string[] = [];

    commands.push(`M ${svgSize / 2 + radius} ${svgSize / 2}`);
    commands.push(`A ${radius} ${radius} 0 ${longPathFlag} 0 ${this._getCoordFromDegrees(degrees, radius, svgSize)}`);
    if (gap > 0) {
      commands.push(`A 1 1 0 1 0 ${this._getCoordFromDegrees(degrees, innerRadius, svgSize)}`);
    }
    commands.push(`L ${this._getCoordFromDegrees(degrees, innerRadius, svgSize)}`);
    commands.push(`A ${innerRadius} ${innerRadius} 0 ${longPathFlag} 1 ${svgSize / 2 + innerRadius} ${svgSize / 2}`);
    if (gap > 0) {
      commands.push(`A 1 1 0 0 0 ${svgSize} ${radius}`);
    }

    return commands.join(' ');
  }

  private _getCoordFromDegrees(angle: number, radius: number, svgSize: number): string {
    const x = Math.cos((angle * Math.PI) / 180);
    const y = Math.sin((angle * Math.PI) / 180);
    const coordX = x * radius + svgSize / 2;
    const coordY = y * -radius + svgSize / 2;

    return [coordX, coordY].join(' ');
  }

  private _percentToDegrees(percent: number): number {
    return percent * 3.6;
  }
}
