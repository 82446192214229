export const upperCaseValidationPattern = /[A-Z]/;
export const lowerCaseValidationPattern = /[a-z]/;
export const numberValidationPattern = /\d/;
export const letterValidationPattern = /[a-zA-Z]/;
/**
 * RFC standard validator without top-domain checking
 * https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript/1373724#1373724
 */
export const emailValidationPattern =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
/**
 * This regular expression includes all country codes and phone length provided in confluence:
 * https://wiki.time2go.tech/display/PROD/Codes+and+phone+numbers
 */
export const extendedPhoneValidationPattern =
  /^(\+)?((86|82|54|52)\d{11}|(977|964|880|234|98|92|91|90|82|81|63|58|57|55|49|44|43|39|30|21|20|7|1)\d{10}|(998|996|995|994|992|972|971|970|967|966|963|962|886|596|595|594|593|590|423|421|420|383|380|377|375|359|358|355|353|352|351|265|264|263|262|261|260|258|256|255|254|251|250|249|244|242|240|233|221|213|212|94|93|84|77|66|64|62|61|60|56|51|48|46|41|40|36|34|33|32|31|27)\d{9}|(993|976|975|974|973|968|965|961|855|853|852|671|670|599|598|591|509|507|506|505|504|503|502|389|387|386|385|383|381|378|374|373|372|371|370|357|356|350|267|266|257|241|237|236|235|232|229|228|227|226|225|224|223|222|216|65|53|47|45)\d{8}|(7940|1876|1869|1868|1809|1787|1784|1767|1758|1473|1441|1345|1284|1268|1246|1242|960|679|678|677|676|675|674|673|597|501|354|297|269|248|245|238|231|230|220)\d{7}|(689|687|685|376|299|298)\d{6}|(682)\d{5}|(3780549)\d{4})$/;
/**
 * This regular expression includes all country codes provided in confluence:
 * https://wiki.time2go.tech/display/PROD/Codes+and+phone+numbers
 * Pattern checks that value starts with one of all country codes
 */
export const countryPhoneCodesPattern =
  /^(86|82|54|52|977|964|880|234|98|92|91|90|81|63|58|57|55|49|44|43|39|30|21|20|7|1|998|996|995|994|992|972|971|970|967|966|963|962|886|596|595|594|593|590|423|421|420|380|377|375|359|358|355|353|352|351|265|264|263|262|261|260|258|256|255|254|251|250|249|244|242|240|233|221|213|212|94|93|84|77|66|64|62|61|60|56|51|48|46|41|40|36|34|33|32|31|27|993|976|975|974|973|968|965|961|855|853|852|671|670|599|598|591|509|507|506|505|504|503|502|389|387|386|385|383|381|378|374|373|372|371|370|357|356|350|267|266|257|241|237|236|235|232|229|228|227|226|225|224|223|222|216|65|53|47|45|7940|1876|1869|1868|1809|1787|1784|1767|1758|1473|1441|1345|1284|1268|1246|1242|960|679|678|677|676|675|674|673|597|501|354|297|269|248|245|238|231|230|220|689|687|685|376|299|298|682|3780549)/;
