import { ProductGlobalWarehouse } from '@pu/models';

export const getProductsMock = (count = 12): ProductGlobalWarehouse[] => {
  const productsMock: ProductGlobalWarehouse[] = [];

  for (let i = 1; i <= count; i++) {
    productsMock.push({
      id: i,
      name: `Product ${i}`,
      mainImage: {
        id: i,
        url: `/assets/images/mock-product.png`,
        name: `${i}.jpg`,
      },
      optionSizes: [
        { id: 1, size: 'M' },
        { id: 2, size: 'L' },
        { id: 3, size: 'XL' },
      ],
      optionColors: [
        { id: 1, color: 'red', name: 'Red' },
        { id: 2, color: 'green', name: 'Green' },
        { id: 3, color: 'blue', name: 'Blue' },
      ],
    });
  }

  return productsMock;
};
