import { Entity } from './entity.model';
import { LocationModel } from './location.model';
import { User } from './user.model';
import { ImageModel } from './file.model';
import { ProductOrder } from './product.model';
import { Delivery } from '.';

export type OrderStatusType = 'new' | 'confirmed' | 'shipping' | 'delivered' | 'canceled' | 'completed';

export interface OrderHistory extends Entity {
  status: OrderStatusType;
  createAt: Date;
  sendFrom: string;
  comment: string | null;
}

export interface Order extends Entity {
  createAt: Date;
  status: OrderStatusType;
  location: LocationModel;
  totalSum: number;
  productsCount: number;
  productImages: ImageModel[];
}

export interface OrderDetails extends Entity {
  delivery: Delivery;
  deliveryMethod: string;
  firstName?: string;
  lastName?: string;
  comment: string;
  phone?: string;
  products: ProductOrder[];
  orderHistory: OrderHistory[];
  commentsHistory?: OrderHistory[]; // Only Frontend
}

export interface OrderAdmin extends Order {
  user: User;
}

export interface OrderAdminForm extends OrderDetails, Omit<OrderAdmin, 'productImages' | 'productsCount' | 'user'> {
  statuses: OrderStatusType[] | null;
}
