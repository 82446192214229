import { HttpParams } from '@angular/common/http';

import { copy } from './copy';

/**
 * Returns not empty params restricted by keys if they are
 */
export function getParams(params: Record<string, any>, keys?: string[], exclude?: boolean): Record<string, any> {
  const httpParams: Record<string, any> = {};
  const keysIndex: Record<string, boolean> = keys
    ? keys.reduce((acc, key) => {
        acc[key] = true;

        return acc;
      }, <Record<string, boolean>>{})
    : {};

  for (const key in params) {
    if (params.hasOwnProperty(key) && (!keys || (exclude ? !keysIndex[key] : keysIndex[key]))) {
      if (params[key] !== null && params[key] !== '') {
        httpParams[key] = params[key];
      }
    }
  }

  return httpParams;
}

/**
 * Returns not empty query params restricted by keys if they are
 */
export function getQueryParams(params: Record<string, any>, keys?: string[], exclude?: boolean): HttpParams {
  const queryParams = getParams(params, keys, exclude);

  return new HttpParams({ fromObject: queryParams });
}

/**
 * Returns a data without empty ids
 */
export function excludeEmptyIds(data: any, needCopy = true): any {
  data = needCopy ? copy(data) : data;
  if (Array.isArray(data)) {
    data.forEach(item => excludeEmptyIds(item, false));
  } else if (data !== null && typeof data === 'object') {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'id') {
          if (
            !data[key] ||
            (typeof data[key] === 'number' && data[key] < 1) ||
            (typeof data[key] === 'string' && data[key].startsWith('guid'))
          ) {
            delete data[key];
          }
        } else if (data[key]) {
          excludeEmptyIds(data[key], false);
        }
      }
    }
  }

  return data;
}
