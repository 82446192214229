import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'pu-sum-input',
  templateUrl: './sum-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SumInputComponent),
      multi: true,
    },
  ],
})
export class SumInputComponent extends AbstractInputComponent<number> {
  @Input() direction: 'left' | 'right' = 'left';
  @Input() currencyLabel = '';
}
