export enum ToastType {
  Info = 1,
  Success,
  Warning,
  Error,
}

export interface ToastTypeData {
  /**
   * Class name for toast
   */
  className: string;

  /**
   * Icon name
   */
  iconName: string;
}

export const toastTypeDataRecord: Record<ToastType, ToastTypeData> = {
  [ToastType.Info]: {
    className: 'info',
    iconName: 'info-circle',
  },
  [ToastType.Success]: {
    className: 'success',
    iconName: 'success',
  },
  [ToastType.Warning]: {
    className: 'warning',
    iconName: 'alert-warning',
  },
  [ToastType.Error]: {
    className: 'error',
    iconName: 'alert-warning',
  },
};
