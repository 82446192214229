import { Injectable } from '@angular/core';

import { DictionaryItem, StrNum } from '@pu/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  private dictionaries: Record<string, DictionaryItem<StrNum>[]> = {};

  constructor(private _translate: TranslateService) {
    this.dictionaries['deliveries'] = [
      { id: 'international', name: this._translate.instant(`dictionary.deliveries.international`) },
      { id: 'ukrposhta', name: this._translate.instant(`dictionary.deliveries.ukrposhta`) },
      { id: 'nova_poshta', name: this._translate.instant(`dictionary.deliveries.novaPoshta`) },
      { id: 'fedex', name: this._translate.instant(`dictionary.deliveries.fedex`) },
      { id: 'dhl', name: this._translate.instant(`dictionary.deliveries.dhl`) },
      { id: 'dpd', name: this._translate.instant(`dictionary.deliveries.dpd`) },
      { id: 'inpost', name: this._translate.instant(`dictionary.deliveries.inpost`) },
      { id: 'acs', name: this._translate.instant(`dictionary.deliveries.acs`) },
      { id: 'gap_akis_express', name: this._translate.instant(`dictionary.deliveries.gapAkisExpress`) },
    ];

    this.dictionaries['orderStatuses'] = [
      { id: null, name: this._translate.instant('dictionary.orderStatuses.all') },
      { id: 'new', name: this._translate.instant('dictionary.orderStatuses.new') },
      { id: 'confirmed', name: this._translate.instant('dictionary.orderStatuses.confirmed') },
      { id: 'shipping', name: this._translate.instant('dictionary.orderStatuses.shipping') },
      { id: 'delivered', name: this._translate.instant('dictionary.orderStatuses.delivered') },
      { id: 'completed', name: this._translate.instant('dictionary.orderStatuses.completed') },
      { id: 'canceled', name: this._translate.instant('dictionary.orderStatuses.canceled') },
    ];

    this.dictionaries['questStatuses'] = [
      { id: null, name: this._translate.instant('dictionary.questStatuses.all') },
      { id: 'inProgress', name: this._translate.instant('dictionary.questStatuses.inProgress') },
      { id: 'inReview', name: this._translate.instant('dictionary.questStatuses.inReview') },
      { id: 'completed', name: this._translate.instant('dictionary.questStatuses.completed') },
      { id: 'rejected', name: this._translate.instant('dictionary.questStatuses.rejected') },
      { id: 'canceled', name: this._translate.instant('dictionary.questStatuses.canceled') },
    ];

    this.dictionaries['periods'] = [
      { id: null, name: this._translate.instant('dictionary.periods.all') },
      { id: 1, name: this._translate.instant('dictionary.periods.month') },
      { id: 3, name: this._translate.instant('dictionary.periods.threeMonths') },
      { id: 6, name: this._translate.instant('dictionary.periods.sixMonths') },
      { id: 12, name: this._translate.instant('dictionary.periods.year') },
    ];

    this.dictionaries['events'] = [
      { id: 'new_account', name: this._translate.instant('dictionary.events.newAccount') },
      { id: 'probation_end', name: this._translate.instant('dictionary.events.probationEnd') },
      { id: 'birthday', name: this._translate.instant('dictionary.events.birthday') },
      { id: 'personal_year_start', name: this._translate.instant('dictionary.events.personalYearStart') },
    ];

    this.dictionaries['months'] = [
      { id: 1, name: this._translate.instant('dictionary.months.january') },
      { id: 2, name: this._translate.instant('dictionary.months.february') },
      { id: 3, name: this._translate.instant('dictionary.months.march') },
      { id: 4, name: this._translate.instant('dictionary.months.april') },
      { id: 5, name: this._translate.instant('dictionary.months.may') },
      { id: 6, name: this._translate.instant('dictionary.months.june') },
      { id: 7, name: this._translate.instant('dictionary.months.july') },
      { id: 8, name: this._translate.instant('dictionary.months.august') },
      { id: 9, name: this._translate.instant('dictionary.months.september') },
      { id: 10, name: this._translate.instant('dictionary.months.october') },
      { id: 11, name: this._translate.instant('dictionary.months.november') },
      { id: 12, name: this._translate.instant('dictionary.months.december') },
    ];

    this.dictionaries['automaticAccrual'] = [
      {
        id: 'monthly-group-tasks',
        name: this._translate.instant('admin.pincoins.automaticAccrual.table.type.monthlyGroup'),
        description: this._translate.instant('admin.pincoins.automaticAccrual.table.description.automaticAccrueMonthly'),
      },
      {
        id: 'yearly-group-tasks',
        name: this._translate.instant('admin.pincoins.automaticAccrual.table.type.yearlyGroup'),
        description: this._translate.instant('admin.pincoins.automaticAccrual.table.description.automaticAccrueYearly'),
      },
      {
        id: 'monthly-user-tasks',
        name: this._translate.instant('admin.pincoins.automaticAccrual.table.type.user'),
        description: this._translate.instant('admin.pincoins.automaticAccrual.table.description.assingAnAward'),
      },
    ];

    this.dictionaries['userStatuses'] = [
      { id: 1, name: this._translate.instant('dictionary.userStatuses.active') },
      { id: 2, name: this._translate.instant('dictionary.userStatuses.probation') },
      { id: 3, name: this._translate.instant('dictionary.userStatuses.offboarding') },
    ];
  }

  get<T extends DictionaryItem<StrNum>[] = DictionaryItem<StrNum>[]>(name: string): T {
    return <T>this.dictionaries[name];
  }
}
