import { AbstractControl, ValidationErrors } from '@angular/forms';

import { emailValidationPattern, extendedPhoneValidationPattern, isPhoneMatched } from '../utils';

/**
 * Login validator
 * Check for email validity if value have '@' char inside
 * Check for phone validity if isPhoneMatched() function returns true
 *
 * @param control login control
 * @returns control validation errors
 */
export const loginValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  if (control.value.includes('@')) {
    const isValid = emailValidationPattern.test(control.value);

    if (isValid && !control.touched) {
      control.markAsTouched();
    }

    return isValid ? null : { mask: true };
  }

  if (isPhoneMatched(control.value)) {
    const isValid = extendedPhoneValidationPattern.test(control.value);

    if (isValid && !control.touched) {
      control.markAsTouched();
    }

    return isValid ? null : { mask: true };
  }

  return { mask: true };
};
