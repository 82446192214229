import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from '@pu/components';
import { LetModule } from '@pu/directives';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BirthdaysWidgetComponent } from './birthdays-widget.component';
import { BirthdaysWidgetEffects, birthdaysWidgetFeature } from './store';

@NgModule({
  declarations: [BirthdaysWidgetComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(birthdaysWidgetFeature),
    EffectsModule.forFeature([BirthdaysWidgetEffects]),
    TranslateModule,
    AvatarModule,
    LetModule,
  ],
  exports: [BirthdaysWidgetComponent],
})
export class BirthdaysWidgetModule {}
