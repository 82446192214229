import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class GlobalSearchOverlayContainer extends OverlayContainer {
  protected override _createContainer() {
    const containerClass = 'global-search-overlay-container';
    const container = this._document.createElement('div');
    container.classList.add(containerClass);

    this._document.body.appendChild(container);
    this._containerElement = container;
  }
}
