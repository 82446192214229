<input type="checkbox" class="pu-checkbox__input" [id]="controlId" [formControl]="control"
       [attr.data-testid]="controlTestId"/>
<div
  class="pu-checkbox__box"
  [ngClass]="{
    'pu-checkbox__box_active': control.value$ | async,
    'pu-checkbox__box_invalid': control.touched && control.invalid
  }"
>
  <ui-icon *ngIf="control.value$ | async" size="16" class="pu-checkbox__box-icon" iconName="checkpoint-yes"></ui-icon>
</div>
<label *ngIf="label || labelTemplate" class="pu-checkbox__label" [for]="controlId">
  <ng-container *ngIf="label">{{ label }}</ng-container>

  <ng-container *ngIf="labelTemplate">
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
  </ng-container>
</label>
<span *ngIf="hint && control.touched && control.invalid" class="pu-control__hint pu-control__hint_invalid">
  {{ hint }}
</span>
