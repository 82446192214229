import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  /**
   * Sets Monday to be the first day of week in calendar
   */
  override getFirstDayOfWeek(): number {
    return 1;
  }
}
