import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pu/ui';

import { ToastComponent } from './toast/toast.component';
import { ToasterComponent } from './toaster/toaster.component';

@NgModule({
  declarations: [ToastComponent, ToasterComponent],
  imports: [CommonModule, IconModule, ButtonModule, TranslateModule],
  exports: [ToastComponent, ToasterComponent],
})
export class ToastModule {}
