<ng-container *puLet="vm$ | async as vm">
  <ng-container *uiSpinner="vm.isLoading">
    <div class="pu-balance"
         [class.pu-balance_dark]="vm.skin?.isDark">
      <img
        class="pu-balance__img"
        [src]="vm.skin?.url || '/assets/images/balance/balance-card-mock.jpg'"
        [alt]="vm.skin?.name || ''" />

      <h3 class="pu-balance__title" *ngIf="!isCard">
        {{ 'balance.title.currentBalance' | translate }}

        <button
          class="pu-balance__edit-btn"
          type="button"
          uiButton
          uiType="quaternary"
          [onlyIcon]="true"
          (click)="edit()">
          <ui-icon iconName="edit"></ui-icon>
        </button>
      </h3>

      <p class="pu-balance__balance">
        <ui-icon
          *ngIf="isCard"
          iconName="coins"
          size="20">
        </ui-icon>

        <span class="pu-balance__balance-sum"
              [attr.translate]="'no'">
          {{ vm.balance }}
        </span>

        <ui-icon
          [class]="!isCard ? 'md:ui-icon_m' : ''"
          iconName="pin-coin"
          [size]="isCard ? '16' : '32'">
        </ui-icon>
      </p>

      <div class="pu-balance__actions"
           *ngIf="!isCard || isTablet">
        <button
          class="lg:ui-button_s xl:ui-button_m"
          type="button"
          uiButton
          [uiType]="isCard ? 'primary' : 'secondary'"
          size="l"
          (click)="share()"
          [title]="'balance.button.sharePincoins' | translate"
          translate="balance.button.share">
        </button>

        <a
          class="lg:ui-button_s xl:ui-button_m"
          type="button"
          uiButton
          [uiType]="isCard ? 'primary' : 'secondary'"
          size="l"
          routerLink="/quests"
          [title]="'balance.button.earnPincoins' | translate"
          translate="balance.button.earn">
        </a>

        <a
          class="lg:ui-button_s xl:ui-button_m"
          *ngIf="isCard"
          uiButton
          uiType="secondary"
          size="l"
          routerLink="/faq"
          [queryParams]="{ tagId: vm.pincoinsTag?.id }"
          translate="balance.link.howToEarn">
        </a>
      </div>
    </div>

    <a
      class="pu-balance__how-to-earn"
      *ngIf="!isCard"
      uiButton
      uiType="quaternary"
      routerLink="/faq"
      [queryParams]="{ tagId: vm.pincoinsTag?.id }"
      translate="balance.link.howToEarnPincoins">
    </a>
  </ng-container>
</ng-container>
