import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@pu/directives';
import { ButtonModule, IconModule, LogoModule } from '@pu/ui';
import { SearchInputModule } from '@pu/forms';

import { HeaderComponent } from './header.component';
import { ControlsComponent } from './controls/controls.component';
import { BalanceModule } from '../../components/balance/balance.module';
import { CartCounterModule } from '../components/cart-counter/cart-counter.module';
import { GlobalSearchModule } from '../components/global-search/global-search.module';
import { BirthdaysWidgetModule } from '../components/birthdays-widget/birthdays-widget.module';
import { SupportModule } from '../../components/support/support.module';

@NgModule({
  declarations: [HeaderComponent, ControlsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LetModule,
    LogoModule,
    BalanceModule,
    IconModule,
    SearchInputModule,
    ButtonModule,
    CartCounterModule,
    GlobalSearchModule,
    BirthdaysWidgetModule,
    SupportModule,
  ],
  exports: [HeaderComponent, ControlsComponent],
})
export class HeaderModule {}
