import { ChangeDetectionStrategy, Component, inject, Inject, OnInit, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pu/ui';
import { SimpleInputComponentModule } from '@pu/forms';
import { FormBuilder } from '@ngneat/reactive-forms';
import { provideNgxMask } from 'ngx-mask';
import { Enable2FAData } from '@pu/models';
import { ActionsSubject, Store } from '@ngrx/store';
import { AuthActions } from '@pu/store';
import { ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DIALOG_DATA, DialogRef } from '@pu/services';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    QRCodeModule,
    ButtonModule,
    IconModule,
    SimpleInputComponentModule,
  ],
  providers: [provideNgxMask()],
  selector: 'pu-tfa-reset',
  templateUrl: './tfa-reset.component.html',
  styleUrls: ['./tfa-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TfaResetComponent implements OnInit {
  // TODO: If inject these inside constructor, we get runtime error that theses things are undefined.
  //  @UntilDestroy() does smth with constructor, so Angular can't resolve dependencies.
  //  Change @UntilDestroy() to Angular native takeUntilDestroyed() after update to 16v.
  private _dialogRef = inject(DialogRef);
  token: string;
  isLoading = false;

  form = this._fb.group({
    code: ['', [Validators.required]],
  });
  errorDictionary: Record<string, string> = {
    apiValidation: 'errors.invalid_recovery_code',
  };

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _actionsSubject: ActionsSubject,
    @Optional() @Inject(DIALOG_DATA) public data: Enable2FAData & { token: string },
  ) {
    this.token = data.token;
  }

  ngOnInit() {
    this._actionsSubject
      .pipe(
        ofType(AuthActions.resetCode.succeededAction),
        tap(() => {
          this.isLoading = false;
          this._dialogRef.close();
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this._actionsSubject
      .pipe(
        ofType(AuthActions.resetCode.failedAction),
        tap(() => (this.isLoading = false)),
        filter(({ error }) => error.code === 'invalid_recovery_code'),
        tap(() => this.form.controls.code.setErrors({ apiValidation: true })),
        untilDestroyed(this),
      )
      .subscribe();
  }

  reset(): void {
    this.isLoading = true;
    this._store.dispatch(AuthActions.resetCode.action({ payload: { ...this.form.value, token: this.token } }));
  }
}
