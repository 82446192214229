import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { RouterActions } from './';

@Injectable()
export class RouterEffects {
  goBack$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.goBack),
        tap(action => this._location.back()),
      ),
    {
      dispatch: false,
    },
  );

  navigate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.navigate),
        tap(({ route, extras = {} }) => {
          this._router.navigate(route, extras);
        }),
      ),
    {
      dispatch: false,
    },
  );

  navigateByUrl$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.navigateByUrl),
        tap(({ url }) => {
          this._router.navigateByUrl(url);
        }),
      ),
    {
      dispatch: false,
    },
  );

  navigateTo404$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.navigateTo404),
        tap(action => {
          this._router.navigate(['/404']);
        }),
      ),
    {
      dispatch: false,
    },
  );

  navigateToHome$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.navigateToHome),
        tap(action => {
          this._router.navigate(['/']);
        }),
      ),
    {
      dispatch: false,
    },
  );

  reloadCurrentRoute$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.reloadCurrentRoute),
        tap(() => this._reloadCurrentRoute()),
      ),
    {
      dispatch: false,
    },
  );

  constructor(private _actions$: Actions, private _location: Location, private _router: Router) {}

  private async _reloadCurrentRoute(): Promise<void> {
    const url = this._router.url;
    const onSameUrlNavigation = this._router.onSameUrlNavigation;
    const shouldReuseRoute = this._router.routeReuseStrategy.shouldReuseRoute;
    this._router.onSameUrlNavigation = 'reload';
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    await this._router.navigateByUrl(url);

    this._router.onSameUrlNavigation = onSameUrlNavigation;
    this._router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
  }
}
