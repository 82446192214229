import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '@pu/store';

import { ContactDialog } from './components/contact/contact.dialog';
import { SupportActions, SupportSelectors, SupportViewModel } from './store';

@Component({
  selector: 'pu-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportComponent implements OnInit {
  @Input() type: 'popover' | 'list' = 'popover';

  @Output() clickItem = new EventEmitter<void>();

  vm$: Observable<SupportViewModel> = this._store.select(SupportSelectors.selectViewModel);
  opened = false;

  constructor(private _store: Store<RootState>, private _contactDialog: ContactDialog) {}

  ngOnInit(): void {
    this._store.dispatch(SupportActions.init({ routeData: {} }));
  }

  openTipsDialog(type: string): void {
    this.clickItem.emit();
    this._store.dispatch(SupportActions.getTipsReq.action({ payload: { type } }));
  }

  openContactDialog(): void {
    this.clickItem.emit();
    this._contactDialog.open();
  }

  toggleList(): void {
    this.opened = !this.opened;
  }
}
