<div class="pu-control__content" #inputReference>
  <div
    class="pu-control__phone-select"
    *ngIf="!isReadonly; else countryFlag"
    #dropdownRef="puDropdownTrigger"
    [puDropdownTriggerFor]="selectDropdown"
    [reference]="inputReference"
    [attr.data-testid]="controlTestId + 'CodeSelector'"
  >
    <ui-icon
      class="pu-control__phone-select-chevron"
      iconName="chevron-1"
      size="20"
      [class.pu-control__phone-select-chevron_active]="dropdownRef.isDropdownOpened$ | async"
    ></ui-icon>
    <div *ngIf="selectedPhoneSubject | async as selectedPhone; else codeTemplate" class="pu-control__phone-select-item">
      <img class="pu-control__phone-select-img" [src]="selectedPhone.img" [alt]="selectedPhone.countryCode"/>
      <div class="pu-control__phone-select-label">{{ selectedPhone.code }}</div>
    </div>
    <ng-template #codeTemplate>
      <div class="pu-control__phone-select-label pu-control__phone-select-label_code">
        {{ 'controls.phonePickerPlaceholder' | translate }}
      </div>
    </ng-template>
  </div>
  <div class="pu-control__input-wrapper">
    <input
      class="pu-control__input"
      puInputNumber
      puDisablePaste
      [allowPaste]="allowPaste"
      [type]="controlTypes.Tel"
      [id]="controlId"
      [ngClass]="{
        'pu-select__input_readonly': isReadonly,
        'pu-control__input_padding': !!iconTemplate
      }"
      [placeholder]="placeholder"
      [formControl]="phoneControl"
      [readonly]="isReadonly"
      [attr.data-testid]="controlTestId"
      [autocomplete]="allowAutocomplete ? 'tel' : 'off'"
    />

    <ui-icon
      *ngIf="hasEditButton"
      class="pu-control__edit-icon"
      size="20"
      iconName="edit"
      (click)="handleEditClick()"
      [attr.data-testid]="controlTestId + 'EditBtn'"
    ></ui-icon>
  </div>
  <ng-container *ngIf="label">
    <label
      [for]="controlId"
      class="pu-control__label pu-control__label_active"
      [ngClass]="{
        'pu-control__label_invalid': control.touched && control.invalid,
        'pu-control__label_small': labelSize === 's'
      }"
    >{{ label }}</label
    >
    <pu-floating-label-outline
      [hasGap]="true"
      [label]="label"
      [labelSize]="labelSize"
      [isHighlighted]="dropdownRef?.isDropdownOpened$ | async"
      [isInvalid]="phoneControl.touched && control.invalid"
      [isReadOnly]="isReadonly"
    ></pu-floating-label-outline>
  </ng-container>
  <div class="pu-control__icon" *ngIf="iconTemplate">
    <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
  </div>
</div>
<pu-dropdown #selectDropdown>
  <pu-select-dropdown-list
    [itemList]="itemList"
    [selectedValue]="(selectedPhoneSubject | async)?.countryCode"
    (itemSelected)="onItemSelected($event)"
  ></pu-select-dropdown-list>
</pu-dropdown>

<pu-validation-message
  *ngIf="phoneControl.touched && control.invalid && showValidationMessage"
  [errors]="control.errors$ | async"
  [errorDictionary]="errorDictionary"
></pu-validation-message>

<ng-template #countryFlag>
  <img
    *ngIf="selectedPhoneSubject | async as selectedPhone"
    class="pu-control__phone-select-img pu-control__phone-select-img_readonly"
    [src]="selectedPhone.img"
    [alt]="selectedPhone.countryCode"
  />
</ng-template>
