import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'pu-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent extends AbstractInputComponent<string> {
  /**
   * Show password toggle button
   */
  @Input() showToggle = true;
  /**
   * Autocomplete Method
   */
  @Input() autocomplete = 'on';

  /**
   * Show new password hints
   */
  @Input() showNewPasswordHints = false;

  /**
   * Property that is used to toggle the visibility of the password
   * @internal
   */
  isPasswordVisible = false;

  /**
   * Used for new password hints
   * @internal
   */
  wasFocused = false;

  /**
   * Toggle the visibility of the password
   * @internal
   */
  toggleVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  override setIsFocused(isFocused: boolean) {
    if (isFocused) {
      this.wasFocused = true;
    }

    super.setIsFocused(isFocused);
  }
}
