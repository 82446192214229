import { LetDirective } from './let.directive';

export interface PUContextWithImplicit<T> {
  $implicit: T;
}

/**
 * @internal
 */
export class LetContext<T> implements PUContextWithImplicit<T> {
  constructor(private readonly _internalDirectiveInstance: LetDirective<T>) {}

  get $implicit(): T {
    return this._internalDirectiveInstance.puLet;
  }

  get puLet(): T {
    return this._internalDirectiveInstance.puLet;
  }
}
