import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pu-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {
  @Input() iconName: string;
  @Input() titleKey: string;
  @Input() messageKey: string;
  @Input() btnKey = 'empty.btn.shopPage';
  @Input() btnLink = '/shop';
}
