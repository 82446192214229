import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { DecodedSafeHtmlPipeModule } from '@pu/pipes';

import { RadioOptionComponent } from './radio-option.component';

@NgModule({
  declarations: [RadioOptionComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, DecodedSafeHtmlPipeModule],
  exports: [RadioOptionComponent],
})
export class RadioOptionModule {}
