import { UserProfile } from '@pu/models';

export const getUsersMock = (count = 12): UserProfile[] => {
  const usersMock: UserProfile[] = [];

  for (let i = 1; i <= count; i++) {
    usersMock.push({
      id: i,
      fullName: `Aleksander Samsonov-1`,
      imageUrl: `/assets/images/user-avatar-mock.jpg`,
      position: 'Golang developer',
      email: 'a.sidorov@pin-up.business',
    });
  }

  return usersMock;
};
