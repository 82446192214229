import { AppError } from '@pu/models';

/**
 * An interface for failed async actions
 */
export interface FailedAction<Initiator extends object | void = void> {
  /**
   * Error caused fail
   */
  error: AppError;

  /**
   * An action which triggered an error
   */
  initiator?: Initiator;
}
