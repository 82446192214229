import { Entity, ImageModel, LocationModel, User } from '@pu/models';

export type QuestStatusType = 'inProgress' | 'inReview' | 'completed' | 'rejected' | 'canceled';

export interface Quest extends Entity {
  name: string;
  rewardAmount: number;
  mainImage: ImageModel;
}

export type QuestCommentType = 'moderator' | 'user';

export interface QuestComment extends Entity {
  text: string;
  type: QuestCommentType;
}

export interface QuestHistory extends Entity {
  createdAt: string;
  status: QuestStatusType;
}

export interface QuestLocation {
  location: LocationModel;
}

export interface QuestStatus {
  status: QuestStatusType;
}

export interface QuestDuration {
  startedAt: string | null;
  expiredAt: string | null;
}

export interface QuestDescription {
  description: string;
}

export interface QuestGallery extends Quest, QuestDuration {
  isParticipate: boolean;
}

export interface QuestDetails extends QuestGallery, QuestDescription {
  extraImages: ImageModel[];
}

export interface AdminQuest extends Quest {
  visible: boolean;
  expiredAt: string | null;
}

export interface AdminQuestEditable extends AdminQuest {
  description: string;
  extraImages: ImageModel[];
  startedAt: string | null;
}

export interface CategoryQuest extends Quest {
  expiredAt: string | null;
  visible: boolean;
}

export interface CategoryQuestEditable extends Entity {
  quest: Quest;
  categoryIds: number[];
  visible: boolean;
}

export interface ParticipantQuest extends Quest, QuestStatus {
  participant: User;
  updatedAt: string;
}

export interface ParticipantQuestHistory extends Entity {
  status: QuestStatusType;
  comment: string;
  images: ImageModel[];
  createdAt: string;
}

export interface ParticipantQuestEditable extends Quest {
  participant: User;
  description: string;
  history: ParticipantQuestHistory[];
}

export interface MyQuest extends Quest, QuestDuration, QuestLocation, QuestStatus {}

export interface MyQuestDetails extends Entity, QuestDescription {
  history: ParticipantQuestHistory[];
}
