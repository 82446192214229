<div class="ui-spinner">
  <svg class="ui-spinner__letter"
       width="26" height="35" viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8664 25.5884C19.9731 25.5884 25.7328 19.8592 25.7328 12.7942C25.7328 5.72928 19.9731 0 12.8664 0C5.75965 0 0 5.72928 0 12.7942V34.0277H9.30948V25.0928V12.8153C9.30948 12.8083 9.30948 12.8012 9.30948 12.7942C9.30948 10.8399 10.9041 9.25472 12.8699 9.25472C14.8358 9.25472 16.4304 10.8399 16.4304 12.7942C16.4304 14.7485 14.8358 16.3337 12.8699 16.3337C12.5871 16.3337 12.3113 16.3021 12.0496 16.2388V25.5638C12.3148 25.5779 12.5906 25.5884 12.8664 25.5884Z"
      fill="#FF2400"
    />
  </svg>

  <div class="ui-spinner__circles">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
