import { AbstractControl, ValidationErrors } from '@angular/forms';

import { extendedPhoneValidationPattern } from '../utils';

export const phoneSelectValidator = (phoneCode: string): ((control: AbstractControl) => ValidationErrors | null) => {
  return (control: AbstractControl) => {
    if (control.value) {
      const phoneNumberWithCode = `${phoneCode.replace(/\s/g, '')}${control.value}`;
      const isValid = extendedPhoneValidationPattern.test(phoneNumberWithCode);

      return !isValid ? { phoneFormat: true } : null;
    }

    return null;
  };
};
