import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLoaderService } from '@pu/services';
import { AppInitModule } from '@pu/app-init';
import { RootStoreModule } from '@pu/store';
import { APP_HTTP_INTERCEPTORS } from '@pu/interceptors';
import { DEFAULT_TOAST_CONFIG, TOAST_CONFIG_TOKEN } from '@pu/ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TimeagoModule } from 'ngx-timeago';
import { matGlobalRippleConfig } from '@pu/third-party';

import { appRoutes, CustomRouteReuseStrategy } from './app.routes';
import { AppComponent } from './app.component';
import { ShellModule } from './shell/shell.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      // gather all params from parent routes to last route
      paramsInheritanceStrategy: 'always',
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderService,
        deps: [HttpClient],
      },
    }),
    NgxPermissionsModule.forRoot(),
    TimeagoModule.forRoot(),
    RootStoreModule,
    AppInitModule,
    ShellModule,
    MatNativeDateModule,
  ],
  providers: [
    APP_HTTP_INTERCEPTORS,
    {
      provide: TOAST_CONFIG_TOKEN,
      useValue: DEFAULT_TOAST_CONFIG,
    },
    { provide: LOCALE_ID, useValue: 'en' },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: matGlobalRippleConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
