import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pu/ui';

import { EmptyComponent } from './empty.component';

@NgModule({
  declarations: [EmptyComponent],
  imports: [CommonModule, IconModule, TranslateModule, ButtonModule, RouterModule],
  exports: [EmptyComponent],
})
export class EmptyModule {}
