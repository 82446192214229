import { Injectable } from '@angular/core';

import { Observable, share, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OneSecondIntervalService extends Observable<number> {
  private _timer$ = timer(0, 1000).pipe(share());

  constructor() {
    super(subscriber => {
      const timerSubs = this._timer$.subscribe({ next: value => subscriber.next(value) });

      return {
        unsubscribe() {
          timerSubs.unsubscribe();
        },
      };
    });
  }
}
