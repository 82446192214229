import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncActionReducer } from '@pu/store';

import { supportFeatureKey, SupportState, SupportActions } from './';
import { GetTagsRes } from '../models';

const tagsReducer = createReducer<GetTagsRes>(
  { itemsCount: 0, tags: [] },
  on(SupportActions.getTagsReq.succeededAction, (state, action): GetTagsRes => action.payload),
);

const tagIdReducer = createReducer<number>(
  null,
  on(SupportActions.tagId, (state, action): number => action.tagId),
);

const tipTypeReducer = createReducer<string>(
  null,
  on(SupportActions.tipType, (state, action): string => action.tipType),
);

export const supportFeature = createFeature({
  name: supportFeatureKey,
  reducer: combineReducers<SupportState>({
    tags: tagsReducer,
    tagId: tagIdReducer,
    tipType: tipTypeReducer,
    getTagsAction: createAsyncActionReducer(SupportActions.getTagsReq),
    getTipsAction: createAsyncActionReducer(SupportActions.getTipsReq),
    sendMessageAction: createAsyncActionReducer(SupportActions.sendMessageReq),
  }),
});
