import { ChangeDetectionStrategy, Component, EventEmitter, OnChanges, Input, Output, ViewChild, SimpleChanges } from '@angular/core';
import { Params, RouterLinkActive } from '@angular/router';

import { CommonMediaQueries, MediaScreenService } from '@pu/services';
import { trackById } from '@pu/utils';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
import { Observable } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';

import { CategoryTab } from './models/category-tab.model';

SwiperCore.use([Navigation]);

@Component({
  selector: 'ui-category-tabs',
  templateUrl: './category-tabs.component.html',
  styleUrls: ['./category-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTabsComponent implements OnChanges {
  /**
   * Categories
   */
  @Input() categories: CategoryTab[];

  /**
   * Value
   */
  @Input() value?: number | string;

  /**
   * Name field
   */
  @Input() nameField = 'name';

  /**
   * Slug field
   */
  @Input() iconField = 'iconName';

  /**
   * Image field
   */
  @Input() imgField = 'image';

  /**
   * Param field
   */
  @Input() paramField = 'id';

  /**
   * Param name
   */
  @Input() paramName?: string;

  /**
   * Slug field
   */
  @Input() paramType: 'query' | 'slug' = 'query';

  /**
   * Route prefix
   */
  @Input() routePrefix = '';

  /**
   * Exact route
   */
  @Input() exactRoute = true;

  /**
   * Query params of other controls
   */
  @Input() queryParams: Params = {};

  /**
   * When active tab changed
   */
  @Output() tabChanged = new EventEmitter<any>();

  /**
   * Slider buttons should be shown, depends on resolution
   */
  hasSliderNavButtons$: Observable<boolean> = this._mediaScreenService.mediaMatcher(CommonMediaQueries.LG);

  sliderConfig: SwiperOptions = {
    slidesPerView: 'auto',
    grabCursor: true,
    navigation: {
      prevEl: '.ui-category-tabs__slide-btn-container_prev',
      nextEl: '.ui-category-tabs__slide-btn-container_next',
    },
  };

  trackById = trackById;

  permissions: NgxPermissionsObject;
  categoryRoutes: Record<number | string, { route: string; queryParams: Record<string, string | number> }> = {};

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(private _mediaScreenService: MediaScreenService, private _permissions: NgxPermissionsService) {
    this.permissions = this._permissions.getPermissions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.categories.forEach(category => {
      this.categoryRoutes[category[this.paramField]] = {
        route: this._getRoute(category),
        queryParams: this._getQueryParams(category),
      };
    });
  }

  changeActiveTab(isActive: boolean, index: number, category: CategoryTab) {
    if (!isActive) {
      this.swiper?.swiperRef.slideTo(index > 0 ? index - 1 : index);
      this.tabChanged.emit(category);
    }
  }

  isActive(rla: RouterLinkActive, category: CategoryTab): boolean {
    return typeof this.value !== 'undefined' ? this.value === category[this.paramField] : rla.isActive;
  }

  private _getRoute(category: CategoryTab): string {
    return (this.routePrefix ? this.routePrefix + '/' : '') + (this.paramType === 'slug' ? category[this.paramField] : '');
  }

  private _getQueryParams(category: CategoryTab): Record<string, string | number> {
    return this.paramType === 'query'
      ? {
          ...this.queryParams,
          [this.paramName]: category[this.paramField],
        }
      : this.queryParams;
  }
}
