import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'pu-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends AbstractInputComponent<boolean> {
  @Input() labelTemplate: TemplateRef<any>;

  /**
   * Indicates if input is non-editable and readonly
   */
  @HostBinding('class.pu-checkbox_readonly')
  @Input()
  override isReadonly: boolean;
}
