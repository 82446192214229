import { Action, createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActionGroupConfig } from '@ngrx/store/src/action_group_creator_models';
import { AppError } from '@pu/models';

import { AsyncActionGroup } from '../models';

/**
 * Create action with no props
 * @param source
 */
export const createAsyncActionNoProps = <ActionResponse, Initiator extends object | void = void>(
  source: string,
): AsyncActionGroup<void, ActionResponse, Initiator> => {
  return createActionGroup({
    source: <ActionGroupConfig<string, any>['source']>`API: ${source}`,
    events: {
      ['Action']: emptyProps(),
      ['Succeeded Action']: props<{
        payload: ActionResponse;
        initiator?: Initiator;
      }>(),
      ['Failed Action']: props<{
        error: AppError;
        initiator?: Initiator;
      }>(),
      ['Clear Action']: emptyProps(),
    },
  });
};

/**
 * Create Action with props
 * @param source
 */
export const createAsyncAction = <ActionPayload, ActionResponse = void, Initiator extends object | void = void>(
  source: string,
): AsyncActionGroup<{ payload: ActionPayload; initiator?: Initiator }, ActionResponse, Initiator> => {
  return createActionGroup({
    source: <ActionGroupConfig<string, any>['source']>`API: ${source}`,
    events: {
      ['Action']: props<{ payload: ActionPayload; initiator?: Initiator }>(),
      ['Succeeded Action']: props<{
        payload: ActionResponse;
        initiator?: Initiator;
      }>(),
      ['Failed Action']: props<{
        error: AppError;
        initiator?: Initiator;
      }>(),
      ['Clear Action']: emptyProps(),
    },
  });
};
