import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SwiperModule } from 'swiper/angular';
import { LetModule } from '@pu/directives';
import { CategoryTabComponentModule, IconModule, SliderControlsModule } from '@pu/ui';

import { CategoryTabsComponent } from './category-tabs.component';

@NgModule({
  imports: [CommonModule, SwiperModule, RouterModule, IconModule, LetModule, SliderControlsModule, CategoryTabComponentModule],
  declarations: [CategoryTabsComponent],
  exports: [CategoryTabsComponent],
})
export class CategoryTabsModule {}
