import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';

import { environment } from '@pu/environment';
import { firstValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthActions, AuthSelectors, RootState } from '@pu/store';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { AccessData, PersonalInfo } from '@pu/models';
import { REFRESH_TOKEN_KEY } from '@pu/constants';

import { accessDataMock, profileMock } from './mocks';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _initialized: boolean;
  private _initializedPromise: Promise<boolean>;

  constructor(private _http: HttpClient, private _store: Store<RootState>, private _router: Router, private _zone: NgZone) {
    this._initializedPromise = new Promise(resolve => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.onGoogleLibraryLoad = () => {
        if (!this._initialized) {
          this._initialized = true;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          google.accounts.id.initialize({
            // Ref: https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
            client_id: environment.googleClientId,
            callback: (response: CredentialResponse): void => {
              this._zone.run(() => {
                if (response.credential) {
                  this._store.dispatch(AuthActions.signIn.action({ payload: { token: response.credential } }));
                } else {
                  this._store.dispatch(AuthActions.logout());
                }
              });
            },
          });

          resolve(true);
        }
      };
    });
  }

  addButton(elementId: string): void {
    this._initializedPromise.then(initialized => {
      const parent = document.getElementById(elementId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      google.accounts.id.renderButton(parent, { theme: 'filled_black' });
    });
  }

  addOneTap(callback?: (notification: PromptMomentNotification) => void): void {
    this._initializedPromise.then(initialized => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      google.accounts.id.prompt(callback);
      // if (notification.getDismissedReason() === 'credential_returned') {
      // }
    });
  }

  async signedInCheck(): Promise<true | UrlTree> {
    if (environment.useMocks) {
      // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
      this._store.dispatch(AuthActions.updateAccessData({ ...accessDataMock }));
      // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
      this._store.dispatch(AuthActions.setProfile({ profile: profileMock }));

      return true;
    }

    const profile = await firstValueFrom(this._store.select(AuthSelectors.selectProfile));

    if (profile) {
      return true;
    }

    return firstValueFrom(
      this.check().pipe(
        map((): true => true),
        catchError(() => of(this._getSignInUrlTree())),
      ),
    );
  }

  signedOutCheck(): Observable<boolean> {
    return this.check().pipe(
      map(() => false),
      tap(() => this._store.dispatch(AuthActions.navigateAfter())),
      catchError(() => of(true)),
    );
  }

  check() {
    const refresh = window.localStorage.getItem(REFRESH_TOKEN_KEY);

    return this._http.post<Pick<AccessData, 'access' | 'permissions'>>(environment.apiHost + `auth/refresh/`, { refresh }).pipe(
      tap(value => this._store.dispatch(AuthActions.updateAccessData({ ...value }))),
      switchMap(() => this._http.get<PersonalInfo>(environment.apiHost + `user/personal-info/`)),
      tap(profile => this._store.dispatch(AuthActions.setProfile({ profile }))),
    );
  }

  logout(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    google.accounts.id.disableAutoSelect();
    this._store.dispatch(AuthActions.logout());
  }

  decodeJwt(idToken: string): Record<string, string | undefined> {
    const base64Url = idToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  private _getSignInUrlTree(): UrlTree {
    const path = window.location.pathname.indexOf('signin') >= 0 ? '' : window.location.pathname;

    return this._router.parseUrl(!path || path === '/' ? '/signin' : '/signin?url=' + encodeURIComponent(path));
  }
}
