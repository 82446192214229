import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { OnChange } from '@pu/decorators';
import { StrNum } from '@pu/models';

import { AbstractInputComponent } from '../abstract-input.component';
import { SelectDropdownListItem } from '../../models/select-dropdown-list-item.model';

@Component({
  selector: 'pu-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends AbstractInputComponent<StrNum> {
  /**
   * Tooltip text of input
   */
  @Input() tooltip: string;

  /**
   * Label of input and HostBinding of class
   */
  @HostBinding('class.pu-select_labelled')
  @Input()
  override label: string;

  /**
   * Value field
   */
  @Input() valueField = 'id';

  /**
   * Name field
   */
  @Input() nameField = 'name';

  /**
   * It is a function, that returns true if item must be disabled
   */
  @Input() isDisabledItem: (item: SelectDropdownListItem<StrNum>) => boolean;

  /**
   * Items for dropdown
   */
  @OnChange('_onItemListChange')
  @Input()
  itemList: SelectDropdownListItem<StrNum>[];

  /**
   * Select opened
   * @internal
   */
  @Output() selectOpened = new EventEmitter<void>();

  /**
   * Items map for dropdown
   * @internal
   */
  itemsRecord: Record<number | string, SelectDropdownListItem<StrNum>> = {};

  override writeValue(value: StrNum): void {
    super.writeValue(value);
    this._cd.detectChanges();
  }

  /**
   * @internal
   * @param item
   */
  onItemSelected(item: SelectDropdownListItem<StrNum>) {
    const isChanged = item[this.valueField] !== this.control.value;
    if (isChanged || item.emitWithNoChange) {
      this.control.setValue(item[this.valueField]);
    }

    if (isChanged) {
      this.control.markAsDirty();
    }
  }

  private _onItemListChange(): void {
    this.itemsRecord = this.itemList.reduce((record, item) => {
      record[item[this.valueField]] = item;

      return record;
    }, <Record<StrNum, SelectDropdownListItem<StrNum>>>{});
  }
}
