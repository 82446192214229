import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, OnDestroy, Output } from '@angular/core';

import { trackByIndex } from '@pu/utils';
import { NavItem } from '@pu/ui';
import { Store } from '@ngrx/store';

import { NotificationsSelectors } from '../components/notifications/store';

type NavItemsKeys = 'home' | 'news' | 'menu' | 'shop' | 'cart' | 'alerts';

@Component({
  selector: 'pu-navbar, [puNavbar]',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnDestroy {
  private _store = inject(Store);
  hasUnreadNotifications$ = this._store.select(NotificationsSelectors.selectHasUnreadNotifications);

  navbarItems: Record<NavItemsKeys, NavItem> = {
    home: {
      label: 'navbar.home',
      icon: 'home',
      link: '/',
      isRoot: true,
    },
    news: {
      label: 'navbar.news',
      icon: 'news-2',
      link: '/news',
    },
    menu: {
      label: 'navbar.menu',
      icon: 'menu',
    },
    shop: {
      label: 'navbar.shop',
      icon: 'shopping-bag',
      link: '/shop',
    },
    cart: {
      label: 'navbar.cart',
      icon: 'cart',
      link: '/cart',
    },
    alerts: {
      label: 'navbar.alerts',
      icon: 'notifications',
    },
  };

  @Input()
  @HostBinding('class.pu-navbar_hidden')
  isHidden = false;

  @Input() isTabletMode: boolean;

  @Output() openMenu = new EventEmitter<void>();
  @Output() toggleNotifications = new EventEmitter<Event>();
  @Output() destroyed = new EventEmitter<void>();

  trackByIndex = trackByIndex;

  ngOnDestroy() {
    this.destroyed.emit();
  }
}
