<div
  [ngClass]="prevControlClass"
  class="ui-slider-controls__container ui-slider-controls__container_prev swiper-button-disabled swiper-button-lock"
>
  <button
    class="ui-slider-controls__btn ui-slider-controls__btn_prev"
    aria-label="Previous"
    type="button">
    <ui-icon
      *ngIf="hasSliderNavButtons"
      iconName="chevron-1"
      class="ui-slider-controls__icon ui-slider-controls__icon_prev"
      [size]="iconSize"
    ></ui-icon>
  </button>
</div>
<div
  [ngClass]="nextControlClass"
  class="ui-slider-controls__container ui-slider-controls__container_next swiper-button-disabled swiper-button-lock"
>
  <button
    class="ui-slider-controls__btn ui-slider-controls__btn_next"
    aria-label="Next"
    type="button">
    <ui-icon
      *ngIf="hasSliderNavButtons"
      iconName="chevron-1"
      class="ui-slider-controls__icon ui-slider-controls__icon_next"
      [size]="iconSize"
    ></ui-icon>
  </button>
</div>
