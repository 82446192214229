<input
  class="pu-control__input pu-search-select__input"
  [ngClass]="{
    'pu-control__input_invalid': control.touched && control.invalid,
    'pu-search-select__input_focus': dropdownRef.isDropdownOpened$ | async,
    'pu-search-select__input_readonly': isReadonly
  }"
  [hidden]="selectedItem"
  [id]="controlId"
  [placeholder]="placeholder"
  [formControl]="searchControl"
  #inputRef
  #dropdownRef="puDropdownTrigger"
  [puDropdownTriggerFor]="selectDropdown"
  [reference]="inputRef"
  [alignX]="dropdownAlignX"
  [inheritWidth]="dropdownInheritWidth"
/>

<ng-container *ngIf="label">
  <label
    class="pu-control__label pu-control__label_active"
    [for]="controlId"
    [ngClass]="{
      'pu-control__label_invalid': control.touched && control.invalid,
      'pu-control__label_small': labelSize === 's'
    }">
    {{ label }}
  </label>

  <pu-floating-label-outline
    [hasGap]="true"
    [label]="label"
    [labelSize]="labelSize"
    [isHighlighted]="dropdownRef.isDropdownOpened$ | async"
    [isInvalid]="control.touched && control.invalid"
    [isReadOnly]="isReadonly">
  </pu-floating-label-outline>
</ng-container>

<ui-icon
  class="pu-search-select__icon-search"
  *ngIf="!isReadonly && !selectedItem"
  iconName="search"
  size="20">
</ui-icon>

<div class="pu-search-select__selected-container"
     *ngIf="selectedItem">

  <div class="pu-search-select__selected-item">
    <ng-container
      *ngTemplateOutlet="itemTpl || defaultItemTpl; context: { $implicit: selectedItem }">
    </ng-container>

    <button
      class="pu-search-select__btn-remove"
      type="button"
      uiButton
      size="s"
      uiType="quaternary"
      [onlyIcon]="true"
      (click)="removeItem()">
      <ui-icon iconName="close" size="16"></ui-icon>
    </button>
  </div>
</div>

<span class="pu-control__hint"
      *ngIf="hint && !isReadonly"
      [class.pu-control__hint_invalid]="control.touched && control.invalid">
  {{ hint }}
</span>

<pu-dropdown #selectDropdown>
  <div class="pu-search-select__items">
    <div class="pu-search-select__item"
         *ngFor="let item of items; trackBy: trackById"
         (click)="selectItem(item)">

      <ng-container
        *ngTemplateOutlet="itemTpl || defaultItemTpl; context: { $implicit: item }">
      </ng-container>
    </div>
  </div>
</pu-dropdown>

<ng-template #defaultItemTpl let-item>
  <div class="pu-search-select__default-item">
    {{item.name}}
  </div>
</ng-template>
