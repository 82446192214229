import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pu/services';
import { BalanceSkin } from '@pu/models';

import { BalanceSkinFormComponent } from './balance-skin-form.component';

@Injectable({
  providedIn: 'root',
})
export class BalanceSkinFormDialog {
  constructor(private _dialog: DialogService) {}

  open(skins: BalanceSkin[], balance: number, skin: BalanceSkin): DialogRef {
    return this._dialog.open(BalanceSkinFormComponent, { skins, balance, skin });
  }
}
