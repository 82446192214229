import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileModule } from '@pu/components';
import { ButtonModule, LogoModule, NavItemModule } from '@pu/ui';
import { TranslateModule } from '@ngx-translate/core';

import { SidebarComponent } from './sidebar.component';
import { BalanceModule } from '../../components/balance/balance.module';

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule, ButtonModule, LogoModule, UserProfileModule, BalanceModule, NavItemModule, TranslateModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
