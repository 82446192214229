import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { INavigator, NAVIGATOR, WINDOW } from '@pu/models';
import { DialogService, UaParserService } from '@pu/services';

import { PwaSetupComponent } from './pwa-setup.component';

@Injectable({ providedIn: 'root' })
export class PwaSetupDialog {
  constructor(
    private _dialog: DialogService,
    private _uaParser: UaParserService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(NAVIGATOR) private _navigator: INavigator,
    @Inject(WINDOW) private _window: Window,
  ) {}

  init(): void {
    if (!this._isStandaloneMode() && this._uaParser.isMobile) {
      this._dialog.open(PwaSetupComponent, null, {
        panelClass: 'pu-overlay-panel_pwa-setup',
      });
    }
  }

  private _isStandaloneMode(): boolean {
    // https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
    return (
      this._window.matchMedia('(display-mode: standalone)').matches ||
      this._navigator.standalone ||
      this._document.referrer.includes('android-app://')
    );
  }
}
