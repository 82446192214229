<input
  class="pu-control__input pu-search-multi-select__input"
  [ngClass]="{
    'pu-control__input_invalid': control.touched && control.invalid,
    'pu-search-multi-select__input_focus': isDropdownOpened,
    'pu-search-multi-select__input_readonly': isReadonly
  }"
  [id]="controlId"
  [placeholder]="computedPlaceholder"
  [formControl]="searchControl"
  #inputRef
  #dropdownRef="puDropdownTrigger"
  [puDropdownTriggerFor]="selectDropdown"
  [reference]="inputRef"
/>

<ng-container *ngIf="label">
  <label
    class="pu-control__label pu-control__label_active"
    [for]="controlId"
    [ngClass]="{
      'pu-control__label_invalid': control.touched && control.invalid,
      'pu-control__label_small': labelSize === 's'
    }">
    {{ label }}
  </label>

  <pu-floating-label-outline
    [hasGap]="true"
    [label]="label"
    [labelSize]="labelSize"
    [isHighlighted]="isDropdownOpened"
    [isInvalid]="control.touched && control.invalid"
    [isReadOnly]="isReadonly">
  </pu-floating-label-outline>
</ng-container>

<ui-icon
  class="pu-search-multi-select__icon-search"
  *ngIf="!isReadonly && !selected.length"
  iconName="search"
  size="20">
</ui-icon>

<button
  class="pu-search-multi-select__btn-remove-all"
  type="button"
  *ngIf="!isReadonly && selected.length"
  uiButton
  size="s"
  uiType="quaternary"
  [onlyIcon]="true"
  (click)="removeAll()">
  <ui-icon
    iconName="close"
    size="16">
  </ui-icon>
</button>

<span class="pu-control__hint"
      *ngIf="hint && !isReadonly"
      [class.pu-control__hint_invalid]="control.touched && control.invalid">
  {{ hint }}
</span>

<pu-dropdown #selectDropdown>
  <div class="pu-search-multi-select__items">
    <div class="pu-search-multi-select__item-container"
         *ngFor="let item of (items.length ? items : selected); trackBy: trackById"
         (click)="toggleItem($event, item)">

      <ui-icon
        class="pu-search-multi-select__icon-checkbox"
        iconName="checkbox-active"
        size="20"
        *ngIf="selectedIndex[item[idField]]">
      </ui-icon>

      <ui-icon
        class="pu-search-multi-select__icon-checkbox"
        iconName="checkbox"
        size="20"
        *ngIf="!selectedIndex[item[idField]]">
      </ui-icon>

      <ng-container
        *ngTemplateOutlet="itemTpl || defaultItemTpl; context: { $implicit: item, isSelected: !!selectedIndex[item[idField]] }">
      </ng-container>
    </div>
  </div>
</pu-dropdown>

<ng-template
  #defaultItemTpl
  let-item
  let-isSelected="isSelected">
  <div class="pu-search-multi-select__default-item"
       [class.pu-search-multi-select_default-item-selected]="isSelected">
    {{ item.name }}
  </div>
</ng-template>
