/**
 * Return object indexed by field name
 * @param arr
 * @param fieldName
 * @returns Record<string, any>
 */
export function indexBy(arr: any[], fieldName: ((item: any) => string) | string): Record<string, any> {
  const result: Record<string, any> = {};

  arr.forEach((item: any) => {
    const key = typeof fieldName === 'function' ? fieldName(item) : item[fieldName];
    if (key) {
      result[key] = item;
    }
  });

  return result;
}
