import { Entity } from './entity.model';
import { User } from './user.model';

export type HistoryTypes = 'manuallyAccrual' | 'automaticAccrual' | 'eventAccrual';
export type AccrualHistoryRollbackStatuses = 'notRollback' | 'inProgress' | 'rolledBack';

export interface AccrualHistory extends Entity {
  createdAt: Date;
  owner: User;
  comment: string;
  amount: number;
  rollbackStatus: AccrualHistoryRollbackStatuses;
  usersCount: number;
  recipient: User | null;
}
