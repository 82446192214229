<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<div class="ui-toaster__toast"
     *ngFor="let toast of currentToasts$ | async; index as index"
     @verticalCollapse
     @triggerChildAnimation>

  <div class="ui-toaster__toast-wrap"
       @slideIn
       @slideOut>
    <ng-container
      *ngTemplateOutlet="toast.tpl || defaultTpl; context: { $implicit: toast, index: index, close: close }">
    </ng-container>
  </div>
</div>

<ng-template
  #defaultTpl
  let-toast
  let-index="index"
  let-close="close">

  <ui-toast
    [toastType]="toast.type"
    [iconName]="toast.iconName"
    [imageUrl]="toast.imageUrl"
    [title]="toast.title | translate"
    [message]="toast.message | translate"
    [timeout]="toast.timeout"
    (closeToast)="close(index)">
  </ui-toast>
</ng-template>
