import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { filter, Subject } from 'rxjs';

import { ExpansionPanelComponent } from './expansion-panel.component';

/**
 * Accordion component provides functionality to close other expansion panels when one opens.
 *   ```
 *   <pu-accordion>
 *     <pu-expansion-panel puAccordionItem></pu-expansion-panel>
 *     <pu-expansion-panel puAccordionItem></pu-expansion-panel>
 *   </pu-accordion>
 *   ```
 *
 * `<pu-expansion-panel>` not necessarily should be the first child of `<pu-accordion>`.
 * If custom element uses expansion panel inside itself, and custom element wrapped with `<pu-accordion>`,
 * expansion panel will be controlled by outer `<pu-accordion>`
 *   ```
 *   <pu-accordion>
 *     <custom-element> has expansion panel in its template </custom-element>
 *     <custom-element> has expansion panel in its template </custom-element>
 *   </pu-accordion>
 *   ```
 */
@Component({
  selector: 'pu-accordion',
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  /**
   * Whether multiple expansion panels can be opened simultaneously
   */
  @Input() openMultiple = false;

  private _openedPanelSubject = new Subject<ExpansionPanelComponent>();

  get openedPanel$() {
    return this._openedPanelSubject.asObservable().pipe(filter(() => !this.openMultiple));
  }

  emitOpenedPanel(panel: ExpansionPanelComponent) {
    this._openedPanelSubject.next(panel);
  }
}
