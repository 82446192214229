import { Entity, ImageModel, FileModel, DictionaryItem } from '@pu/models';

export type ArticleType = 'news' | 'faq';

export type RubricTag = DictionaryItem;

export interface News extends Entity {
  title: string;
}

export interface NewsShop extends News {
  tag: RubricTag;
  previewImage: ImageModel;
  image: ImageModel;
  previewText: string;
  article: string;
  publishedAt: string;
  read: boolean;
  saved: boolean;
}

export interface Article extends NewsShop {
  articleType: ArticleType;
  videoUrl?: string;
  pdf?: FileModel;
  linkName?: string;
  linkUrl?: string;
}

export interface NewsAdmin extends News {
  tag: RubricTag;
  publishedAt: string;
  visible: boolean;
}

export interface NewsForm extends News {
  articleType: ArticleType;
  tagId: number;
  previewImage: ImageModel;
  image: ImageModel;
  previewText: string;
  article: string;
  groupIds: number[];
  slackChannelIds: number[];
  visible: boolean;
  videoUrl?: string;
  pdf?: FileModel;
  linkName?: string;
  linkUrl?: string;
}

export interface GlobalSearchArticle extends News {
  tag: RubricTag;
  imageUrl: string;
  publishedAt: string;
}
