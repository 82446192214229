<label class="pu-radio-option__label">
  <div class="pu-radio-option__circle" [class.pu-radio-option__circle_active]="control.value === option.value"></div>
  <span *ngIf="!isHtmlLabel; else htmlLabel">{{ option.label | translate }}</span>
  <input
    type="radio"
    class="pu-radio-option__radio-btn"
    [name]="controlId"
    [value]="option.value"
    [formControl]="control"
    [attr.data-testid]="controlTestId"
  />
</label>

<ng-template #htmlLabel>
  <span [innerHTML]="option.label | decodedSafeHtml"></span>
</ng-template>
